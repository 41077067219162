import { PATH_ADMIN_API } from '../constants/api-routes';
import { IBankAccount } from '../types/bank.account.interface';
import { baseApi } from './base.api';

export const bankAccountService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllBankAccount: build.query<IBankAccount[], {}>({
      query: () => ({
        url: PATH_ADMIN_API.ALL_BANK_ACCOUNT,
      }),
      providesTags: ['BankAccount'],
    }),
    createBankAccount: build.mutation<
      any,
      {
        sbaName: string;
        currency: string | null;
        multicurrency: boolean;
        isIban: boolean;
        iban: string;
        bic: string;
        accountNumber: string;
        bankName: string;
        beneficiaryInformation: string;
        receivingBankInformation: string;
        contactEmail: string | null;
        active: boolean;
      }
    >({
      query: (body) => ({
        url: PATH_ADMIN_API.BANK_ACCOUNT,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['BankAccount'],
    }),
    updateBankAccount: build.mutation<
      any,
      {
        id: number;
        sbaName: string;
        currency: string;
        multicurrency: boolean;
        isIban: boolean;
        iban: string;
        bic: string;
        accountNumber: string;
        bankName: string;
        beneficiaryInformation: string;
        receivingBankInformation: string;
        contactEmail: string;
        active: boolean;
      }
    >({
      query: (body) => ({
        url: `${PATH_ADMIN_API.BANK_ACCOUNT}/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['BankAccount'],
    }),
    deleteBankAccount: build.mutation<
      any,
      {
        id: number;
      }
    >({
      query: ({ id }) => ({
        url: `${PATH_ADMIN_API.BANK_ACCOUNT}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['BankAccount'],
    }),
  }),
});

export const {
  useGetAllBankAccountQuery,
  useCreateBankAccountMutation,
  useDeleteBankAccountMutation,
  useUpdateBankAccountMutation,
} = bankAccountService;
