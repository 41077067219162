import React, { FC, memo, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  CardContent,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Switch,
} from '@mui/material';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { object } from 'yup';
import { useNotification, useServerError } from '@/hooks';
import { useChangeAutoFuelMutation } from '@/services';
import { IAutoFuel } from '@/types/admin.interface';
import { stringScheme } from '@/utils';

type GasStationEditProps = {
  open: boolean;
  onClose: () => void;
  userId: string | number | null | undefined;
  userRole: string | null | undefined;
  userName: string | null | undefined;
  vaultAutoFuel: boolean | null | undefined;
};

export const AutoFuelEdit: FC<GasStationEditProps> = memo(
  ({ open, onClose, userId, userName, vaultAutoFuel }) => {
    const { showNotification } = useNotification();

    const [
      changeAutoFuel,
      {
        isLoading: isLoadingChangeAutoFuel,
        isError: isErrorChangeAutoFuel,
        error: errorChangeAutoFuel,
        isSuccess: isSuccessChangeAutoFuel,
      },
    ] = useChangeAutoFuelMutation();

    const { handleSubmit, control, watch } = useForm<IAutoFuel>({
      defaultValues: {
        status: vaultAutoFuel as boolean,
      },
      resolver: yupResolver(
        object({
          status: stringScheme({ required: true }),
        }),
      ) as Resolver<any>,
    });

    const status = watch('status');

    const onSubmit = () => {
      if (vaultAutoFuel === status) {
        onClose();
      } else {
        changeAutoFuel({ userId: userId as number, autoFuel: status });
      }
    };

    useServerError({
      isError: isErrorChangeAutoFuel,
      error: errorChangeAutoFuel,
    });

    useEffect(() => {
      if (isSuccessChangeAutoFuel) {
        onClose();
        showNotification(
          `Vault auto fuel status for user ID ${userId} was changed!`,
          'success',
        );
      }
    }, [isSuccessChangeAutoFuel]);

    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <CardContent sx={{ overflow: 'auto' }}>
          <CardHeader
            sx={{ textAlign: 'center' }}
            title="Vault auto fuel status"
          />
          <Divider />
          <Typography m={1} py={2} variant="h5">
            Changing vault auto fuel status for{' '}
            <b style={{ wordBreak: 'break-word' }}>{userName}</b>
          </Typography>
          <Box
            sx={{
              minWidth: 120,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="status"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl sx={{ maxWidth: '110px' }}>
                  <InputLabel id="demo-simple-select-helper-label" shrink>
                    Status
                  </InputLabel>

                  <FormControlLabel
                    onChange={onChange}
                    control={<Switch defaultChecked checked={value} />}
                    label={`${status}`}
                  />
                  {error && (
                    <FormHelperText error={!!error.message}>
                      {error.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Box>
          <Button sx={{ mt: 3, ml: 1 }} onClick={onClose} variant="outlined">
            Close
          </Button>
          <LoadingButton
            endIcon={<SaveIcon />}
            loadingPosition="end"
            variant="contained"
            loading={isLoadingChangeAutoFuel}
            onClick={handleSubmit(onSubmit)}
            sx={{ mt: 3, ml: 1 }}
            type="submit"
          >
            Save
          </LoadingButton>
        </CardContent>
      </Dialog>
    );
  },
);
