import React, { FC } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { Grid } from '@mui/material';
import { Loader } from '@/shared';
import { useServerError } from '../../../hooks';
import { usePagination } from '../../../hooks/usePagination';
import { useGetAllUserFeedbacksQuery } from '../../../services';
import { UserFeedbacksTable } from './user-feedbacks-table/user.feedbacks.table';

export const UserFeedbacksPage: FC = () => {
  const { onChangeRowsPerPage, onChangePage, pagination } = usePagination();

  const { data, isFetching, error, isError } = useGetAllUserFeedbacksQuery(
    {
      limit: pagination.rowsPerPage,
      page: pagination.page + 1,
    },
    { refetchOnMountOrArgChange: true },
  );

  useServerError({ isError: isError, error: error });

  return (
    <Container maxWidth="xl">
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 4 }}>
        <Typography variant="h3" color="inherit" textAlign="left">
          User feedbacks
        </Typography>
      </Box>

      <Grid container>
        <Grid item xs={12}>
          {data?.data ? (
            <UserFeedbacksTable
              feedbacks={data?.data}
              count={data?.meta.total}
              page={pagination?.page}
              rowsPerPage={pagination?.rowsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              isFetching={isFetching}
            />
          ) : (
            <Box display="flex" alignItems="center" justifyContent="center">
              {isFetching ? (
                <Loader />
              ) : (
                <Typography>Something went wrong!</Typography>
              )}
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
