import { PATH_ADMIN_API } from '../constants/api-routes';
import { ICompanyPdf } from '../types/company.interface';
import { baseApi } from './base.api';

export const companyPdfService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCompanyPdf: build.query<ICompanyPdf[], {}>({
      query: () => ({
        url: PATH_ADMIN_API.COMPANY_PDF,
      }),
      providesTags: ['CompanyPdf'],
    }),
    createCompanyPdf: build.mutation<
      any,
      {
        companyName: string;
        companyTag: string;
        companyCode: number;
        addressLine1: string;
        addressLine2: string;
        addressLine3: string;
        addressLine4: string;
        officerName: string;
        officerMail: string;
        officerPosition: string;
        isDefault: boolean;
        isFuze: boolean;
        accountStatementFooter: string;
      }
    >({
      query: (body) => ({
        url: PATH_ADMIN_API.COMPANY_PDF,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CompanyPdf'],
    }),
    updateCompanyPdf: build.mutation<
      any,
      {
        id: number;
        companyName: string;
        companyTag: string;
        companyCode: number;
        addressLine1: string;
        addressLine2: string;
        addressLine3: string;
        addressLine4: string;
        officerName: string;
        officerMail: string;
        officerPosition: string;
        isDefault: boolean;
        isFuze: boolean;
        accountStatementFooter: string;
      }
    >({
      query: (body) => ({
        url: `${PATH_ADMIN_API.COMPANY_PDF}/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['CompanyPdf'],
    }),
    deleteCompanyPdf: build.mutation<
      any,
      {
        id: number;
      }
    >({
      query: (params) => ({
        url: `${PATH_ADMIN_API.COMPANY_PDF}/${params.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CompanyPdf'],
    }),
    uploadCompanyImage: build.mutation<
      any,
      {
        id: number;
        type: string;
        body: FormData;
      }
    >({
      query: ({ id, type, body }) => ({
        url: `${PATH_ADMIN_API.COMPANY_PDF_IMAGE}/${id}/${type}`,
        method: 'POST',
        body,
        formData: true,
      }),
      invalidatesTags: ['CompanyPdfStamp'],
    }),
    deleteCompanyImage: build.mutation<
      any,
      {
        id: number;
        type: string;
      }
    >({
      query: ({ id, type }) => ({
        url: `${PATH_ADMIN_API.COMPANY_PDF_IMAGE}/${id}/${type}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CompanyPdfStamp', 'CompanyPdfSignature'],
    }),
    getCompanyImage: build.query<
      any,
      {
        id: number;
        type: string;
      }
    >({
      query: ({ id, type }) => ({
        url: `${PATH_ADMIN_API.COMPANY_PDF_IMAGE}/${id}/${type}`,
        responseHandler: async (data) => {
          return data.text();
        },
      }),
      providesTags: ['CompanyPdfStamp', 'CompanyPdfSignature'],
    }),
  }),
});

export const {
  useGetCompanyPdfQuery,
  useCreateCompanyPdfMutation,
  useUpdateCompanyPdfMutation,
  useDeleteCompanyPdfMutation,
  useUploadCompanyImageMutation,
  useDeleteCompanyImageMutation,
  useGetCompanyImageQuery,
} = companyPdfService;
