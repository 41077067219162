export interface ICurrency {
  blockchainName: string;
  customName: string;
  externalId: string;
  icon: string;
  id: number;
  isDefault: boolean;
  networkQrCode: string;
  protocolQrCode: string;
  status: boolean;
  b2c2CurrencyName: string;
  fuzeCurrencyName: string;
  ordering: number;
  reason: string | null;
  isVisible: boolean;
}

export interface ICurrencySlice {
  id: number | null;
  customName: string;
  icon: string;
  status: boolean;
  isDefault: boolean;
  protocolQrCode: string;
  networkQrCode: string;
  b2c2CurrencyName: string;
  fuzeCurrencyName: string;
  ordering: number | null;
  externalId: string;
  reason: string | null;
  isVisible: boolean;
}
export interface ICurrencySchema {
  customName: string;
  icon: string;
  networkQrCode: string;
  protocolQrCode: string;
  status: boolean;
  isDefault: boolean;
  b2c2CurrencyName: string;
  fuzeCurrencyName: string;
  ordering: number | null;
  fireblocksCurrency: string;
  reason: string | null;
  isVisible: boolean;
}

export interface ICurrencies {
  count: number;
  items: ICurrency[];
}

export const FiatCurrencies = ['USD', 'EUR', 'GBP', 'AED'];

export interface IFireblocksCurrency {
  id: number;
  externalId: string;
}

export type CurrencyPayload = Omit<ICurrency, 'externalId' | 'blockchainName'>;
