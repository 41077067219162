import { FC } from 'react';
import { LockOutlined } from '@mui/icons-material';
import { Avatar, Box, Typography } from '@mui/material';
import { AuthRegisterFormPage } from '../auth-register/auth-register-form.page';

type RegisterPageProps = {};

export const RegisterPage: FC<RegisterPageProps> = () => {
  return (
    <Box
      sx={{
        marginTop: 8,
        marginBottom: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlined />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign up
      </Typography>
      <AuthRegisterFormPage />
    </Box>
  );
};
