import { PATH_ADMIN_API } from '../constants/api-routes';
import { IFiatLimit, IFiatLimitCondition } from '../types/fiat.limit.interface';
import { baseApi } from './base.api';

export const fiatLimitService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllFiatLimit: build.query<IFiatLimit[], null>({
      query: () => ({
        url: PATH_ADMIN_API.FIAT_LIMIT_ALL,
      }),
      providesTags: ['FiatLimit'],
    }),
    getFiatLimitCondition: build.query<IFiatLimitCondition, null>({
      query: () => ({
        url: PATH_ADMIN_API.FIAT_LIMIT_CONDITIONS,
      }),
    }),
    getFiatLimitById: build.query<any, { id: number }>({
      query: ({ id }) => ({
        url: PATH_ADMIN_API.FIAT_LIMIT,
        params: {
          id,
        },
      }),
    }),
    deleteFiatLimit: build.mutation<any, { id: number }>({
      query: ({ id }) => ({
        url: `${PATH_ADMIN_API.FIAT_LIMIT}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['FiatLimit'],
    }),
    updateFiatLimit: build.mutation<
      any,
      {
        id: number;
        value: number | null;
        applyCondition: string;
        fiat: string;
        tierId: number | null | string;
      }
    >({
      query: ({ id, value, applyCondition, tierId, fiat }) => ({
        url: `${PATH_ADMIN_API.FIAT_LIMIT}/${id}`,
        body: {
          value,
          applyCondition,
          tierId,
          fiat,
        },
        method: 'PUT',
      }),
      invalidatesTags: ['FiatLimit'],
    }),
    createFiatLimit: build.mutation<
      any,
      {
        value: number | null;
        applyCondition: string;
        fiat: string;
        tierId: number | null | string;
      }
    >({
      query: ({ value, applyCondition, tierId, fiat }) => ({
        url: PATH_ADMIN_API.FIAT_LIMIT,
        method: 'POST',
        body: {
          value,
          applyCondition,
          tierId,
          fiat,
        },
      }),
      invalidatesTags: ['FiatLimit'],
    }),
  }),
});

export const {
  useGetAllFiatLimitQuery,
  useGetFiatLimitByIdQuery,
  useGetFiatLimitConditionQuery,
  useDeleteFiatLimitMutation,
  useCreateFiatLimitMutation,
  useUpdateFiatLimitMutation,
} = fiatLimitService;
