import React, { FC } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/system';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

type UploadSvgButtonProps = {
  onChange: (result: any) => void;
  title?: string;
  loading?: boolean;
};

export const UploadSvgButton: FC<UploadSvgButtonProps> = ({
  onChange,
  title = 'Upload',
  loading = false,
}) => {
  return (
    <LoadingButton
      component="label"
      variant="contained"
      loading={loading}
      startIcon={<CloudUploadIcon />}
    >
      {title}
      <VisuallyHiddenInput
        type="file"
        accept="image/svg+xml"
        onChange={(e) => {
          const file = e?.target?.files && e?.target?.files[0];
          if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
              const targetRes = event?.target?.result as string;
              const result = atob(
                targetRes.replace('data:image/svg+xml;base64,', ''),
              );
              onChange(result);
            };
            reader.readAsDataURL(file);
          }
        }}
      />
    </LoadingButton>
  );
};
