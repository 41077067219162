import React, { FC } from 'react';
import { Delete as DeleteIcon } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { IconButton, TableCell, TableRow, Box } from '@mui/material';
import { ITier } from '../../../../types/tier.interface';

type TierRowProps = {
  row: ITier;
  labelId: any;
  handleOpenModal: any;
  isLoadingTear: boolean;
  disableEdit?: boolean;
  handleDeleteTier: ({ id, name }: { id: number; name: string }) => void;
};

export const TierRow: FC<TierRowProps> = ({
  row,
  labelId,
  handleOpenModal,
  isLoadingTear,
  handleDeleteTier,
}) => {
  return (
    <TableRow tabIndex={-1} key={row.id}>
      <TableCell id={labelId} align="left">
        {row?.name}
      </TableCell>
      <TableCell align="center">
        {row?.isDefault && <CheckCircleIcon color="success" />}
      </TableCell>
      <TableCell align="right">
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <IconButton
            aria-label="Settings"
            size="large"
            title="Edit"
            onClick={() =>
              handleOpenModal({
                id: row.id,
                name: row?.name,
                isDefault: row?.isDefault,
              })
            }
            disabled={isLoadingTear}
          >
            <ModeEditIcon />
          </IconButton>
        </Box>
      </TableCell>
      <TableCell align="right">
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <IconButton
            aria-label="Delete"
            size="large"
            title="Delete"
            onClick={() =>
              handleDeleteTier({
                id: row.id as number,
                name: row.name,
              })
            }
            disabled={isLoadingTear}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
};
