import React, { FC, memo } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import { NoItemsWrapper } from '../../../shared';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

type ImageSectionProps = {
  isLoading?: boolean;
  imageType: string;
  title?: string;
  isError: boolean;
  isFetchingImage: boolean;
  image: string;
  handleUpload: (image: string | Blob, type: string) => void;
  handleDelete: (type: string) => void;
};

export const ImageSection: FC<ImageSectionProps> = memo(
  ({
    isLoading,
    imageType,
    isError,
    image,
    handleUpload,
    handleDelete,
    title,
    isFetchingImage,
  }) => {
    return (
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: 1,
          }}
        >
          <LoadingButton
            component="label"
            variant="contained"
            loading={isLoading}
            startIcon={<CloudUploadIcon />}
          >
            {title ? title : 'Upload'}
            <VisuallyHiddenInput
              type="file"
              onChange={(e) => {
                const file = e?.target?.files && e?.target?.files[0];
                if (file) {
                  handleUpload(file, imageType);
                }
              }}
            />
          </LoadingButton>
          <IconButton onClick={() => handleDelete(imageType)}>
            <DeleteIcon />
          </IconButton>
        </Box>
        <NoItemsWrapper
          isLoading={isFetchingImage}
          length={isError ? 0 : 1}
          text="There are no image"
        >
          {!isError && image && (
            <Box
              sx={{
                height: '250px',
                padding: 3,
                border: '1px solid',
                borderRadius: '5px',
                svg: {
                  fill: '#000071',
                  maxWidth: '500px',
                  maxHeight: '250px',
                  height: '100%',
                  width: '100%',
                  path: {
                    fill: '#000071 !important',
                  },
                },
              }}
              dangerouslySetInnerHTML={{
                __html: image || '',
              }}
            />
          )}
        </NoItemsWrapper>
      </Box>
    );
  },
);
