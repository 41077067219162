import React, { FC, memo, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  CardContent,
  Dialog,
  Divider,
  FormControl,
  TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { object } from 'yup';
import { useNotification, useServerError } from '../../../hooks';
import {
  useCreateFiatLimitMutation,
  useGetAllFiatLimitQuery,
  useGetAllTierQuery,
  useGetFiatLimitConditionQuery,
  useUpdateFiatLimitMutation,
} from '../../../services';
import { FIAT_CURRENCY_LIST } from '../../../shared/currencies/currencies';
import { IFiatLimitSchema } from '../../../types/fiat.limit.interface';
import { ITier } from '../../../types/tier.interface';
import { numberScheme, stringScheme } from '../../../utils';

type FiatLimitCreateEditModalProps = {
  open: boolean;
  onClose: () => void;
  feeTierId: number | null;
  id: number | null;
  feeValue: number | null;
  feeApplyCondition: string;
  feeFiat: string;
};

export const FiatLimitCreateEditModal: FC<FiatLimitCreateEditModalProps> = memo(
  ({ open, onClose, feeTierId, id, feeValue, feeApplyCondition, feeFiat }) => {
    const { showNotification } = useNotification();

    const { data: fiatLimitData } = useGetAllFiatLimitQuery(null);

    const [
      createFiatLimit,
      {
        isLoading: isLoadingCreateFiatLimit,
        isError: isErrorCreateFiatLimit,
        error: errorCreateFiatLimit,
        isSuccess: isSuccessCreateFiatLimit,
      },
    ] = useCreateFiatLimitMutation();

    const [
      updateFiatLimit,
      {
        isLoading: isLoadingUpdateFiatLimit,
        isError: isErrorUpdateFiatLimit,
        error: errorUpdateFiatLimit,
        isSuccess: isSuccessUpdateFiatLimit,
      },
    ] = useUpdateFiatLimitMutation();

    const {
      data: fiatLimitConditionsData,
      isFetching: isFetchingFiatLimitConditions,
      error: errorFiatLimitConditions,
      isError: isErrorFiatLimitConditions,
    } = useGetFiatLimitConditionQuery(null);

    const { data: tierData, isFetching: isFetchingTierData } =
      useGetAllTierQuery(null);

    const { handleSubmit, control } = useForm<IFiatLimitSchema>({
      defaultValues: {
        tierId: feeTierId,
        fiatValue: feeValue,
        applyCondition: feeApplyCondition,
        fiat: feeFiat,
      },
      resolver: yupResolver(
        object({
          tierId: stringScheme({ required: true }).test(
            'unique-tier',
            'This tier id is already taken for this condition',
            function (value, context) {
              const { applyCondition } = context.parent;

              return feeTierId === (value && +value)
                ? true
                : !fiatLimitData?.some(
                    (item: any) =>
                      item.tierId === (value && +value) &&
                      item.applyCondition === applyCondition,
                  );
            },
          ),
          fiatValue: numberScheme({ required: true }).integer(
            'Fiat Value must be an integer',
          ),
          applyCondition: stringScheme({ required: true }),
          fiat: stringScheme({ required: true }),
        }),
      ) as Resolver<IFiatLimitSchema>,
    });

    const onSubmit = (data: IFiatLimitSchema) => {
      if (id) {
        updateFiatLimit({
          id: id,
          value: data?.fiatValue && +data?.fiatValue,
          applyCondition: data.applyCondition,
          tierId: data?.tierId && +data?.tierId,
          fiat: data.fiat,
        });
      } else {
        createFiatLimit({
          value: data?.fiatValue && +data?.fiatValue,
          applyCondition: data.applyCondition,
          tierId: data?.tierId && +data?.tierId,
          fiat: data.fiat,
        });
      }
    };

    useServerError({
      isError: isErrorCreateFiatLimit,
      error: errorCreateFiatLimit,
    });
    useServerError({
      isError: isErrorUpdateFiatLimit,
      error: errorUpdateFiatLimit,
    });
    useServerError({
      isError: isErrorFiatLimitConditions,
      error: errorFiatLimitConditions,
    });

    useEffect(() => {
      if (isSuccessCreateFiatLimit || isSuccessUpdateFiatLimit) {
        onClose();
        showNotification(
          !id ? `Fiat limit was created!` : `Fiat limit was updated!`,
          'success',
        );
      }
    }, [isSuccessCreateFiatLimit, isSuccessUpdateFiatLimit]);

    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <CardContent sx={{ overflow: 'auto' }}>
          <CardHeader
            sx={{ textAlign: 'center' }}
            title={id ? 'Fiat limit update' : 'Fiat limit create'}
          />
          <Divider />
          <Typography m={1} py={2} variant="h5">
            {id ? (
              <Typography variant="h6">
                Update fiat limit{' '}
                <b>
                  {feeApplyCondition}, ID #{id}
                </b>
              </Typography>
            ) : (
              <Typography variant="h6">Create new fiat limit</Typography>
            )}
          </Typography>
          <Box
            sx={{
              minWidth: 120,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '20px',
            }}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="tierId"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl error={!!error} fullWidth>
                  <InputLabel id="demo-simple-select-error-label">
                    Tier ID
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-error-label"
                    id="demo-simple-select-error"
                    value={value as string}
                    label="Tier ID"
                    onChange={onChange}
                    disabled={isFetchingTierData}
                  >
                    {tierData &&
                      tierData?.map((el: ITier) => {
                        return (
                          <MenuItem key={el?.id} value={el?.id as any}>
                            {el?.name}, ID {el.id}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {error && <FormHelperText>{error.message}</FormHelperText>}
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="fiatValue"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="number"
                    required
                    onChange={onChange}
                    value={value}
                    fullWidth
                    id="fiatValue"
                    label="Fiat Value"
                    name="fiatValue"
                    autoComplete="fiatValue"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="applyCondition"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl error={!!error} fullWidth>
                  <InputLabel id="demo-simple-select-error-label">
                    Apply Condition
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-error-label"
                    id="demo-simple-select-error"
                    value={value}
                    label="Apply Condition"
                    onChange={onChange}
                    disabled={isFetchingFiatLimitConditions}
                  >
                    {fiatLimitConditionsData &&
                      Object?.values(fiatLimitConditionsData)?.map(
                        (el, index) => {
                          return (
                            <MenuItem key={el} value={el}>
                              {Object.keys(fiatLimitConditionsData)[index]}
                            </MenuItem>
                          );
                        },
                      )}
                  </Select>
                  {error && <FormHelperText>{error.message}</FormHelperText>}
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="fiat"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl error={!!error} fullWidth>
                  <InputLabel id="demo-simple-select-error-label">
                    Fiat
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-error-label"
                    id="demo-simple-select-error"
                    value={value}
                    label="Fiat"
                    onChange={onChange}
                    disabled={isFetchingFiatLimitConditions}
                  >
                    {FIAT_CURRENCY_LIST?.map((el) => {
                      return (
                        <MenuItem key={el.symbol} value={el.symbol}>
                          {el.symbol}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {error && <FormHelperText>{error.message}</FormHelperText>}
                </FormControl>
              )}
            />
          </Box>
          <Button sx={{ mt: 3, ml: 1 }} onClick={onClose} variant="outlined">
            Close
          </Button>
          <LoadingButton
            endIcon={<SaveIcon />}
            loadingPosition="end"
            variant="contained"
            loading={isLoadingCreateFiatLimit || isLoadingUpdateFiatLimit}
            onClick={handleSubmit(onSubmit)}
            sx={{ mt: 3, ml: 1 }}
            type="submit"
          >
            {id ? 'Update' : 'Create'}
          </LoadingButton>
        </CardContent>
      </Dialog>
    );
  },
);
