import React, { FC, useEffect } from 'react';
import {
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  Box,
  Typography,
  Button,
} from '@mui/material';
import { spacing } from '@mui/system';
import { styled } from '@mui/system';
import { useDispatch } from 'react-redux';
import { EnhancedTableHeadComponent } from '../../../../components';
import { HeadCell } from '../../../../components/table_head';
import { useNotification, useServerError } from '../../../../hooks';
import { useDialog } from '../../../../hooks/useDialog';
import {
  useDeleteFiatLimitMutation,
  useGetAllFiatLimitQuery,
} from '../../../../services';
import { NoItemsWrapper } from '../../../../shared';
import { setModalState, setFeeFiatState } from '../../../../store/ui';
import { IFiatLimit } from '../../../../types/fiat.limit.interface';
import { FiatLimitRow } from './fiat.limit.row';

const Paper = styled(MuiPaper)(spacing);

const headCells: Array<HeadCell> = [
  {
    id: 'applyCondition',
    numeric: false,
    disablePadding: true,
    label: 'Condition',
  },
  {
    id: 'tierId',
    numeric: true,
    disablePadding: false,
    label: 'Tier Name',
  },
  {
    id: 'value',
    numeric: true,
    disablePadding: false,
    label: 'Value',
  },
  {
    id: 'fiat',
    numeric: true,
    disablePadding: false,
    label: 'Fiat',
  },
  { id: 'button', numeric: true, disablePadding: false, label: 'Edit' },
  { id: 'buttonDelete', numeric: true, disablePadding: false, label: 'Delete' },
];

export const FiatLimitTable: FC = () => {
  const [handleOpenDialog, DialogComponent] = useDialog();

  const dispatch = useDispatch();
  const { showNotification } = useNotification();

  const {
    data: fiatLimitData,
    isFetching: isFetchingFeeData,
    error: errorFeeData,
    isError: isErrorFeeData,
  } = useGetAllFiatLimitQuery(null);

  const [
    deleteFiatLimit,
    {
      isLoading: isLoadingDeleteFiatLimit,
      isError: isErrorDeleteFiatLimit,
      error: errorDeleteFiatLimit,
      isSuccess: isSuccessDeleteFiatLimit,
    },
  ] = useDeleteFiatLimitMutation();

  const handleOpenFiatLimitModal = ({
    id,
    applyCondition,
    tierId,
    value,
    feeFiat,
  }: {
    applyCondition: string;
    feeFiat: string;
    id: number | null;
    tierId: number | null;
    value: number | null;
  }) => {
    dispatch(
      setModalState({
        visible: true,
        name: 'fiatLimitModal',
      }),
    );
    dispatch(
      setFeeFiatState({
        id: id,
        value: value,
        tierId: tierId,
        applyCondition: applyCondition,
        feeFiat: feeFiat,
      }),
    );
  };

  const handleDeleteFiatLimit = ({
    id,
    name,
  }: {
    id: number;
    name: string;
  }) => {
    handleOpenDialog({
      description: (
        <>
          Are you sure you want to delete fiat limit <b>{name}</b>?
        </>
      ),
      buttonApplyHandler: () => deleteFiatLimit({ id }),
      loadingDelete: isLoadingDeleteFiatLimit,
    });
  };

  useServerError({
    isError: isErrorDeleteFiatLimit,
    error: errorDeleteFiatLimit,
  });
  useServerError({ isError: isErrorFeeData, error: errorFeeData });

  useEffect(() => {
    if (isSuccessDeleteFiatLimit) {
      showNotification('Fiat limit deleted!', 'success');
    }
  }, [isSuccessDeleteFiatLimit]);

  return (
    <>
      <Box
        mb={2}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h3" color="inherit" noWrap textAlign="left">
          Fiat limit
        </Typography>
        <Button
          variant="contained"
          onClick={() =>
            handleOpenFiatLimitModal({
              id: null,
              tierId: null,
              applyCondition: '',
              value: null,
              feeFiat: '',
            })
          }
        >
          + Add fiat limit
        </Button>
      </Box>
      <Paper sx={{ marginBottom: 5 }}>
        <TableContainer sx={{ maxHeight: 640 }}>
          <Table
            sx={{ minWidth: 800 }}
            aria-labelledby="tableTitle"
            size={'small'}
            aria-label="enhanced table"
            stickyHeader
          >
            <EnhancedTableHeadComponent headCells={headCells} />
            <TableBody>
              <NoItemsWrapper
                length={fiatLimitData?.length as number}
                text={'There are no fiat limit'}
                colSpan={4}
                isTableComponent
                isLoading={!fiatLimitData?.length ? isFetchingFeeData : false}
              >
                {fiatLimitData?.map((row: IFiatLimit, index: number) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <FiatLimitRow
                      key={row?.id}
                      row={row}
                      labelId={labelId}
                      isLoadingFiatLimit={isFetchingFeeData}
                      handleOpenModal={handleOpenFiatLimitModal}
                      handleDeleteTier={handleDeleteFiatLimit}
                    />
                  );
                })}
              </NoItemsWrapper>
            </TableBody>
          </Table>
        </TableContainer>
        {DialogComponent}
      </Paper>
    </>
  );
};
