import React, { FC, useEffect } from 'react';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { BankAccountCard } from '../../../components';
import { useNotification, useServerError } from '../../../hooks';
import { useDialog } from '../../../hooks/useDialog';
import {
  useDeleteBankAccountMutation,
  useGetAllBankAccountQuery,
} from '../../../services';
import { NoItemsWrapper } from '../../../shared';
import { setBankAccountState, setModalState } from '../../../store/ui';
import { IBankAccountSlice } from '../../../types/bank.account.interface';

export const BankAccountList: FC = () => {
  const { showNotification } = useNotification();
  const [handleOpenDialog, DialogComponent] = useDialog();

  const dispatch = useDispatch();

  const {
    data: bankAccountData,
    isFetching: isFetchingBankAccountData,
    isError: isErrorBankAccountData,
    error: errorBankAccountData,
  } = useGetAllBankAccountQuery({});

  const [
    deleteBankAccount,
    {
      isLoading: isLoadingDeleteBankAccount,
      isError: isErrorDeleteBankAccount,
      error: errorDeleteBankAccount,
      isSuccess: isSuccessDeleteBankAccount,
    },
  ] = useDeleteBankAccountMutation();

  useServerError({
    isError: isErrorDeleteBankAccount,
    error: errorDeleteBankAccount,
  });
  useServerError({
    isError: isErrorBankAccountData,
    error: errorBankAccountData,
  });

  const handleDeleteBankAccount = (id: number, bankName: string) => {
    handleOpenDialog({
      description: (
        <>
          Are you sure you want to delete bank account, <b>{bankName}</b>?
        </>
      ),
      buttonApplyHandler: () => deleteBankAccount({ id }),
      loadingDelete: isLoadingDeleteBankAccount,
    });
  };

  const handleCreateBankAccount = () => {
    dispatch(
      setModalState({
        visible: true,
        name: 'bankAccountModal',
      }),
    );
  };
  const handleUpdateBankAccount = (bankAccount: IBankAccountSlice) => {
    dispatch(
      setModalState({
        visible: true,
        name: 'bankAccountModal',
      }),
    );
    dispatch(setBankAccountState(bankAccount));
  };

  useEffect(() => {
    if (isSuccessDeleteBankAccount) {
      showNotification('Bank account was deleted!', 'success');
    }
  }, [isSuccessDeleteBankAccount]);

  return (
    <>
      <Box
        mb={2}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          flexWrap: 'wrap',
        }}
      >
        <Typography variant="h3" color="inherit" noWrap textAlign="left">
          Bank account
        </Typography>
        <Typography sx={{ my: 2 }} textAlign="left">
          Create or update existing bank account
        </Typography>
        <Box>
          <LoadingButton
            variant="contained"
            startIcon={<AccountBalanceIcon />}
            onClick={handleCreateBankAccount}
          >
            Create new bank account
          </LoadingButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            my: 5,
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <NoItemsWrapper
            isLoading={isFetchingBankAccountData}
            length={bankAccountData?.length || 0}
            text="There are no bank account"
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              {bankAccountData?.map((bankAccount) => {
                return (
                  <BankAccountCard
                    key={bankAccount?.id}
                    bankAccount={bankAccount}
                    updateBankAccount={handleUpdateBankAccount}
                    deleteBankAccount={handleDeleteBankAccount}
                  />
                );
              })}
            </Box>
          </NoItemsWrapper>
        </Box>
        {DialogComponent}
      </Box>
    </>
  );
};
