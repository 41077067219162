import React, { FC } from 'react';
import { Container, Typography } from '@mui/material';
import { Grid } from '@mui/material';
import { ReceiveFiatTable } from './receive-fiat/receive.fiat.table';
import { WithdrawsTable } from './withdraws/withdraws.table';

export const OperationsPage: FC = () => {
  return (
    <Container maxWidth="xl">
      <Typography variant="h3" color="inherit" textAlign="left">
        Withdraw requests
      </Typography>
      <Typography sx={{ my: 2 }} textAlign="left">
        List of withdraw requests
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          <WithdrawsTable />
        </Grid>
      </Grid>
      <Typography variant="h3" color="inherit" textAlign="left">
        Receive fiat requests
      </Typography>
      <Typography sx={{ my: 2 }} textAlign="left">
        List of receive fiat Requests
      </Typography>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ReceiveFiatTable />
        </Grid>
      </Grid>
    </Container>
  );
};
