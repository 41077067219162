import React, { ChangeEvent, FC, MouseEvent, useEffect, useState } from 'react';
import {
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { spacing } from '@mui/system';
import { styled } from '@mui/system';
import { useNotification, useServerError } from '@/hooks';
import { useApproveAccountConfirmationsMutation } from '@/services';
import { NoItemsWrapper } from '@/shared';
import { IAccountConfirmationItem } from '@/types/account.confirmation.interface';
import { RequestsRow } from './requests.row';

type HeadCell = {
  id: string;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
};

type AccountConfirmationProps = {
  requests: IAccountConfirmationItem[];
  count: number;
  rowsPerPage: number;
  isLoadingAccountConfirmation: boolean;
  page: number;
  onPageChange: (e: MouseEvent<HTMLButtonElement> | null, page: number) => void;
  onRowsPerPageChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
};

const Paper = styled(MuiPaper)(spacing);

const StyledTableHead = styled(TableHead)(({ theme: styledTheme }) => ({
  height: 55,
  '& .MuiTableCell-root': {
    backgroundColor: styledTheme.palette.grey[200],
  },
}));

const headCells: Array<HeadCell> = [
  {
    id: 'email',
    numeric: false,
    disablePadding: true,
    label: 'Email',
  },
  {
    id: 'user',
    numeric: true,
    disablePadding: true,
    label: 'User',
  },
  {
    id: 'phone',
    numeric: true,
    disablePadding: true,
    label: 'Phone',
  },
  {
    id: 'createdAt',
    numeric: true,
    disablePadding: false,
    label: 'Created at',
  },
  {
    id: 'kycVerified',
    numeric: true,
    disablePadding: false,
    label: 'KYC Verified',
  },
  {
    id: 'isApproved',
    numeric: true,
    disablePadding: false,
    label: 'Is approved',
  },
  {
    id: 'actions-approve',
    numeric: true,
    disablePadding: false,
    label: 'Approve',
  },
];

const EnhancedTableHead: FC = () => {
  return (
    <StyledTableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ padding: '6px 16px' }}
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
};

export const AccountConfirmationTable: FC<AccountConfirmationProps> = ({
  requests,
  page,
  rowsPerPage,
  count,
  onPageChange,
  onRowsPerPageChange,
  isLoadingAccountConfirmation,
}) => {
  const [dialog, setDialog] = useState<{
    id: number;
    isOpen: boolean;
    email: string;
    type: 'approve';
  } | null>(null);

  const mediaTheme = useTheme();
  const matches = useMediaQuery(mediaTheme.breakpoints.up('sm'));

  const { showNotification } = useNotification();

  const [
    approvePdfRequest,
    {
      isLoading: isLoadingApprovePdfRequest,
      isError: isErrorApprovePdfRequest,
      error: errorApprovePdfRequest,
      isSuccess: isSuccessApprovePdfRequest,
    },
  ] = useApproveAccountConfirmationsMutation();

  useServerError({
    isError: isErrorApprovePdfRequest,
    error: errorApprovePdfRequest,
  });

  const handleCloseModal = () => setDialog(null);
  const handleOpenModal = ({
    id,
    email,
    type,
  }: {
    id: number;
    email: string;
    type: 'approve';
  }) => setDialog({ id, isOpen: true, email, type });

  const handleApprove = () => {
    setDialog(null);

    if (dialog?.type === 'approve') {
      approvePdfRequest({ pdfId: dialog.id });
    }
  };

  useEffect(() => {
    if (isSuccessApprovePdfRequest) {
      showNotification('Approved!', 'success');
    }
  }, [isSuccessApprovePdfRequest]);

  return (
    <Paper sx={{ marginBottom: 10 }}>
      <TableContainer sx={{ maxHeight: 640 }}>
        <Table
          sx={{ minWidth: 800 }}
          aria-labelledby="tableTitle"
          size={'small'}
          aria-label="enhanced table"
          stickyHeader
        >
          <EnhancedTableHead />
          <NoItemsWrapper
            isTableComponent
            colSpan={8}
            length={requests?.length}
            text={'There are no requests'}
          >
            <TableBody>
              {!!requests?.length &&
                requests?.map(
                  (row: IAccountConfirmationItem, index: number) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <RequestsRow
                        key={row?.id}
                        row={row}
                        labelId={labelId}
                        handleOpenModal={handleOpenModal}
                        isFetching={
                          isLoadingAccountConfirmation ||
                          isLoadingApprovePdfRequest
                        }
                      />
                    );
                  },
                )}
            </TableBody>
          </NoItemsWrapper>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage={matches ? 'Rows per page:' : ''}
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
      <Dialog
        open={!!dialog?.isOpen}
        onClose={handleCloseModal}
        aria-labelledby="dialog-title"
      >
        <DialogTitle sx={{ textTransform: 'capitalize' }}>
          {dialog?.type}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {dialog?.type} request for {dialog?.email}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button
            sx={{ textTransform: 'capitalize' }}
            variant="outlined"
            onClick={handleApprove}
          >
            {dialog?.type}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};
