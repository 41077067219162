// created by Artem
import { FC } from 'react';
import {
  Box,
  Container,
  Link as MaterialLink,
  styled,
  Typography,
} from '@mui/material';
import { Navigate, Outlet, Link } from 'react-router-dom';
import LogoQBS from '@/assets/logo.svg';
import { useGetUserQuery } from '@/services';
import { AuthWrapComponent, LoadingComponent } from '@/shared';
import { PATH_ADMIN } from '../constants/spa-routes';

const StyledLogoWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  [theme.breakpoints.down('md')]: { width: 200 },
}));

export const AuthLayout: FC = () => {
  const storedToken = localStorage.getItem('token');
  const { data: user, isLoading } = useGetUserQuery(undefined, {
    skip: !storedToken,
  });

  if (isLoading) return <LoadingComponent fullscreen />;
  if (user) return <Navigate to={PATH_ADMIN.TRANSACTION_USERS} />;

  return (
    <AuthWrapComponent>
      <Link to={'/'}>
        <StyledLogoWrapper
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            alignItems: 'center',
            padding: '20px',
          }}
        >
          <img src={LogoQBS} alt="logo" width={115} height={165} />
        </StyledLogoWrapper>
      </Link>
      <Container component="main" maxWidth="xs">
        <Outlet />
        <Typography variant="body2" color="text.secondary" align="center">
          {'Copyright © '}
          <MaterialLink
            color="inherit"
            target="_blank"
            href="https://www.qbs.ag/"
          >
            QBS
          </MaterialLink>
          {` ${new Date().getFullYear()}.`}
        </Typography>
      </Container>
    </AuthWrapComponent>
  );
};
