import React, { FC, useState } from 'react';
import {
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  Box,
  TablePagination,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormHelperText,
} from '@mui/material';
import { spacing } from '@mui/system';
import { styled } from '@mui/system';
import { EnhancedTableHeadComponent } from '@/components';
import { HeadCell } from '@/components/table_head';
import { useDebounce, useServerError, useSortBy } from '@/hooks';
import { usePagination } from '@/hooks/usePagination';
import { CountriesRow } from '@/pages/admin/countries/countries-table/countries.row';
import {
  useGetAllCountriesQuery,
  useUpdateCountryStatusMutation,
} from '@/services/countries.service';
import { NoItemsWrapper } from '@/shared';

const Paper = styled(MuiPaper)(spacing);

const headCells: Array<HeadCell> = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'flag',
    numeric: true,
    disablePadding: false,
    label: 'Flag',
  },
  {
    id: 'code',
    numeric: true,
    disablePadding: false,
    label: 'ISO Code',
  },
  { id: 'status', numeric: true, disablePadding: false, label: 'Status' },
  {
    id: 'button',
    numeric: true,
    disablePadding: false,
    label: 'Update status',
  },
];

enum StatusOptionLabel {
  All = 'All',
  Active = 'Active',
  Inactive = 'Inactive',
}

enum StatusOptionValue {
  All = '-1',
  Active = '1',
  Inactive = '0',
}

const STATUS_OPTIONS = [
  { id: 1, label: StatusOptionLabel.All, value: StatusOptionValue.All },
  { id: 2, label: StatusOptionLabel.Active, value: StatusOptionValue.Active },
  {
    id: 3,
    label: StatusOptionLabel.Inactive,
    value: StatusOptionValue.Inactive,
  },
];

const SORT_LABEL = 'Sort by name';

export const CountriesTable: FC = () => {
  const [statusSort, setStatusSort] = useState<StatusOptionValue>(
    StatusOptionValue.All,
  );

  const [sort, SortComponent] = useSortBy(SORT_LABEL, true);

  const {
    onChangeRowsPerPage,
    onChangePage,
    pagination,
    onSearch: onSearchName,
  } = usePagination();
  const { pagination: searchId, onSearch: onSearchId } = usePagination();
  const { pagination: searchIsoCode, onSearch: onSearchIsoCode } =
    usePagination();

  const searchNameDebounce = useDebounce(pagination.search, 500);
  const searchIdDebounce = useDebounce(searchId.search, 500);
  const searchIsoCodeDebounce = useDebounce(searchIsoCode.search, 500);

  const {
    data: countriesData,
    isFetching: isFetchingCountriesData,
    isError: isErrorCountriesData,
    error: errorCountriesData,
  } = useGetAllCountriesQuery({
    limit: pagination.rowsPerPage,
    page: pagination.page + 1,
    name: searchNameDebounce,
    id: searchIdDebounce,
    isoCode: searchIsoCodeDebounce,
    status: statusSort,
    sort,
  });

  const [updateCountryStatus] = useUpdateCountryStatusMutation();

  const handleUpdateStatus = (id: number, status: boolean) => {
    updateCountryStatus({ id, status });
  };

  const handleChangeStatusSort = (val: string) => {
    setStatusSort(val as StatusOptionValue);
  };

  useServerError({
    isError: isErrorCountriesData,
    error: errorCountriesData,
  });

  return (
    <>
      <Box
        mb={2}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          my: 2,
          gap: '15px',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
            <Box sx={{ width: { xs: '100%', sm: 'auto' } }}>
              <FormHelperText>Search by name</FormHelperText>
              <TextField
                fullWidth
                placeholder="Name"
                size="small"
                onChange={onSearchName}
              />
            </Box>
            <Box sx={{ width: { xs: '100%', sm: 'auto' } }}>
              <FormHelperText>Search by ID</FormHelperText>
              <TextField
                fullWidth
                placeholder="ID"
                size="small"
                onChange={onSearchId}
              />
            </Box>
            <Box sx={{ width: { xs: '100%', sm: 'auto' } }}>
              <FormHelperText>Search by ISO code</FormHelperText>
              <TextField
                fullWidth
                placeholder="ISO code"
                size="small"
                onChange={onSearchIsoCode}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: { xs: '16px', sm: '20px' },
              justifyContent: { xs: 'space-between', sm: 'flex-start' },
              width: { xs: '100%', sm: 'auto' },
            }}
          >
            <Box>
              <FormHelperText>Filter by status</FormHelperText>
              <Select
                fullWidth
                size="small"
                value={statusSort}
                onChange={(event: SelectChangeEvent) => {
                  handleChangeStatusSort(event.target.value);
                }}
              >
                {STATUS_OPTIONS.map((option) => (
                  <MenuItem key={option.id} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box pt="22.92px" display="flex">
              {SortComponent}
            </Box>
          </Box>
        </Box>
      </Box>
      <Paper sx={{ marginBottom: 5 }}>
        <TableContainer sx={{ maxHeight: 640 }}>
          <Table
            sx={{ minWidth: 800 }}
            aria-labelledby="tableTitle"
            size={'small'}
            aria-label="enhanced table"
            stickyHeader
          >
            <EnhancedTableHeadComponent headCells={headCells} />
            <TableBody>
              <NoItemsWrapper
                length={countriesData?.data?.length as number}
                text={'There are no countries'}
                colSpan={4}
                isTableComponent
                isLoading={
                  !countriesData?.data?.length ? isFetchingCountriesData : false
                }
              >
                {countriesData?.data?.map((row: any) => {
                  return (
                    <CountriesRow
                      key={row?.id}
                      row={row}
                      handleChangeStatus={handleUpdateStatus}
                      isFetchingCountriesData={isFetchingCountriesData}
                    />
                  );
                })}
              </NoItemsWrapper>
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={countriesData?.meta?.total as number}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};
