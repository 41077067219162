import { AEDLogo } from '@/assets/icons/aed';
import { EURLogo } from '@/assets/icons/eur';
import { GBPLogo } from '@/assets/icons/gbp';
import { USDLogo } from '@/assets/icons/usd';

export const FIAT_CURRENCY_LIST = [
  {
    name: 'United States Dollar',
    symbol: 'USD',
    sign: '$',
    icon: <USDLogo />,
    id: 1,
  },
  { name: 'Euro', symbol: 'EUR', sign: '€', icon: <EURLogo />, id: 2 },
  {
    name: 'Great Britain Pound',
    symbol: 'GBP',
    sign: '£',
    icon: <GBPLogo />,
    id: 3,
  },
  {
    name: 'Arab Emirates Dirham',
    symbol: 'AED',
    sign: 'د.إ',
    icon: <AEDLogo />,
    id: 4,
  },
];
