export interface IBankAccountSlice {
  id: number | null;
  sbaName: string;
  currency: string;
  multicurrency: boolean;
  isIban: boolean;
  iban: string;
  bic: string;
  accountNumber: string;
  bankName: string;
  beneficiaryInformation: string;
  receivingBankInformation: string;
  contactEmail: string;
  active: boolean;
}
export interface IBankAccountSchema {
  sbaName: string;
  currency: string;
  multicurrency: boolean;
  isIban: boolean;
  iban: string;
  bic: string;
  accountNumber: string;
  bankName: string;
  beneficiaryInformation: string;
  receivingBankInformation: string;
  contactEmail: string;
  active: boolean;
}

export interface IBankAccount {
  id: number;
  sbaName: string;
  currency: string;
  multicurrency: boolean;
  isIban: boolean;
  iban: string;
  bic: string;
  accountNumber: string;
  bankName: string;
  beneficiaryInformation: string;
  receivingBankInformation: string;
  contactEmail: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
}

export enum BANK_ACCOUNT_ENUM {
  bankName = 'Bank name',
  sbaName = 'SBA name',
  accountNumber = 'Account number',
  bic = 'BIC',
  currency = 'Currency',
  multicurrency = 'Multicurrency',
  iban = 'IBAN',
  isIban = 'Is IBAN',
  beneficiaryInformation = 'Beneficiary information',
  receivingBankInformation = 'Receiving bank information',
  contactEmail = 'Contact email',
  active = 'Active',
}

export enum IMAGE_TYPE {
  SIGNATURE = 'signature',
  STAMP = 'stamp',
}
