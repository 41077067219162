import React, { FC, useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TablePagination,
  TableRow,
  Box,
  TextField,
  Autocomplete,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { spacing } from '@mui/system';
import { styled } from '@mui/system';
import { EnhancedTableHeadComponent } from '@/components';
import { HeadCell } from '@/components/table_head';
import { transformDate } from '@/helpers/dateTransformHelper';
import { getBgColorByStatusHelper } from '@/helpers/getBgColorByStatusHelper';
import {
  useNotification,
  useServerError,
  usePagination,
  useSortBy,
} from '@/hooks';
import {
  useChangeStatusReceiveFiatMutation,
  useGetReceiveFiatTransactionQuery,
} from '@/services';
import { NoItemsWrapper } from '@/shared';
import { FiatCurrencies } from '@/types/currencies.interface';
import { DateFormatEnum } from '@/types/dateTimeFormatOption';
import {
  OperationStatusFiat,
  OperationStatusFiatEnum,
} from '@/types/operations.interface';
import { ReceiveFiatItem } from '@/types/receive.fiat.interface';
import { ChangeStatusRow } from './change.status.row';
import { TransactionHistoryRow } from './transaction.history.row';

type WithdrawsTableProps = {};

const Paper = styled(MuiPaper)(spacing);

const headCells: Array<HeadCell> = [
  {
    id: '#',
    numeric: true,
    disablePadding: true,
    label: '#',
  },
  {
    id: 'currency',
    numeric: true,
    disablePadding: true,
    label: 'Currency',
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: false,
    label: 'Amount',
  },
  {
    id: 'createdAt',
    numeric: true,
    disablePadding: false,
    label: 'Created at',
  },
  {
    id: 'transactionId',
    numeric: true,
    disablePadding: false,
    label: 'Transaction Id',
  },
  {
    id: 'accountNumber',
    numeric: true,
    disablePadding: false,
    label: 'Account number',
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: false,
    label: 'Email',
  },
  { id: 'status', numeric: true, disablePadding: false, label: 'Status' },
];

export const ReceiveFiatTable: FC<WithdrawsTableProps> = () => {
  const [statusSort, setStatusSort] = useState<string | null>(null);
  const [currencySort, setCurrencySort] = useState<string | null>(null);

  const { onChangeRowsPerPage, onChangePage, pagination, onSearch } =
    usePagination();

  const [sort, SortComponent] = useSortBy();

  const { data, isFetching, error, isError } =
    useGetReceiveFiatTransactionQuery(
      {
        skip: pagination.page * pagination.rowsPerPage,
        take: pagination.rowsPerPage,
        sortOrder: sort,
        search: pagination.search,
        status: statusSort ? (statusSort as string) : undefined,
        currency: currencySort ? (currencySort as string) : undefined,
      },
      { refetchOnMountOrArgChange: true },
    );

  useServerError({ isError: isError, error: error });
  const { showNotification } = useNotification();

  const [
    changeReceiveFiatStatus,
    {
      isLoading: isLoadingChangeReceiveFiatStatus,
      isError: isErrorChangeReceiveFiatStatus,
      error: errorChangeReceiveFiatStatus,
      isSuccess: isSuccessChangeReceiveFiatStatus,
    },
  ] = useChangeStatusReceiveFiatMutation();

  const mediaTheme = useTheme();
  const matches = useMediaQuery(mediaTheme.breakpoints.up('sm'));

  const handleChangeStatus = ({
    id,
    status,
  }: {
    id: number;
    status: OperationStatusFiat;
  }) => {
    changeReceiveFiatStatus({
      id,
      status: status,
    });
  };

  const handleChangeStatusSort = (val: string | null) => {
    setStatusSort(val);
  };
  const handleChangeFiatSort = (val: string | null) => {
    setCurrencySort(val);
  };

  useServerError({
    isError: isErrorChangeReceiveFiatStatus,
    error: errorChangeReceiveFiatStatus,
  });

  useEffect(() => {
    if (isSuccessChangeReceiveFiatStatus) {
      showNotification('Status changed!', 'success');
    }
  }, [isSuccessChangeReceiveFiatStatus]);

  const operationStatusOptions = Object.values(OperationStatusFiatEnum);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          my: 2,
          gap: '15px',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <TextField placeholder="Search" size="small" onChange={onSearch} />
        </Box>
        <Autocomplete
          size="small"
          sx={{ minWidth: '200px' }}
          disablePortal
          id="combo-box-demo"
          onChange={(event, val) => handleChangeStatusSort(val)}
          value={statusSort}
          options={operationStatusOptions}
          renderInput={(params) => <TextField {...params} label="Status" />}
        />
        <Autocomplete
          size="small"
          sx={{ minWidth: '100px' }}
          disablePortal
          id="combo-box-demo"
          onChange={(event, val) => handleChangeFiatSort(val)}
          value={currencySort}
          options={FiatCurrencies}
          renderInput={(params) => <TextField {...params} label="Fiat" />}
        />
        {SortComponent}
      </Box>
      <Paper sx={{ marginBottom: 5 }}>
        <TableContainer sx={{ maxHeight: 640 }}>
          <Table
            sx={{ minWidth: 800 }}
            aria-labelledby="tableTitle"
            size={'small'}
            aria-label="enhanced table"
            stickyHeader
          >
            <EnhancedTableHeadComponent headCells={headCells} />
            <NoItemsWrapper
              isTableComponent
              colSpan={7}
              length={data?.items?.length as number}
              text={
                isError ? 'Something went wrong' : 'There are no operations'
              }
              isLoading={isFetching}
              emptyRows={pagination.rowsPerPage}
            >
              <TableBody>
                {!!data?.items &&
                  data?.items?.map((row: any, index: number) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <OperationsRow
                        key={row?.id}
                        row={row}
                        labelId={labelId}
                        handleChangeReceiveFiatStatus={handleChangeStatus}
                        isLoadingChangeStatus={isLoadingChangeReceiveFiatStatus}
                      />
                    );
                  })}
              </TableBody>
            </NoItemsWrapper>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage={matches ? 'Rows per page:' : ''}
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={data?.meta?.count as number}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

type TransactionsRowProps = {
  row: ReceiveFiatItem;
  labelId: any;
  handleChangeReceiveFiatStatus: ({
    id,
    status,
  }: {
    id: number;
    status: OperationStatusFiat;
  }) => void;
  isLoadingChangeStatus: boolean;
};

const OperationsRow: FC<TransactionsRowProps> = ({
  row,
  labelId,
  handleChangeReceiveFiatStatus,
  isLoadingChangeStatus,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <TableRow
        sx={{
          backgroundColor: getBgColorByStatusHelper(row.status),
        }}
        tabIndex={-1}
        key={row.id}
      >
        <TableCell id={labelId} align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell id={labelId} align="center">
          {row?.currency}
        </TableCell>
        <TableCell align="center" id={labelId} scope="row" padding="normal">
          {row?.amount}
        </TableCell>
        <TableCell
          align="center"
          id={labelId}
          scope="row"
          padding="normal"
          sx={{ textWrap: 'nowrap' }}
        >
          {transformDate(
            row?.createdAt,
            DateFormatEnum.DATE_WITH_TIME_WITH_DOT,
          )}
        </TableCell>
        <TableCell align="center">{row?.transactionId}</TableCell>
        <TableCell align="center">{row?.User?.accountNumber}</TableCell>
        <TableCell align="center">{row?.User?.email}</TableCell>
        <TableCell align="center">{row?.status}</TableCell>
      </TableRow>
      {open && (
        <>
          <ChangeStatusRow
            row={row}
            isLoadingChangeStatus={isLoadingChangeStatus}
            handleChangeReceiveFiatStatus={handleChangeReceiveFiatStatus}
            open={open}
          />
          <TransactionHistoryRow row={row} open={open} />
        </>
      )}
    </>
  );
};
