import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { baseApi } from '@/services';
import { unauthorizedMiddleware } from './middleware/unauthorized-middleware';
import bankAccountSlice from './ui/bank.account.slice';
import bannerSlice from './ui/banner.slice';
import companySlice from './ui/company.slice';
import currencySlice from './ui/currency.slice';
import feeFiatSlice from './ui/fee.fiat.slice';
import uiReducer from './ui/slice';
import tierSlice from './ui/tier.slice';

const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  uiReducer,
  tierSlice,
  feeFiatSlice,
  currencySlice,
  companySlice,
  bankAccountSlice,
  bannerSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([unauthorizedMiddleware, baseApi.middleware]),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = typeof store;
export type AppDispatch = AppStore['dispatch'];
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
