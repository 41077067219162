import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBannerSlice } from '../../types/banners.interface';

export const initialBannerState: IBannerSlice = {
  id: null,
  text: '',
  text2: '',
  type: '',
  userAccountType: '',
  active: false,
  isFuze: false,
};

export const bannerSlice = createSlice({
  name: 'uiSlice',
  initialState: initialBannerState,
  reducers: {
    setBannerState(
      state: IBannerSlice,
      action: PayloadAction<{
        id: number | null;
        text: string;
        text2: string;
        type: string;
        userAccountType: string;
        active: boolean;
        isFuze: boolean;
      }>,
    ) {
      const { id, text, text2, type, active, userAccountType, isFuze } =
        action.payload;
      state.id = id;
      state.text = text;
      state.text2 = text2;
      state.type = type;
      state.userAccountType = userAccountType;
      state.active = active;
      state.isFuze = isFuze;
    },
  },
});

export const { setBannerState } = bannerSlice.actions;

export default bannerSlice.reducer;
