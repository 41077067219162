const ROOTS_AUTH = '/auth';

export enum StepsRegisterEnum {
  VERIFY_EMAIL = 'verify-email',
  VERIFY_PHONE = 'verify-phone',
}

export const PATH_REGISTER = {
  ROOT: ROOTS_AUTH,
  LOGIN: `${ROOTS_AUTH}/login`,
  REGISTER: `${ROOTS_AUTH}/register`,
  REGISTER_PHONE_VERIFY: `${ROOTS_AUTH}/register?${StepsRegisterEnum.VERIFY_PHONE}`,
  REGISTER_EMAIL_VERIFY: `${ROOTS_AUTH}/register?${StepsRegisterEnum.VERIFY_EMAIL}`,
  FORGOT_PASSWORD: `${ROOTS_AUTH}/forgot-password`,
  LOGIN_VERIFY: `${ROOTS_AUTH}/login-verify`,
  LOGIN_PHONE_VERIFY: `${ROOTS_AUTH}/login-phone-verify`,
  GOOGLE_VERIFY: `${ROOTS_AUTH}/google-verify`,
};
export const PATH_ADMIN = {
  ROOT: '',
  TRANSACTION_USERS: `/transactions-users`,
  ACCOUNT_CONFIRMATION: `/account-confirmation`,
  GAS_STATION: `/gas-station`,
  SYSTEM_BALANCE: `/system-balance`,
  OPERATIONS: `/operations`,
  TIER: `/tier`,
  CURRENCIES: `/currencies`,
  USER_ACTIVITY: `/user-activity`,
  SYSTEM_LOGS: `/system-logs`,
  COMPANY_PDF: `/company-pdf`,
  BANK_ACCOUNT: `/bank-account`,
  BANNERS: `/banners`,
  EMAIL_TEMPLATE: `/email-template`,
  CREATE_EMAIL_TEMPLATE: `/email-template/create`,
  USER_FEEDBACKS: `/user-feedbacks`,
  DEPOSIT_ADDRESSES: `/deposit-addresses`,
  COUNTRIES: `/countries`,
};
