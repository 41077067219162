import { PATH_ADMIN_API } from '../constants/api-routes';
import { baseApi } from './base.api';

export const stampService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getStamp: build.query<any, {}>({
      query: () => ({
        url: PATH_ADMIN_API.STAMP_IMAGE,
        responseHandler: async (data) => {
          return data.text();
        },
      }),
      providesTags: ['Stamp'],
    }),
    uploadStump: build.mutation<any, FormData>({
      query: (body) => ({
        url: PATH_ADMIN_API.STAMP_IMAGE,
        method: 'POST',
        body,
        formData: true,
      }),
      invalidatesTags: ['Stamp'],
    }),
    deleteStump: build.mutation<any, any>({
      query: () => ({
        url: PATH_ADMIN_API.STAMP_IMAGE,
        method: 'DELETE',
      }),
      invalidatesTags: ['Stamp'],
    }),
  }),
});

export const {
  useGetStampQuery,
  useDeleteStumpMutation,
  useUploadStumpMutation,
} = stampService;
