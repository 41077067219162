import { PATH_ADMIN_API } from '@/constants/api-routes';
import {
  CreateEmailTemplatePayload,
  IEmailTemplate,
  IEmailTemplateKeys,
  UpdateEmailTemplatePayload,
} from '@/types/email.template.interface';
import { baseApi } from './base.api';

export const emailTemplateService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllEmailTemplate: build.query<IEmailTemplate[], {}>({
      query: () => ({
        url: PATH_ADMIN_API.EMAIL_TEMPLATE_ALL,
      }),
      providesTags: ['EmailTemplatesList'],
    }),
    getEmailTemplateById: build.query<
      IEmailTemplate,
      { id: string | undefined }
    >({
      query: ({ id }) => ({
        url: `${PATH_ADMIN_API.EMAIL_TEMPLATE}/${id}`,
      }),
      providesTags: ['EmailTemplate'],
    }),
    getEmailTemplateTypes: build.query<{}, {}>({
      query: () => ({
        url: PATH_ADMIN_API.EMAIL_TEMPLATE_TYPES,
      }),
    }),
    getEmailTemplateKeys: build.query<IEmailTemplateKeys, {}>({
      query: () => ({
        url: PATH_ADMIN_API.EMAIL_TEMPLATE_KEYS,
      }),
    }),
    createEmailTemplate: build.mutation<any, CreateEmailTemplatePayload>({
      query: (body) => ({
        url: PATH_ADMIN_API.EMAIL_TEMPLATE,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['EmailTemplatesList', 'EmailTemplate'],
    }),
    updateEmailTemplate: build.mutation<any, UpdateEmailTemplatePayload>({
      query: (body) => ({
        url: `${PATH_ADMIN_API.EMAIL_TEMPLATE}/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['EmailTemplatesList', 'EmailTemplate'],
    }),
    deleteEmailTemplate: build.mutation<
      any,
      {
        id: number;
      }
    >({
      query: ({ id }) => ({
        url: `${PATH_ADMIN_API.EMAIL_TEMPLATE}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['EmailTemplatesList'],
    }),
  }),
});

export const {
  useGetAllEmailTemplateQuery,
  useGetEmailTemplateByIdQuery,
  useGetEmailTemplateTypesQuery,
  useCreateEmailTemplateMutation,
  useDeleteEmailTemplateMutation,
  useUpdateEmailTemplateMutation,
  useGetEmailTemplateKeysQuery,
} = emailTemplateService;
