import React, { FC } from 'react';
import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { activityJsonParseHelper } from '../../../../helpers/activityJsonParseHelper';
import { transformDate } from '../../../../helpers/dateTransformHelper';
import { DateFormatEnum } from '../../../../types/dateTimeFormatOption';
import { IUserActivityItem } from '../../../../types/user.interface';

type UserActivityRowProps = {
  row: IUserActivityItem;
  labelId: any;
};

export const ActivityRow: FC<UserActivityRowProps> = ({ row, labelId }) => {
  const parsedMetadata = row?.metadata && JSON.parse(row?.metadata);
  return (
    <TableRow tabIndex={-1} key={row.id}>
      <TableCell id={labelId} align="center">
        {row?.User?.id}
      </TableCell>
      <TableCell align="center">{row?.User?.email}</TableCell>
      <TableCell align="center">{row?.action}</TableCell>
      <TableCell align="center">
        {transformDate(row?.createdAt, DateFormatEnum.DATE_WITH_TIME_WITH_DOT)}
      </TableCell>
      <TableCell align="center">
        {parsedMetadata &&
          Object.keys(parsedMetadata)?.map((el) => {
            return (
              <Box key={el}>
                <Typography textAlign="left">
                  {el}: {activityJsonParseHelper(parsedMetadata[el])}
                </Typography>
              </Box>
            );
          })}
      </TableCell>
    </TableRow>
  );
};
