import React, { FC, memo, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  Button,
  CardContent,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Switch,
  TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { object } from 'yup';
import { useNotification, useServerError } from '@/hooks';
import {
  useCreateBannerMutation,
  useGetBannerTypesQuery,
  useUpdateBannerMutation,
} from '../../../services/banner.service';
import {
  ACCOUNT_TYPE,
  BANNER_TYPE,
  IBannerSchema,
  IBannerSlice,
  USER_KYC_KYB_NOT_VERIFIED,
} from '../../../types/banners.interface';
import { booleanScheme, stringScheme } from '../../../utils';

type BannerModalProps = IBannerSlice & {
  open: boolean;
  onClose: () => void;
};

export const BannerModal: FC<BannerModalProps> = memo(
  ({
    open,
    onClose,
    id,
    text,
    text2,
    type,
    active,
    userAccountType,
    isFuze,
  }) => {
    const { showNotification } = useNotification();

    const { data: bannerTypes, isFetching: isFetchingBannerTypes } =
      useGetBannerTypesQuery({});

    const [
      createBanner,
      {
        isLoading: isLoadingCreateBanner,
        isError: isErrorCreateBanner,
        error: errorCreateBanner,
        isSuccess: isSuccessCreateBanner,
      },
    ] = useCreateBannerMutation();

    const [
      updateBanner,
      {
        isLoading: isLoadingUpdateBanner,
        isError: isErrorUpdateBanner,
        error: errorUpdateBanner,
        isSuccess: isSuccessUpdateBanner,
      },
    ] = useUpdateBannerMutation();

    const {
      handleSubmit,
      control,
      watch,
      formState: { isDirty },
    } = useForm<IBannerSchema>({
      defaultValues: {
        text,
        text2,
        type:
          type === BANNER_TYPE.USER_KYC_KYB_NOT_VERIFIED
            ? USER_KYC_KYB_NOT_VERIFIED
            : type,
        active,
        isFuze,
        userAccountType,
      },
      resolver: yupResolver(
        object({
          text: stringScheme({ required: true }).label('Title'),
          text2: stringScheme({ required: true }).label('Description'),
          type: stringScheme({ required: true }).label('Type'),
          userAccountType: stringScheme({ required: true }).label(
            'User account type',
          ),
          active: booleanScheme({ required: false }).label('Active'),
          isFuze: booleanScheme({ required: false }).label('Is Fuze'),
        }),
      ) as Resolver<IBannerSchema>,
    });

    const activeWatch = watch('active');
    const isFuzeWatch = watch('isFuze');

    const onSubmit = (data: IBannerSchema) => {
      if (id) {
        updateBanner({
          text: data.text,
          text2: data.text2,
          type: bannerTypes![data.type as keyof BANNER_TYPE] as string,
          userAccountType: data.userAccountType,
          active: data.active,
          isFuze: data.isFuze,
          id,
        });
      } else {
        createBanner({
          text: data.text,
          text2: data.text2,
          type: bannerTypes![data.type as keyof BANNER_TYPE] as string,
          userAccountType: data.userAccountType,
          active: data.active,
          isFuze: data.isFuze,
        });
      }
    };

    useServerError({
      isError: isErrorCreateBanner,
      error: errorCreateBanner,
    });
    useServerError({
      isError: isErrorUpdateBanner,
      error: errorUpdateBanner,
    });

    useEffect(() => {
      if (isSuccessCreateBanner) {
        onClose();
        showNotification('Banner was created!', 'success');
      }
      if (isSuccessUpdateBanner) {
        onClose();
        showNotification('Banner was updated!', 'success');
      }
    }, [isSuccessCreateBanner, isSuccessUpdateBanner]);

    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <CardContent sx={{ overflow: 'auto' }}>
          <CardHeader
            sx={{ textAlign: 'center' }}
            title={id ? 'Banner update' : 'Banner create'}
          />
          <Divider />
          <Typography m={1} py={2} variant="h5">
            {id && (
              <Typography variant="h6">
                Update banner <b>#{id}</b>
              </Typography>
            )}
          </Typography>
          <Box
            sx={{
              minWidth: 120,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="text"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="string"
                    required
                    onChange={onChange}
                    value={value}
                    multiline
                    fullWidth
                    id="bankName"
                    label="Title"
                    name="bankName"
                    autoComplete="Title"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="text2"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="string"
                    onChange={onChange}
                    value={value}
                    fullWidth
                    multiline
                    required
                    id="description"
                    label="Description"
                    name="description"
                    autoComplete="Description"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="userAccountType"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <Autocomplete
                    sx={{ m: '16px 0 8px 0' }}
                    disablePortal
                    id="combo-box-demo"
                    onChange={(event, val) => onChange(val)}
                    value={value}
                    options={Object.values(ACCOUNT_TYPE)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="User account type"
                        error={!!error?.message}
                        helperText={error?.message}
                      />
                    )}
                  />
                </FormControl>
              )}
            />{' '}
            <Controller
              control={control}
              name="type"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <Autocomplete
                    sx={{ m: '16px 0 8px 0' }}
                    disablePortal
                    id="combo-box-demo"
                    onChange={(event, val) => onChange(val)}
                    value={value}
                    disabled={isFetchingBannerTypes}
                    loading={isFetchingBannerTypes}
                    options={bannerTypes ? Object.keys(bannerTypes) : []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Type"
                        required
                        error={!!error?.message}
                        helperText={error?.message}
                      />
                    )}
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="active"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl sx={{ mt: 2 }}>
                  <InputLabel id="demo-simple-select-helper-label" shrink>
                    Active
                  </InputLabel>

                  <FormControlLabel
                    onChange={onChange}
                    control={<Switch defaultChecked checked={value} />}
                    label={`${activeWatch}`}
                  />
                  {error && (
                    <FormHelperText error={!!error.message}>
                      {error.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="isFuze"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl sx={{ mt: 2 }}>
                  <InputLabel id="demo-simple-select-helper-label" shrink>
                    Is Fuze
                  </InputLabel>

                  <FormControlLabel
                    onChange={onChange}
                    control={<Switch defaultChecked checked={value} />}
                    label={`${isFuzeWatch}`}
                  />
                  {error && (
                    <FormHelperText error={!!error.message}>
                      {error.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Box>
          <Button sx={{ mt: 3, ml: 1 }} onClick={onClose} variant="outlined">
            Close
          </Button>
          <LoadingButton
            endIcon={<SaveIcon />}
            loadingPosition="end"
            variant="contained"
            loading={isLoadingCreateBanner || isLoadingUpdateBanner}
            onClick={handleSubmit(onSubmit)}
            disabled={!isDirty}
            sx={{ mt: 3, ml: 1 }}
            type="submit"
          >
            {id ? 'Update' : 'Create'}
          </LoadingButton>
        </CardContent>
      </Dialog>
    );
  },
);
