import React, { FC } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { IconButton, TableCell, TableRow, Box } from '@mui/material';
import { theme } from '../../../../assets/theme';
import { getIconVerified } from '../../../../helpers/getIconVerifiedHelper';
import { UsersInterface } from '../../../../types/admin.interface';

type UserRowProps = {
  row: UsersInterface;
  labelId: any;
  handleOpenModal: any;
  isLoadingUsers: boolean;
  disableEdit?: boolean;
};

export const UserRow: FC<UserRowProps> = ({
  row,
  labelId,
  handleOpenModal,
  isLoadingUsers,
}) => {
  return (
    <TableRow
      tabIndex={-1}
      key={row.id}
      sx={{
        backgroundColor: row.isDeleted
          ? theme.palette.error.light
          : theme.palette.background.default,
      }}
    >
      <TableCell id={labelId} align="center">
        {row?.id}
      </TableCell>
      <TableCell id={labelId} align="left">
        {row?.name}
      </TableCell>
      <TableCell id={labelId} align="left">
        {row?.lastName}
      </TableCell>
      <TableCell align="center">{row?.email}</TableCell>
      <TableCell align="center">{getIconVerified(row?.kycVerified)}</TableCell>
      <TableCell align="center">
        {getIconVerified(row?.vaultAutoFuel)}
      </TableCell>
      <TableCell align="center">{row?.role}</TableCell>
      <TableCell align="right">
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <IconButton
            aria-label="Settings"
            size="large"
            title="Settings"
            onClick={() =>
              handleOpenModal({
                id: row.id,
                userRole: row?.role,
                userName: `${row?.name} ${row?.lastName}`,
                vaultAutoFuel: row?.vaultAutoFuel,
              })
            }
            disabled={isLoadingUsers}
          >
            <SettingsIcon />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
};
