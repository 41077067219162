import React from 'react';
import { Box, TableCell, Typography } from '@mui/material';
import { Loader } from '../loading/loader';

type NoItemsWrapperProps = {
  length: number;
  text?: string;
  children?: React.ReactNode;
  isTableComponent?: boolean;
  colSpan?: number;
  isLoading?: boolean;
  emptyRows?: number;
};

export const NoItemsWrapper: React.FC<NoItemsWrapperProps> = ({
  length,
  text = 'There are no items',
  children,
  isTableComponent = false,
  colSpan = 1,
  isLoading = false,
  emptyRows = 0,
}) => {
  return length > 0 && !isLoading ? (
    <>{children}</>
  ) : (
    <Box
      component={isTableComponent ? TableCell : 'div'}
      sx={{ py: emptyRows ? 0 : 5 }}
      width={'100%'}
      colSpan={colSpan}
    >
      {isLoading ? (
        <Loader
          size={20}
          sx={{
            height: emptyRows ? `${47 * emptyRows}px` : 'auto',
            alignItems: 'center',
          }}
        />
      ) : (
        <Typography gutterBottom align="center" variant="subtitle1">
          {text}
        </Typography>
      )}
    </Box>
  );
};
