import React, { FC } from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/system';

export type HeadCell = {
  id: string;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
  noWrap?: boolean;
};

const StyledTableHead = styled(TableHead)(({ theme: styledTheme }) => ({
  height: 55,
  '& .MuiTableCell-root': {
    backgroundColor: styledTheme.palette.grey[200],
  },
}));

interface ITableHead {
  headCells: HeadCell[];
}
export const EnhancedTableHeadComponent: FC<ITableHead> = ({ headCells }) => {
  return (
    <StyledTableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{
              padding: '6px 16px',
              textWrap: headCell?.noWrap ? 'nowrap' : 'inherit',
            }}
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
};
