import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICurrencySlice } from '@/types/currencies.interface';

export const initialCurrencyState: ICurrencySlice = {
  id: null,
  customName: '',
  icon: '',
  networkQrCode: '',
  protocolQrCode: '',
  b2c2CurrencyName: '',
  fuzeCurrencyName: '',
  status: false,
  isDefault: false,
  ordering: null,
  externalId: '',
  reason: null,
  isVisible: false,
};

export const currencySlice = createSlice({
  name: 'uiSlice',
  initialState: initialCurrencyState,
  reducers: {
    setCurrencyState(
      state: ICurrencySlice,
      action: PayloadAction<{
        customName: string;
        icon: string;
        id: number | null;
        networkQrCode: string;
        protocolQrCode: string;
        b2c2CurrencyName: string;
        fuzeCurrencyName: string;
        status: boolean;
        isDefault: boolean;
        ordering: number | null;
        externalId: string;
        reason: string | null;
        isVisible: boolean;
      }>,
    ) {
      const {
        id,
        customName,
        icon,
        networkQrCode,
        protocolQrCode,
        b2c2CurrencyName,
        fuzeCurrencyName,
        status,
        isDefault,
        ordering,
        externalId,
        reason,
        isVisible,
      } = action.payload;
      state.id = id;
      state.customName = customName;
      state.icon = icon;
      state.networkQrCode = networkQrCode;
      state.protocolQrCode = protocolQrCode;
      state.status = status;
      state.isDefault = isDefault;
      state.b2c2CurrencyName = b2c2CurrencyName;
      state.fuzeCurrencyName = fuzeCurrencyName;
      state.ordering = ordering;
      state.externalId = externalId;
      state.reason = reason;
      state.isVisible = isVisible;
    },
  },
});

export const { setCurrencyState } = currencySlice.actions;

export default currencySlice.reducer;
