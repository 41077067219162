import React, { FC, memo, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  CardContent,
  Dialog,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { object } from 'yup';
import { useNotification, useServerError } from '@/hooks';
import { useChangeTierForUserMutation, useGetAllTierQuery } from '@/services';
import { IUserTier } from '@/types/admin.interface';
import { ITier } from '@/types/tier.interface';
import { stringScheme } from '@/utils';

type ChangeLoginTypeProps = {
  open: boolean;
  onClose: () => void;
  userId: string | number | null | undefined;
  tierId: number | null | undefined;
  userName: string | null | undefined;
};

export const ChangeUserTier: FC<ChangeLoginTypeProps> = memo(
  ({ open, onClose, tierId, userId, userName }) => {
    const { showNotification } = useNotification();

    const [
      changeUserTier,
      {
        isLoading: isLoadingChangeUserTier,
        isError: isErrorChangeUserTier,
        error: errorChangeUserTier,
        isSuccess: isSuccessChangeUserTier,
      },
    ] = useChangeTierForUserMutation();

    const { data: tierData, isFetching: isFetchingTierData } =
      useGetAllTierQuery(null);

    const { handleSubmit, control, watch } = useForm<IUserTier>({
      defaultValues: {
        tierId: tierId as number,
      },
      resolver: yupResolver(
        object({
          tierId: stringScheme({ required: true }),
        }),
      ) as Resolver<any>,
    });

    const tier = watch('tierId');

    const onSubmit = () => {
      changeUserTier({ userId: userId as number, tierId: +tier });
    };

    useServerError({
      isError: isErrorChangeUserTier,
      error: errorChangeUserTier,
    });

    useEffect(() => {
      if (isSuccessChangeUserTier) {
        onClose();
        showNotification(
          `User tier for user ${userName} was changed!`,
          'success',
        );
      }
    }, [isSuccessChangeUserTier]);

    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <CardContent sx={{ overflow: 'auto' }}>
          <CardHeader sx={{ textAlign: 'center' }} title="User Tier" />
          <Divider />
          <Typography m={1} py={2} variant="h5">
            Changing user tier id for{' '}
            <b style={{ wordBreak: 'break-word' }}>{userName}</b>
          </Typography>
          <Box
            sx={{
              minWidth: 120,
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="tierId"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl error={!!error} fullWidth>
                  <InputLabel id="demo-simple-select-error-label">
                    Tier
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-error-label"
                    id="demo-simple-select-error"
                    value={value as string}
                    label="Tier"
                    onChange={(event: SelectChangeEvent) => {
                      onChange(event.target.value);
                    }}
                    disabled={isFetchingTierData}
                  >
                    {tierData &&
                      tierData?.map((el: ITier) => {
                        return (
                          <MenuItem key={el?.id} value={el?.id as any}>
                            {el?.name}, ID {el.id}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {error && <FormHelperText>{error.message}</FormHelperText>}
                </FormControl>
              )}
            />
          </Box>
          <Button sx={{ mt: 3, ml: 1 }} onClick={onClose} variant="outlined">
            Close
          </Button>
          <LoadingButton
            endIcon={<SaveIcon />}
            loadingPosition="end"
            disabled={tierId === tier}
            loading={isLoadingChangeUserTier}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            sx={{ mt: 3, ml: 1 }}
            type="submit"
          >
            Save
          </LoadingButton>
        </CardContent>
      </Dialog>
    );
  },
);
