import React, { FC } from 'react';
import { Box, Divider, FormHelperText, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { transformDate } from '../../helpers/dateTransformHelper';
import { getIconVerified } from '../../helpers/getIconVerifiedHelper';
import {
  BANNER_ENUM,
  BANNER_TYPE,
  IBanner,
  IBannerSlice,
  USER_KYC_KYB_NOT_VERIFIED,
} from '../../types/banners.interface';
import { DateFormatEnum } from '../../types/dateTimeFormatOption';

type BannerProps = {
  banner: IBanner;
  updateBanner: (props: IBannerSlice) => void;
  deleteBanner: (id: number) => void;
};
export const BannerCard: FC<BannerProps> = ({
  banner,
  updateBanner = () => {},
  deleteBanner = () => {},
}) => {
  const handleUpdate = () => {
    updateBanner(banner);
  };
  const handleDelete = () => {
    deleteBanner(banner?.id);
  };
  const bannerType =
    banner.type === BANNER_TYPE.USER_KYC_KYB_NOT_VERIFIED
      ? USER_KYC_KYB_NOT_VERIFIED
      : banner.type;
  return (
    <Card sx={{ maxWidth: '100%' }} elevation={3}>
      <CardContent sx={{ display: 'flex' }}>
        <Box width={'100%'}>
          <Box sx={{ mb: 1, display: 'flex' }}>
            <Typography variant="h5" component="div">
              #{banner.id}, {bannerType}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <Box>
              <Grid container>
                {Object.keys(BANNER_ENUM).map((el: string) => {
                  const enumKey = el as keyof typeof BANNER_ENUM;
                  const bannerValue =
                    banner[enumKey] === BANNER_TYPE.USER_KYC_KYB_NOT_VERIFIED
                      ? USER_KYC_KYB_NOT_VERIFIED
                      : banner[enumKey];
                  return (
                    <React.Fragment key={el}>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={3}
                        lg={3}
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          width="100%"
                        >
                          {BANNER_ENUM[enumKey]}:
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        md={9}
                        lg={9}
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Typography
                          variant="body2"
                          width="100%"
                          ml={1}
                          whiteSpace="pre-line"
                          textAlign="left"
                        >
                          {typeof banner[enumKey] === 'boolean'
                            ? getIconVerified(
                                Boolean(banner[enumKey]) as boolean,
                              )
                            : bannerValue}
                        </Typography>
                      </Grid>
                      <Divider sx={{ width: '100%', my: 1 }} />
                    </React.Fragment>
                  );
                })}
              </Grid>
            </Box>
          </Box>
        </Box>
      </CardContent>
      <CardActions>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            flexWrap: 'wrap',
            gap: '10px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
            }}
          >
            <Button size="small" variant="outlined" onClick={handleUpdate}>
              Update
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="error"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Box>
          <Box>
            <FormHelperText>
              Created at{' '}
              {transformDate(
                banner?.createdAt,
                DateFormatEnum.DATE_WITH_TIME_WITH_DOT,
              )}
            </FormHelperText>
            {banner?.updatedAt && (
              <FormHelperText sx={{ mt: 0 }}>
                Updated at{' '}
                {transformDate(
                  banner?.updatedAt,
                  DateFormatEnum.DATE_WITH_TIME_WITH_DOT,
                )}
              </FormHelperText>
            )}
          </Box>
        </Box>
      </CardActions>
    </Card>
  );
};
