import React, { FC } from 'react';
import { Container, Grid } from '@mui/material';
import { CompanyPdfList } from './company.pdf.list';

type CompanyPdfProps = {};
export const CompanyPdf: FC<CompanyPdfProps> = () => {
  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid item xs={12}>
          <CompanyPdfList />
        </Grid>
      </Grid>
    </Container>
  );
};
