import React, { FC, useEffect, useState } from 'react';
import { Delete as DeleteIcon } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  TableCell,
  TableRow,
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import { transformDate } from '@/helpers/dateTransformHelper';
import { useNotification, useServerError } from '@/hooks';
import { useDialog } from '@/hooks/useDialog';
import { useDeleteUserFeedbackMutation } from '@/services';
import { DateFormatEnum } from '@/types/dateTimeFormatOption';
import { IUserFeedback } from '@/types/user.feedbacks.interface';
import { FeedbackContentRow } from './feedback.content.row';

type UserFeedbackRowProps = {
  row: IUserFeedback;
  isFetching?: boolean;
};

export const UserFeedbackRow: FC<UserFeedbackRowProps> = ({
  row,
  isFetching,
}) => {
  const [opened, setOpened] = useState(false);

  const [handleOpenDialog, DialogComponent] = useDialog();

  const [
    deleteFeedback,
    {
      isLoading: deletingFeedback,
      isError: isDeleteFeedbackError,
      error: deleteFeedbackError,
      isSuccess: isDeleteFeedbackSuccess,
    },
  ] = useDeleteUserFeedbackMutation();

  useServerError({
    isError: isDeleteFeedbackError,
    error: deleteFeedbackError,
  });

  const onDeleteClick = () => {
    handleOpenDialog({
      title: 'Delete user feedback',
      description: `Are you sure you want to delete feedback from ${
        row?.User?.email ?? 'unknown user'
      }?`,
      applyBtnTitle: 'Delete',
      applyButtonColor: 'error',
      buttonApplyHandler: () => deleteFeedback({ id: row.id }),
      loadingDelete: deletingFeedback,
    });
  };

  const { showNotification } = useNotification();

  useEffect(() => {
    if (isDeleteFeedbackSuccess) {
      showNotification('User feedback deleted!', 'success');
    }
  }, [isDeleteFeedbackSuccess]);

  const onExpandRowClick = () => setOpened(!opened);

  return (
    <>
      <TableRow tabIndex={-1} sx={{ position: opened ? 'sticky' : 'static' }}>
        <TableCell align="left">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={onExpandRowClick}
          >
            {opened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center">{row?.id}</TableCell>
        <TableCell align="left">{row?.title}</TableCell>
        <TableCell align="left">
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {row?.feedback}
          </Typography>
        </TableCell>
        <TableCell align="center">{row?.User?.email ?? '-'}</TableCell>

        <TableCell align="center">
          {transformDate(
            row?.createdAt,
            DateFormatEnum.DATE_WITH_TIME_WITH_DOT,
          )}
        </TableCell>

        <TableCell align="center">
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton
              aria-label="Delete"
              size="medium"
              title="Delete feedback"
              onClick={onDeleteClick}
              disabled={isFetching || deletingFeedback}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <FeedbackContentRow row={row} opened={opened} />
      {DialogComponent}
    </>
  );
};
