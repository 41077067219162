import React, { FC, useState } from 'react';
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { Grid } from '@mui/material';
import { useServerError } from '../../../hooks';
import { usePagination } from '../../../hooks/usePagination';
import { useGetAccountConfirmationsQuery } from '../../../services';
import { Loader } from '../../../shared';
import { AccountConfirmationTable } from './account.confirmation.table';

export const AccountConfirmationPage: FC = () => {
  const [checkedIsApproved, setCheckedIsApproved] = useState<boolean>(false);

  const { onChangeRowsPerPage, onChangePage, pagination, resetPagination } =
    usePagination();

  const { data, isFetching, error, isError } = useGetAccountConfirmationsQuery(
    {
      skip: pagination.page * pagination.rowsPerPage,
      take: pagination.rowsPerPage,
      isApproved: checkedIsApproved,
    },
    { refetchOnMountOrArgChange: true },
  );

  useServerError({ isError: isError, error: error });

  const handleChangeApproved = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedIsApproved(event.target.checked);
    resetPagination();
  };

  return (
    <Container maxWidth="xl">
      <Typography variant="h3" color="inherit" textAlign="left">
        Accounts confirmation
      </Typography>
      <Typography sx={{ my: 2 }} textAlign="left">
        List of Accounts confirmation
      </Typography>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <FormControlLabel
            label="Approved"
            control={
              data?.items && isFetching && checkedIsApproved ? (
                <Loader
                  size={15}
                  sx={{
                    height: '42px',
                    width: '42px',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                />
              ) : (
                <Checkbox
                  checked={checkedIsApproved}
                  onChange={handleChangeApproved}
                />
              )
            }
          />
          {data?.items ? (
            <AccountConfirmationTable
              requests={data?.items}
              count={data?.meta.count}
              page={pagination?.page}
              rowsPerPage={pagination?.rowsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              isLoadingAccountConfirmation={isFetching}
            />
          ) : (
            <Box display="flex" alignItems="center" justifyContent="center">
              {isFetching ? (
                <Loader />
              ) : (
                <Typography>Something went wrong!</Typography>
              )}
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
