import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBankAccountSlice } from '../../types/bank.account.interface';

export const initialBankAccountState: IBankAccountSlice = {
  id: null,
  sbaName: '',
  currency: '',
  multicurrency: false,
  isIban: false,
  iban: '',
  bic: '',
  accountNumber: '',
  bankName: '',
  beneficiaryInformation: '',
  receivingBankInformation: '',
  contactEmail: '',
  active: false,
};

export const bankAccountSlice = createSlice({
  name: 'uiSlice',
  initialState: initialBankAccountState,
  reducers: {
    setBankAccountState(
      state: IBankAccountSlice,
      action: PayloadAction<{
        id: number | null;
        sbaName: string;
        currency: string;
        multicurrency: boolean;
        isIban: boolean;
        iban: string;
        bic: string;
        accountNumber: string;
        bankName: string;
        beneficiaryInformation: string;
        receivingBankInformation: string;
        contactEmail: string;
        active: boolean;
      }>,
    ) {
      const {
        id,
        sbaName,
        currency,
        multicurrency,
        isIban,
        iban,
        bic,
        accountNumber,
        bankName,
        beneficiaryInformation,
        receivingBankInformation,
        contactEmail,
        active,
      } = action.payload;
      state.id = id;
      state.sbaName = sbaName;
      state.currency = currency;
      state.multicurrency = multicurrency;
      state.isIban = isIban;
      state.iban = iban;
      state.bic = bic;
      state.accountNumber = accountNumber;
      state.bankName = bankName;
      state.beneficiaryInformation = beneficiaryInformation;
      state.receivingBankInformation = receivingBankInformation;
      state.contactEmail = contactEmail;
      state.active = active;
    },
  },
});

export const { setBankAccountState } = bankAccountSlice.actions;

export default bankAccountSlice.reducer;
