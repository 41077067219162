import { PATH_ADMIN_API } from '../constants/api-routes';
import { IFee, IFeeCondition } from '../types/fee.interface';
import { baseApi } from './base.api';

export const feeService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllFee: build.query<IFee[], null>({
      query: () => ({
        url: PATH_ADMIN_API.FEE_ALL,
      }),
      providesTags: ['Fee'],
    }),
    getFeeConditions: build.query<IFeeCondition, null>({
      query: () => ({
        url: PATH_ADMIN_API.FEE_CONDITIONS,
      }),
      providesTags: ['Fee'],
    }),
    getFeeById: build.query<any, { id: number }>({
      query: ({ id }) => ({
        url: `${PATH_ADMIN_API.FEE}/${id}`,
      }),
    }),
    deleteFee: build.mutation<any, { id: number }>({
      query: ({ id }) => ({
        url: `${PATH_ADMIN_API.FEE}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Fee'],
    }),
    updateFee: build.mutation<
      any,
      {
        id: number;
        value: number | null;
        applyCondition: string;
        tierId: number | null | string;
      }
    >({
      query: ({ id, value, applyCondition, tierId }) => ({
        url: `${PATH_ADMIN_API.FEE}/${id}`,
        body: {
          value,
          applyCondition,
          tierId,
        },
        method: 'PUT',
      }),
      invalidatesTags: ['Fee'],
    }),
    createFee: build.mutation<
      any,
      {
        value: number | null;
        applyCondition: string;
        tierId: number | null | string;
      }
    >({
      query: ({ value, applyCondition, tierId }) => ({
        url: PATH_ADMIN_API.FEE,
        method: 'POST',
        body: {
          value,
          applyCondition,
          tierId,
        },
      }),
      invalidatesTags: ['Fee'],
    }),
  }),
});

export const {
  useGetAllFeeQuery,
  useGetFeeByIdQuery,
  useCreateFeeMutation,
  useDeleteFeeMutation,
  useUpdateFeeMutation,
  useGetFeeConditionsQuery,
} = feeService;
