import React, { FC, useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TablePagination,
  TableRow,
  Box,
  TextField,
  Autocomplete,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { spacing } from '@mui/system';
import { styled } from '@mui/system';
import { EnhancedTableHeadComponent } from '@/components';
import { transformDate } from '@/helpers/dateTransformHelper';
import { getBgColorByStatusHelper } from '@/helpers/getBgColorByStatusHelper';
import { useNotification, useServerError } from '@/hooks';
import { usePagination } from '@/hooks/usePagination';
import {
  useChangeStatusWithdrawRequestMutation,
  useGetCryptoCurrencyForFiltersQuery,
  useGetWithdrawRequestsQuery,
} from '@/services';
import { NoItemsWrapper } from '@/shared';
import { FiatCurrencies } from '@/types/currencies.interface';
import { DateFormatEnum } from '@/types/dateTimeFormatOption';
import { useSortBy } from '../../../../hooks/useSortBy';
import {
  OperationStatusFiat,
  OperationStatusFiatEnum,
} from '../../../../types/operations.interface';
import {
  ICryptoCurrencyForFilter,
  WithdrawItem,
} from '../../../../types/withdraw.interface';
import { ChangeStatusRow } from './change.status.row';
import { TransactionHistoryRow } from './transaction.history.row';

type HeadCell = {
  id: string;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
};

type WithdrawsTableProps = {};

const Paper = styled(MuiPaper)(spacing);

const headCells: Array<HeadCell> = [
  {
    id: '#',
    numeric: true,
    disablePadding: true,
    label: '#',
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: true,
    label: 'Email',
  },
  {
    id: 'oldBalance',
    numeric: true,
    disablePadding: true,
    label: 'Old balance',
  },
  {
    id: 'newBalance',
    numeric: true,
    disablePadding: true,
    label: 'New balance',
  },
  {
    id: 'accountNumber',
    numeric: true,
    disablePadding: true,
    label: 'Account number',
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: true,
    label: 'Amount',
  },
  {
    id: 'fiat',
    numeric: true,
    disablePadding: false,
    label: 'Fiat',
  },
  {
    id: 'assetName',
    numeric: true,
    disablePadding: false,
    label: 'Asset name',
  },
  {
    id: 'from',
    numeric: true,
    disablePadding: false,
    label: 'From',
  },
  {
    id: 'to',
    numeric: true,
    disablePadding: false,
    label: 'To',
  },
  {
    id: 'iban',
    numeric: true,
    disablePadding: false,
    label: 'IBAN',
  },
  {
    id: 'createdAt',
    numeric: true,
    disablePadding: false,
    label: 'Created at',
  },
  { id: 'status', numeric: true, disablePadding: false, label: 'Status' },
];

export const WithdrawsTable: FC<WithdrawsTableProps> = () => {
  const { onChangeRowsPerPage, onChangePage, pagination, onSearch } =
    usePagination();

  const [statusSort, setStatusSort] = useState<string | null>(null);
  const [currencyFiatSort, setCurrencyFiatSort] = useState<string | null>(null);
  const [currencyAssetSort, setCurrencyAssetSort] =
    useState<ICryptoCurrencyForFilter | null>(null);
  const [getFilterCurrencies, setGetFilterCurrencies] =
    useState<boolean>(false);

  const [sort, SortComponent] = useSortBy();

  const { data, isFetching, error, isError } = useGetWithdrawRequestsQuery(
    {
      skip: pagination.page * pagination.rowsPerPage,
      take: pagination.rowsPerPage,
      search: pagination.search,
      status: statusSort ? (statusSort as string) : undefined,
      fiat: currencyFiatSort ? (currencyFiatSort as string) : undefined,
      cryptoAssetId: currencyAssetSort
        ? (currencyAssetSort.id as number)
        : undefined,
      sortOrder: sort,
    },
    { refetchOnMountOrArgChange: true },
  );

  const {
    data: dataCryptoForFilter,
    isFetching: isFetchingDataCryptoForFilter,
    error: errorDataCryptoForFilter,
    isError: isErrorDataCryptoForFilter,
  } = useGetCryptoCurrencyForFiltersQuery({}, { skip: !getFilterCurrencies });

  useServerError({ isError: isError, error: error });
  useServerError({
    isError: isErrorDataCryptoForFilter,
    error: errorDataCryptoForFilter,
  });
  const { showNotification } = useNotification();

  const [
    changeWithdrawStatus,
    {
      isLoading: isLoadingChangeWithdrawStatus,
      isError: isErrorChangeWithdrawStatus,
      error: errorChangeWithdrawStatus,
      isSuccess: isSuccessChangeWithdrawStatus,
    },
  ] = useChangeStatusWithdrawRequestMutation();

  const mediaTheme = useTheme();
  const matches = useMediaQuery(mediaTheme.breakpoints.up('sm'));

  const handleChangeStatus = ({
    id,
    status,
  }: {
    id: number;
    status: OperationStatusFiat;
  }) => {
    changeWithdrawStatus({
      withdrawRequestId: id,
      status: status,
    });
  };

  const handleChangeStatusSort = (val: string | null) => {
    setStatusSort(val);
  };
  const handleChangeFiatSort = (val: string | null) => {
    setCurrencyFiatSort(val);
  };
  const handleChangeAssetSort = (val: ICryptoCurrencyForFilter | null) => {
    setCurrencyAssetSort(val);
  };

  useServerError({
    isError: isErrorChangeWithdrawStatus,
    error: errorChangeWithdrawStatus,
  });

  useEffect(() => {
    if (isSuccessChangeWithdrawStatus) {
      showNotification('Status changed!', 'success');
    }
  }, [isSuccessChangeWithdrawStatus]);

  const operationStatusOptions = Object.values(OperationStatusFiatEnum);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          my: 2,
          gap: '15px',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <TextField placeholder="Search" size="small" onChange={onSearch} />
        </Box>
        <Autocomplete
          size="small"
          sx={{ minWidth: '200px' }}
          disablePortal
          id="combo-box-demo"
          onChange={(event, val) => handleChangeStatusSort(val)}
          value={statusSort}
          options={operationStatusOptions}
          renderInput={(params) => <TextField {...params} label="Status" />}
        />
        <Autocomplete
          size="small"
          sx={{ minWidth: '100px' }}
          disablePortal
          id="combo-box-demo"
          onChange={(event, val) => handleChangeFiatSort(val)}
          value={currencyFiatSort}
          disabled={!!currencyAssetSort}
          options={FiatCurrencies}
          renderInput={(params) => <TextField {...params} label="Fiat" />}
        />
        <Autocomplete
          size="small"
          sx={{ minWidth: '200px' }}
          disablePortal
          id="combo-box-demo"
          onChange={(event, val) => handleChangeAssetSort(val)}
          value={currencyAssetSort}
          disabled={!!currencyFiatSort}
          getOptionLabel={(option) => option?.externalId}
          loading={isFetchingDataCryptoForFilter}
          options={
            isFetchingDataCryptoForFilter
              ? []
              : (dataCryptoForFilter as readonly ICryptoCurrencyForFilter[])
          }
          onOpen={() => setGetFilterCurrencies(true)}
          renderInput={(params) => <TextField {...params} label="Asset name" />}
        />
        {SortComponent}
      </Box>
      <Paper sx={{ marginBottom: 5 }}>
        <TableContainer sx={{ maxHeight: 640 }}>
          <Table
            sx={{ minWidth: 800 }}
            aria-labelledby="tableTitle"
            size={'small'}
            aria-label="enhanced table"
            stickyHeader
          >
            <EnhancedTableHeadComponent headCells={headCells} />
            <NoItemsWrapper
              isTableComponent
              colSpan={13}
              length={data?.items?.length as number}
              text={
                isError ? 'Something went wrong' : 'There are no operations'
              }
              isLoading={isFetching}
              emptyRows={pagination.rowsPerPage}
            >
              <TableBody>
                {!!data?.items &&
                  data?.items?.map((row: any, index: number) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TransactionsRow
                        key={row?.id}
                        row={row}
                        labelId={labelId}
                        handleChangeWithdrawStatus={handleChangeStatus}
                        isLoadingChangeStatus={isLoadingChangeWithdrawStatus}
                      />
                    );
                  })}
              </TableBody>
            </NoItemsWrapper>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage={matches ? 'Rows per page:' : ''}
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={data?.meta?.count as number}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

type TransactionsRowProps = {
  row: WithdrawItem;
  labelId: any;
  handleChangeWithdrawStatus: ({
    id,
    status,
  }: {
    id: number;
    status: OperationStatusFiat;
  }) => void;
  isLoadingChangeStatus: boolean;
};

const TransactionsRow: FC<TransactionsRowProps> = ({
  row,
  labelId,
  handleChangeWithdrawStatus,
  isLoadingChangeStatus,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <TableRow
        sx={{
          backgroundColor: getBgColorByStatusHelper(row.status),
        }}
        tabIndex={-1}
        key={row.id}
      >
        <TableCell id={labelId} align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell id={labelId} align="center">
          {row?.User?.email}
        </TableCell>
        <TableCell id={labelId} align="center">
          {row?.oldBalance}
        </TableCell>
        <TableCell id={labelId} align="center">
          {row?.newBalance}
        </TableCell>
        <TableCell id={labelId} align="center">
          {row?.User?.accountNumber}
        </TableCell>
        <TableCell align="center">{row?.total}</TableCell>
        <TableCell align="center">{row?.fiat}</TableCell>
        <TableCell align="center">{row?.assetName}</TableCell>
        <TableCell align="center">{row?.from}</TableCell>
        <TableCell align="center">{row?.to}</TableCell>
        <TableCell align="center">{row?.Iban?.account}</TableCell>
        <TableCell
          align="center"
          id={labelId}
          scope="row"
          padding="normal"
          sx={{ textWrap: 'nowrap' }}
        >
          {transformDate(
            row?.createdAt,
            DateFormatEnum.DATE_WITH_TIME_WITH_DOT,
          )}
        </TableCell>
        <TableCell align="center">{row?.status}</TableCell>
      </TableRow>
      {open && (
        <>
          <ChangeStatusRow
            isLoadingChangeStatus={isLoadingChangeStatus}
            row={row}
            open={open}
            handleChangeWithdrawStatus={handleChangeWithdrawStatus}
          />
          <TransactionHistoryRow row={row} open={open} />
        </>
      )}
    </>
  );
};
