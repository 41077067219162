import React, { FC } from 'react';
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography,
  Collapse,
} from '@mui/material';
import { styled } from '@mui/system';
import { theme } from '../../../../assets/theme';
import { useServerError } from '../../../../hooks';
import { useGetSystemLogsStackTraceQuery } from '../../../../services';
import { ISystemLogsItem } from '../../../../types/system.logs.interface';

const StyledTableHead = styled(TableHead)(({ theme: styledTheme }) => ({
  height: 40,
  '& .MuiTableCell-root': {
    backgroundColor: styledTheme.palette.grey[200],
  },
  whiteSpace: 'pre-line',
}));

type StackTraceRowProps = {
  row: ISystemLogsItem;
  open: boolean;
};

export const StackTraceRow: FC<StackTraceRowProps> = ({ row, open }) => {
  const {
    data: stackTraceData,
    isError: isErrorStackTrace,
    error: errorStackTrace,
  } = useGetSystemLogsStackTraceQuery(
    { id: row?.id as number },
    { skip: !open, refetchOnMountOrArgChange: true },
  );

  useServerError({ isError: isErrorStackTrace, error: errorStackTrace });

  return (
    <>
      {open && (
        <TableRow sx={{ backgroundColor: theme.palette.grey[100] }}>
          <TableCell style={{ paddingBottom: 15, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Stack trace
                </Typography>
              </Box>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="a dense table">
                  <StyledTableHead>
                    <TableRow>
                      <TableCell padding="none" align="left" sx={{ pl: 2 }}>
                        Message: {stackTraceData?.message}
                      </TableCell>
                    </TableRow>
                  </StyledTableHead>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
