import React, { FC } from 'react';
import { Container, Grid } from '@mui/material';
import { FeeTable } from './fee-table/fee.table';
import { FiatLimitTable } from './fiat-limit-table/fiat.limit.table';
import { TierTable } from './tier-table/tier.table';

type TierProps = {};
export const Tier: FC<TierProps> = () => {
  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid item xs={12}>
          <TierTable />
        </Grid>
        <Grid item xs={12}>
          <FeeTable />
        </Grid>
        <Grid item xs={12}>
          <FiatLimitTable />
        </Grid>
      </Grid>
    </Container>
  );
};
