import React, { FC } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { IFuzeDepositAddress } from '@/types/fuze.deposit.addresses.interface';

type DepositAddressesRowProps = {
  row: IFuzeDepositAddress;
};

export const DepositAddressesRow: FC<DepositAddressesRowProps> = ({ row }) => {
  return (
    <TableRow tabIndex={-1}>
      <TableCell align="left">{row.id}</TableCell>
      <TableCell align="left">{row.address}</TableCell>
      <TableCell align="left">{row.asset}</TableCell>
      <TableCell align="left">{row.chain}</TableCell>
      <TableCell align="left">{row.fireblocksWalletId}</TableCell>
      <TableCell align="left">{row.fuzeId}</TableCell>
      <TableCell align="center">{row.userWalletId}</TableCell>
    </TableRow>
  );
};
