import { FC } from 'react';
import { useAppDispatch, useAppSelector } from '@/store';
import {
  initialBankAccountState,
  initialBannerState,
  initialCompanyState,
  initialCurrencyState,
  ModalNames,
  setBankAccountState,
  setBannerState,
  setCompanyState,
  setCurrencyState,
  setModalState,
  setRolesState,
} from '@/store/ui';
import { AutoFuelEdit } from './auto_fuel_edit';
import { BankAccountModal } from './bank_account';
import { BannerModal } from './banner';
import { ChangeUserRole } from './change_user_role';
import { ChangeUserTier } from './change_user_tier';
import { CompanyModal } from './company';
import { CurrencyModal } from './currency';
import { FeeCreateEditModal } from './fee_create_edit';
import { FiatLimitCreateEditModal } from './fiat_limit_create_edit';
import { GasStationEdit } from './gas_station_edit';
import { InviteUser } from './invite-user';
import { TierCreateEditModal } from './tier_create_edit';

export const ModalManager: FC = () => {
  const dispatch = useAppDispatch();
  const {
    modalsVisibility,
    userId,
    userRole,
    userName,
    vaultAutoFuel,
    tierId,
  } = useAppSelector((s) => s.uiReducer);
  const { id, isDefault, name: tierName } = useAppSelector((s) => s.tierSlice);
  const {
    id: feeId,
    value: feeValue,
    tierId: feeTierId,
    applyCondition: feeApplyCondition,
    feeFiat: feeFiat,
  } = useAppSelector((s) => s.feeFiatSlice);
  const {
    id: currencyId,
    customName: currencyCustomName,
    icon: currencyIcon,
    networkQrCode: currencyNetworkQrCode,
    protocolQrCode: currencyProtocolQrCode,
    status: currencyStatus,
    isDefault: currencyIsDefault,
    b2c2CurrencyName,
    fuzeCurrencyName,
    ordering: orderingCurrency,
    externalId: currencyExternalId,
    reason: currencyReason,
    isVisible: currencyIsVisible,
  } = useAppSelector((s) => s.currencySlice);
  const {
    id: companyId,
    companyName,
    companyTag,
    companyCode,
    addressLine1,
    addressLine2,
    addressLine3,
    addressLine4,
    officerName,
    officerMail,
    officerPosition,
    isDefault: companyIsDefault,
    isFuze: companyIsFuze,
    accountStatementFooter,
  } = useAppSelector((s) => s.companySlice);
  const {
    id: bankAccountId,
    sbaName,
    currency: bankAccountCurrency,
    multicurrency,
    isIban,
    iban: bankAccountIban,
    bic,
    accountNumber: bankAccountAccountNumber,
    bankName,
    beneficiaryInformation,
    receivingBankInformation,
    contactEmail,
    active: bankAccountActive,
  } = useAppSelector((s) => s.bankAccountSlice);
  const {
    id: bannerId,
    text: bannerText,
    text2: bannerText2,
    type: bannerType,
    userAccountType: bannerUserAccountType,
    active: bannerActive,
    isFuze: bannerIsFuze,
  } = useAppSelector((s) => s.bannerSlice);

  const onModalClose = (name: ModalNames) => {
    dispatch(setModalState({ visible: false, name }));
    dispatch(
      setRolesState({
        userId: null,
        userName: null,
        vaultAutoFuel: null,
        tierId: null,
      }),
    );
    if (companyId) {
      dispatch(setCompanyState(initialCompanyState));
    }
    if (bankAccountId) {
      dispatch(setBankAccountState(initialBankAccountState));
    }
    if (bannerId) {
      dispatch(setBannerState(initialBannerState));
    }
    if (currencyId) {
      dispatch(setCurrencyState(initialCurrencyState));
    }
  };

  return (
    <>
      {modalsVisibility.changeRole && (
        <ChangeUserRole
          open={modalsVisibility.changeRole}
          onClose={() => onModalClose('changeRole')}
          userId={userId}
          userRole={userRole}
          userName={userName}
        />
      )}
      {modalsVisibility.autoFuelEdit && (
        <AutoFuelEdit
          open={modalsVisibility.autoFuelEdit}
          onClose={() => onModalClose('autoFuelEdit')}
          userId={userId}
          userRole={userRole}
          userName={userName}
          vaultAutoFuel={vaultAutoFuel}
        />
      )}
      {modalsVisibility.gasStationEdit && (
        <GasStationEdit
          open={modalsVisibility.gasStationEdit}
          onClose={() => onModalClose('gasStationEdit')}
        />
      )}
      {modalsVisibility.tierModal && (
        <TierCreateEditModal
          open={modalsVisibility.tierModal}
          onClose={() => onModalClose('tierModal')}
          tierName={tierName}
          id={id}
          isDefault={isDefault}
        />
      )}
      {modalsVisibility.feeModal && (
        <FeeCreateEditModal
          open={modalsVisibility.feeModal}
          onClose={() => onModalClose('feeModal')}
          id={feeId}
          feeValue={feeValue}
          feeTierId={feeTierId}
          feeApplyCondition={feeApplyCondition}
        />
      )}
      {modalsVisibility.fiatLimitModal && (
        <FiatLimitCreateEditModal
          open={modalsVisibility.fiatLimitModal}
          onClose={() => onModalClose('fiatLimitModal')}
          id={feeId}
          feeValue={feeValue}
          feeTierId={feeTierId}
          feeApplyCondition={feeApplyCondition}
          feeFiat={feeFiat}
        />
      )}
      {modalsVisibility.changeTierForUser && (
        <ChangeUserTier
          open={modalsVisibility.changeTierForUser}
          onClose={() => onModalClose('changeTierForUser')}
          userId={userId}
          tierId={tierId}
          userName={userName}
        />
      )}
      {modalsVisibility.inviteUser && (
        <InviteUser
          open={modalsVisibility.inviteUser}
          onClose={() => onModalClose('inviteUser')}
        />
      )}
      {modalsVisibility.currencyModal && (
        <CurrencyModal
          open={modalsVisibility.currencyModal}
          onClose={() => onModalClose('currencyModal')}
          id={currencyId}
          customName={currencyCustomName}
          icon={currencyIcon}
          networkQrCode={currencyNetworkQrCode}
          protocolQrCode={currencyProtocolQrCode}
          status={currencyStatus}
          isDefault={currencyIsDefault}
          b2c2CurrencyName={b2c2CurrencyName}
          fuzeCurrencyName={fuzeCurrencyName}
          ordering={orderingCurrency}
          externalId={currencyExternalId}
          reason={currencyReason}
          isVisible={currencyIsVisible}
        />
      )}
      {modalsVisibility.companyModal && (
        <CompanyModal
          open={modalsVisibility.companyModal}
          onClose={() => onModalClose('companyModal')}
          id={companyId}
          companyName={companyName}
          companyTag={companyTag}
          companyCode={companyCode}
          addressLine1={addressLine1}
          addressLine2={addressLine2}
          addressLine3={addressLine3}
          addressLine4={addressLine4}
          officerName={officerName}
          officerMail={officerMail}
          officerPosition={officerPosition}
          isDefault={companyIsDefault}
          isFuze={companyIsFuze}
          accountStatementFooter={accountStatementFooter}
        />
      )}
      {modalsVisibility.bankAccountModal && (
        <BankAccountModal
          open={modalsVisibility.bankAccountModal}
          onClose={() => onModalClose('bankAccountModal')}
          id={bankAccountId}
          sbaName={sbaName}
          currency={bankAccountCurrency}
          multicurrency={multicurrency}
          isIban={isIban}
          iban={bankAccountIban}
          bic={bic}
          accountNumber={bankAccountAccountNumber}
          bankName={bankName}
          beneficiaryInformation={beneficiaryInformation}
          receivingBankInformation={receivingBankInformation}
          contactEmail={contactEmail}
          active={bankAccountActive}
        />
      )}
      {modalsVisibility.bannersModal && (
        <BannerModal
          open={modalsVisibility.bannersModal}
          onClose={() => onModalClose('bannersModal')}
          id={bannerId}
          text={bannerText}
          text2={bannerText2}
          type={bannerType}
          userAccountType={bannerUserAccountType}
          active={bannerActive}
          isFuze={bannerIsFuze}
        />
      )}
    </>
  );
};
