import { createBrowserRouter, Navigate } from 'react-router-dom';
import { PATH_ADMIN, PATH_REGISTER } from '@/constants/spa-routes';
import { AdminLayout, AuthLayout } from '@/layouts';
import {
  AuthVerifyEmailPage,
  ForgotPage,
  LoginPage,
  RegisterPage,
  ResetPasswordPage,
  TransactionsUsersPage,
  SystemBalancePage,
  OperationsPage,
  AccountConfirmationPage,
  UsersGasStationPage,
  Tier,
  Currencies,
  Activity,
  CompanyPdf,
  BankAccount,
  Banners,
  EmailTemplate,
  SystemLogs,
  UserFeedbacksPage,
  DepositAddressesPage,
  CountriesPage,
  NotFoundPage,
} from '@/pages';
import { GoogleAuthPage } from '@/pages/login/google.auth.page';
import { EmailTemplateItem } from '@/pages/admin/email-template-item/email-template-item';

export const routes = createBrowserRouter([
  {
    path: '',
    element: <AdminLayout />,
    children: [
      { path: '', element: <Navigate to={PATH_ADMIN.TRANSACTION_USERS} /> },
      {
        path: PATH_ADMIN.TRANSACTION_USERS,
        element: <TransactionsUsersPage />,
      },
      { path: PATH_ADMIN.GAS_STATION, element: <UsersGasStationPage /> },
      { path: PATH_ADMIN.SYSTEM_BALANCE, element: <SystemBalancePage /> },
      { path: PATH_ADMIN.OPERATIONS, element: <OperationsPage /> },
      {
        path: PATH_ADMIN.ACCOUNT_CONFIRMATION,
        element: <AccountConfirmationPage />,
      },
      { path: PATH_ADMIN.TIER, element: <Tier /> },
      { path: PATH_ADMIN.CURRENCIES, element: <Currencies /> },
      { path: PATH_ADMIN.USER_ACTIVITY, element: <Activity /> },
      { path: PATH_ADMIN.COMPANY_PDF, element: <CompanyPdf /> },
      { path: PATH_ADMIN.BANK_ACCOUNT, element: <BankAccount /> },
      { path: PATH_ADMIN.BANNERS, element: <Banners /> },
      { path: PATH_ADMIN.EMAIL_TEMPLATE, element: <EmailTemplate /> },
      {
        path: `${PATH_ADMIN.EMAIL_TEMPLATE}/create`,
        element: <EmailTemplateItem />,
      },
      {
        path: `${PATH_ADMIN.EMAIL_TEMPLATE}/:id`,
        element: <EmailTemplateItem />,
      },
      { path: PATH_ADMIN.SYSTEM_LOGS, element: <SystemLogs /> },
      { path: PATH_ADMIN.USER_FEEDBACKS, element: <UserFeedbacksPage /> },
      { path: PATH_ADMIN.DEPOSIT_ADDRESSES, element: <DepositAddressesPage /> },
      { path: PATH_ADMIN.COUNTRIES, element: <CountriesPage /> },
      { path: '*', element: <NotFoundPage /> },
    ],
  },
  {
    path: PATH_REGISTER.ROOT,
    element: <AuthLayout />,
    children: [
      { path: '', element: <LoginPage /> },
      { path: 'invite', element: <RegisterPage /> },
      { path: 'register', element: <RegisterPage /> },
      { path: 'forgot', element: <ForgotPage /> },
      { path: 'reset-password', element: <ResetPasswordPage /> },
      { path: 'register-verify', element: <AuthVerifyEmailPage /> },
      { path: 'login-verify', element: <AuthVerifyEmailPage /> },
      { path: 'google-verify', element: <GoogleAuthPage /> },
      { path: '*', element: <NotFoundPage /> },
    ],
  },
  { path: '*', element: <NotFoundPage /> },
]);
