import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITier } from '../../types/tier.interface';

const initialState: ITier = {
  id: null,
  name: '',
  isDefault: false,
};

export const tierSlice = createSlice({
  name: 'uiSlice',
  initialState,
  reducers: {
    setTierState(
      state: ITier,
      action: PayloadAction<{
        id: number | null;
        name: string;
        isDefault: boolean;
      }>,
    ) {
      const { id, name, isDefault } = action.payload;
      state.id = id;
      state.name = name;
      state.isDefault = isDefault;
    },
  },
});

export const { setTierState } = tierSlice.actions;

export default tierSlice.reducer;
