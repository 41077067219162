// created by Artem
import { FC, useEffect } from 'react';
import { Container } from '@mui/material';
import {
  Navigate,
  Outlet,
  ScrollRestoration,
  useNavigate,
} from 'react-router-dom';
import { HeaderAdmin } from '@/components/header/header.admin';
import { ModalManager } from '@/components/modals/modal_manager';
import { theme } from '../assets/theme';
import { PATH_REGISTER } from '../constants/spa-routes';
import { storedKeys } from '../constants/stored-keys';
import { useNotification } from '../hooks';
import { useGetUserQuery } from '../services';
import { LoadingComponent } from '../shared';

export const AdminLayout: FC = () => {
  const storedToken = localStorage.getItem('token');
  const { data: user, isLoading } = useGetUserQuery(undefined, {
    skip: !storedToken,
  });

  const { showNotification } = useNotification();

  const navigate = useNavigate();
  useEffect(() => {
    if (user?.role === storedKeys.USER_ROLE) {
      localStorage.removeItem('token');
      showNotification('Your have no permission!', 'warning');
      navigate(PATH_REGISTER.ROOT);
    }
  }, [user]);

  if (isLoading) return <LoadingComponent fullscreen />;
  if (!user) return <Navigate to={PATH_REGISTER.ROOT} />;

  return (
    <>
      <HeaderAdmin />

      <Container
        component="main"
        sx={{
          p: { xs: 2.5, md: 5 },
          px: { xs: 0, sm: 2.5 },
          ml: { sm: `calc(${theme.spacing(8)} + 1px)` },
          width: { sm: `calc(100% - calc(${theme.spacing(8)} + 1px))` },
          maxWidth: { sm: '100%' },
          background: '#fff',
        }}
      >
        <Outlet />
      </Container>
      <ModalManager />
      <ScrollRestoration />
    </>
  );
};
