import React, { ChangeEvent, FC, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import SendIcon from '@mui/icons-material/Send';
import { LoadingButton } from '@mui/lab';
import {
  Typography,
  Container,
  Grid,
  TextField,
  InputLabel,
  FormControlLabel,
  Switch,
  FormHelperText,
  FormControl,
} from '@mui/material';
import { styled } from '@mui/system';
import { Controller, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { object } from 'yup';
import {
  useGetAdminSettingsQuery,
  useUpdateAdminSettingsMutation,
} from '@/services';
import {
  AdminBalanceInterface,
  SupportedSettingNames,
} from '@/types/admin.interface';
import { feeScheme, numberScheme, stringScheme, totalScheme } from '@/utils';
import { useNotification, useServerError } from '../../../hooks';

const StyledGridItem = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
}));

type SystemBalanceProps = {};

const SHOW_NERWORK_FEE_KEY = 'showNetworkFeeForSendCrypto';

export const SystemBalancePage: FC<SystemBalanceProps> = () => {
  const { data: adminSettings } = useGetAdminSettingsQuery({});

  const [
    changeSystemSettings,
    {
      isLoading,
      isError: isErrorSystemDeposite,
      error: errorSystemDeposite,
      isSuccess,
    },
  ] = useUpdateAdminSettingsMutation();

  const { showNotification } = useNotification();

  const { handleSubmit, control, reset } = useForm<AdminBalanceInterface>({
    defaultValues: {
      eurSystemBalance: 0,
      usdSystemBalance: 0,
      gbpSystemBalance: 0,
      aedSystemBalance: 0,
      fee: 0,
      showNetworkFeeForSendCrypto: 0,
      jurisdiction: '',
      companyName: '',
      companyRegistrationNumber: '',
      companyAddress: '',
    },
    resolver: yupResolver(
      object({
        total: totalScheme({ min: 1 }),
        fee: feeScheme(),
        systemBankAccount: stringScheme({ required: true }),
        showNetworkFeeForSendCrypto: numberScheme(),
        jurisdiction: stringScheme(),
        companyName: stringScheme(),
        companyRegistrationNumber: stringScheme(),
        companyAddress: stringScheme(),
      }),
    ) as Resolver<any>,
  });

  const onSubmit: SubmitHandler<AdminBalanceInterface> = (data) => {
    const payload = {
      settings: [
        'fee',
        'usdSystemBalance',
        'eurSystemBalance',
        'gbpSystemBalance',
        'aedSystemBalance',
        'showNetworkFeeForSendCrypto',
        'jurisdiction',
        'companyName',
        'companyRegistrationNumber',
        'companyAddress',
      ].map((b) => {
        const key = b as SupportedSettingNames;

        return {
          settingName: key,
          settingValue: data[key].toString(),
        };
      }),
    };

    return changeSystemSettings(payload);
  };

  const handleChangeTotal = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: { (...event: any[]): void; (arg0: string | null): void },
  ) => {
    const inputValue = e.target.value.trim();
    if (inputValue === '') {
      onChange(null);
    } else {
      const numericValue = parseInt(inputValue);
      if (numericValue >= 1) {
        onChange(numericValue.toString());
      }
    }
  };

  useEffect(() => {
    if (adminSettings) {
      const resetPayload = adminSettings?.reduce((acc, curr) => {
        if (curr.settingName === SHOW_NERWORK_FEE_KEY) {
          return {
            ...acc,
            [curr.settingName as SupportedSettingNames]: parseInt(
              curr.settingValue,
            ),
          };
        }

        return {
          ...acc,
          [curr.settingName as SupportedSettingNames]: curr.settingValue,
        };
      }, {});

      reset(resetPayload);
    }
  }, [adminSettings]);

  useServerError({
    isError: isErrorSystemDeposite,
    error: errorSystemDeposite,
  });

  useEffect(() => {
    if (isSuccess) {
      reset();
      showNotification('Admin setting are successfully changed.', 'success');
    }
  }, [isSuccess]);

  return (
    <Container maxWidth="xl">
      <Typography variant="h3" color="inherit" noWrap textAlign="left">
        System Balance
      </Typography>
      <Grid
        container
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        // spacing={4}
        columnSpacing={4}
        rowSpacing={{ xs: 2, sm: 0 }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
          }}
        >
          <Typography sx={{ my: 2 }} textAlign="left">
            Fiat balance deposit
          </Typography>
          <StyledGridItem item sm={10} xs={12} sx={{ mb: 2, width: '100%' }}>
            <Controller
              control={control}
              name="eurSystemBalance"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  error={!!error?.message}
                  helperText={error?.message}
                  required
                  value={value}
                  onChange={(e) => handleChangeTotal(e, onChange)}
                  type="number"
                  fullWidth
                  id="eurSystemBalance"
                  label="EUR System Balance"
                  autoComplete="EUR System balance"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
            />
          </StyledGridItem>
          <StyledGridItem item sm={10} xs={12} sx={{ mb: 2, width: '100%' }}>
            <Controller
              control={control}
              name="usdSystemBalance"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  error={!!error?.message}
                  helperText={error?.message}
                  required
                  value={value}
                  onChange={(e) => handleChangeTotal(e, onChange)}
                  type="number"
                  fullWidth
                  id="usdSystemBalance"
                  label="USD System Balance"
                  autoComplete="USD System balance"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
            />
          </StyledGridItem>
          <StyledGridItem item sm={10} xs={12} sx={{ mb: 2, width: '100%' }}>
            <Controller
              control={control}
              name="gbpSystemBalance"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  error={!!error?.message}
                  helperText={error?.message}
                  required
                  value={value}
                  onChange={(e) => handleChangeTotal(e, onChange)}
                  type="number"
                  fullWidth
                  id="gbpSystemBalance"
                  label="GBP System Balance"
                  autoComplete="GBP System balance"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
            />
          </StyledGridItem>
          <StyledGridItem item sm={10} xs={12} sx={{ mb: 2, width: '100%' }}>
            <Controller
              control={control}
              name="aedSystemBalance"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  error={!!error?.message}
                  helperText={error?.message}
                  required
                  value={value}
                  onChange={(e) => handleChangeTotal(e, onChange)}
                  type="number"
                  fullWidth
                  id="aedSystemBalance"
                  label="AED System Balance"
                  autoComplete="AED System balance"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
            />
          </StyledGridItem>
          <StyledGridItem item sm={10} xs={12} sx={{ mb: 2, width: '100%' }}>
            <Controller
              control={control}
              name="showNetworkFeeForSendCrypto"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl sx={{ maxWidth: '100%', width: '100%' }}>
                  <InputLabel shrink>Show Network Fee (Send Crypto)</InputLabel>
                  <FormControlLabel
                    onChange={(event, checked) => onChange(Number(checked))}
                    control={<Switch checked={!!value} />}
                    label={value ? 'Show' : 'Hide'}
                  />
                  {error && (
                    <FormHelperText error={!!error.message}>
                      {error.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </StyledGridItem>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
            pb: { xs: 0, sm: 8 },
          }}
        >
          <Typography sx={{ my: 2 }} textAlign="left">
            Company info
          </Typography>
          <StyledGridItem item sm={10} xs={12} sx={{ mb: 2, width: '100%' }}>
            <Controller
              control={control}
              name="companyName"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  error={!!error?.message}
                  helperText={error?.message}
                  required
                  value={value}
                  onChange={onChange}
                  fullWidth
                  id="companyName"
                  label="Company name"
                  autoComplete="Company name"
                />
              )}
            />
          </StyledGridItem>
          <StyledGridItem item sm={10} xs={12} sx={{ mb: 2, width: '100%' }}>
            <Controller
              control={control}
              name="companyRegistrationNumber"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  error={!!error?.message}
                  helperText={error?.message}
                  required
                  value={value}
                  onChange={onChange}
                  fullWidth
                  id="companyRegistrationNumber"
                  label="Company Registration number"
                  autoComplete="Company Registration number"
                />
              )}
            />
          </StyledGridItem>
          <StyledGridItem item sm={10} xs={12} sx={{ mb: 2, width: '100%' }}>
            <Controller
              control={control}
              name="companyAddress"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  error={!!error?.message}
                  helperText={error?.message}
                  required
                  value={value}
                  onChange={onChange}
                  fullWidth
                  id="companyAddress"
                  label="Company Address"
                  autoComplete="Company Address"
                />
              )}
            />
          </StyledGridItem>
          <StyledGridItem item sm={10} xs={12} sx={{ mb: 2, width: '100%' }}>
            <Controller
              control={control}
              name="jurisdiction"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  error={!!error?.message}
                  helperText={error?.message}
                  required
                  value={value}
                  onChange={onChange}
                  fullWidth
                  id="jurisdiction"
                  label="Jurisdiction"
                  autoComplete="Jurisdiction"
                />
              )}
            />
          </StyledGridItem>
        </Grid>
        <Grid item xs={12} sm={4}>
          <LoadingButton
            endIcon={<SendIcon />}
            loading={isLoading}
            loadingPosition="end"
            variant="contained"
            type="submit"
            fullWidth
            sx={{
              mt: 2,
            }}
          >
            <span>Update</span>
          </LoadingButton>
        </Grid>
      </Grid>
    </Container>
  );
};
