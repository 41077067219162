import React, { FC } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { Grid } from '@mui/material';
import { useGetAllFuzeDepositAddressesQuery } from '@/services/fuze.service';
import { Loader } from '@/shared';
import { useServerError } from '../../../hooks';
import { DepositAddressesTable } from './deposit-addresses-table/deposit.addresses.table';

export const DepositAddressesPage: FC = () => {
  const { data, isFetching, error, isError } =
    useGetAllFuzeDepositAddressesQuery(undefined, {
      refetchOnMountOrArgChange: true,
    });

  const depositAddresses = data ? Object.values(data) : undefined;

  useServerError({ isError: isError, error: error });

  return (
    <Container maxWidth="xl">
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 4 }}>
        <Typography variant="h3" color="inherit" textAlign="left">
          Fuze Deposit Addresses
        </Typography>
      </Box>

      <Grid container>
        <Grid item xs={12}>
          {depositAddresses?.length ? (
            <DepositAddressesTable depositAddresses={depositAddresses} />
          ) : (
            <Box display="flex" alignItems="center" justifyContent="center">
              {isFetching ? (
                <Loader />
              ) : (
                <Typography>Something went wrong!</Typography>
              )}
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
