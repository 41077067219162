import React, { FC, useEffect } from 'react';
import {
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  Box,
  Typography,
  TablePagination,
  TextField,
  Stack,
} from '@mui/material';
import { spacing } from '@mui/system';
import { styled } from '@mui/system';
import { useDispatch } from 'react-redux';
import { EnhancedTableHeadComponent } from '@/components';
import { HeadCell } from '@/components/table_head';
import {
  useDebounce,
  useNotification,
  useServerError,
  usePagination,
} from '@/hooks';
import {
  useGetAllCryptoCurrenciesQuery,
  useUpdateB2C2ListMutation,
  useUpdateFireblocksListMutation,
  useUpdateFuzeListMutation,
} from '@/services';
import { NoItemsWrapper } from '@/shared';
import { setCurrencyState, setModalState } from '@/store/ui';
import { CurrenciesRow } from './currencies.row';
import { LoadingButton } from '@mui/lab';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import { ICurrencySlice } from '@/types/currencies.interface';

const Paper = styled(MuiPaper)(spacing);

const headCells: Array<HeadCell> = [
  {
    id: 'icon',
    numeric: false,
    disablePadding: true,
    label: 'Icon',
  },
  {
    id: 'customName',
    numeric: true,
    disablePadding: false,
    label: 'Custom Name',
  },
  {
    id: 'b2c2CurrencyName',
    numeric: true,
    disablePadding: false,
    label: 'B2C2 Currency Name',
  },
  {
    id: 'fuzeCurrencyName',
    numeric: true,
    disablePadding: false,
    label: 'Fuze Currency Name',
  },
  {
    id: 'networkQrCode',
    numeric: true,
    disablePadding: false,
    label: 'Network QR Code',
  },
  {
    id: 'protocolQrCode',
    numeric: true,
    disablePadding: false,
    label: 'Protocol QR Code',
  },
  {
    id: 'ordering',
    numeric: true,
    disablePadding: false,
    label: 'Ordering',
  },
  {
    id: 'isDefault',
    numeric: true,
    disablePadding: false,
    label: 'Is Default',
  },
  { id: 'status', numeric: true, disablePadding: false, label: 'Status' },
  {
    id: 'isVisible',
    numeric: true,
    disablePadding: false,
    label: 'Is Visible',
  },
  { id: 'button', numeric: true, disablePadding: false, label: 'Edit' },
];

enum SuccessNotificationMessageEnum {
  Fireblocks = 'Fireblocks list has been updated successfully.',
  B2C2 = 'B2C2 list has been updated successfully.',
  Fuze = 'Fuze list has been updated successfully.',
}

export const CurrenciesTable: FC = () => {
  const { onChangeRowsPerPage, onChangePage, pagination, onSearch } =
    usePagination();

  const dispatch = useDispatch();

  const { showNotification } = useNotification();

  const searchDebounce = useDebounce(pagination.search, 500);

  const {
    data: currenciesData,
    isFetching: isFetchingCurrenciesData,
    error: errorCurrenciesData,
    isError: isErrorCurrenciesData,
  } = useGetAllCryptoCurrenciesQuery({
    skip: pagination.page * pagination.rowsPerPage,
    take: pagination.rowsPerPage,
    search: searchDebounce,
  });

  const [
    updateFireblocksList,
    {
      isLoading: isLoadingUpdateFireblocksList,
      isSuccess: isSuccessUpdateFireblocksList,
      isError: isErrorUpdateFireblocksList,
      error: errorUpdateFireblocksList,
    },
  ] = useUpdateFireblocksListMutation();

  const [
    updateB2C2List,
    {
      isLoading: isLoadingUpdateB2C2List,
      isSuccess: isSuccessUpdateB2C2List,
      isError: isErrorUpdateB2C2List,
      error: errorUpdateB2C2List,
    },
  ] = useUpdateB2C2ListMutation();

  const [
    updateFuzeList,
    {
      isLoading: isLoadingUpdateFuzeList,
      isSuccess: isSuccessUpdateFuzeList,
      isError: isErrorUpdateFuzeList,
      error: errorUpdateFuzeList,
    },
  ] = useUpdateFuzeListMutation();

  const handleUpdateFireblocksList = () => {
    updateFireblocksList();
  };

  const handleUpdateB2C2List = () => {
    updateB2C2List();
  };

  const handleUpdateFuzeList = () => {
    updateFuzeList();
  };

  const handleOpenCreateCurrencyModal = () => {
    dispatch(
      setModalState({
        visible: true,
        name: 'currencyModal',
      }),
    );
  };

  const handleOpenCurrencyModal = ({
    id,
    customName,
    icon,
    status,
    protocolQrCode,
    networkQrCode,
    isDefault,
    b2c2CurrencyName,
    fuzeCurrencyName,
    ordering,
    externalId,
    reason,
    isVisible,
  }: ICurrencySlice) => {
    dispatch(
      setModalState({
        visible: true,
        name: 'currencyModal',
      }),
    );
    dispatch(
      setCurrencyState({
        id,
        customName,
        icon,
        status,
        protocolQrCode,
        networkQrCode,
        isDefault,
        b2c2CurrencyName,
        fuzeCurrencyName,
        ordering,
        externalId,
        reason,
        isVisible,
      }),
    );
  };

  useEffect(() => {
    if (isSuccessUpdateFireblocksList) {
      showNotification(SuccessNotificationMessageEnum.Fireblocks, 'success');
    }

    if (isSuccessUpdateB2C2List) {
      showNotification(SuccessNotificationMessageEnum.B2C2, 'success');
    }

    if (isSuccessUpdateFuzeList) {
      showNotification(SuccessNotificationMessageEnum.Fuze, 'success');
    }
  }, [
    isSuccessUpdateFireblocksList,
    isSuccessUpdateB2C2List,
    isSuccessUpdateFuzeList,
  ]);

  useServerError({
    isError: isErrorCurrenciesData,
    error: errorCurrenciesData,
  });

  useServerError({
    isError: isErrorUpdateFireblocksList,
    error: errorUpdateFireblocksList,
  });

  useServerError({
    isError: isErrorUpdateB2C2List,
    error: errorUpdateB2C2List,
  });

  useServerError({
    isError: isErrorUpdateFuzeList,
    error: errorUpdateFuzeList,
  });

  return (
    <>
      <Box
        mb={2}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 1,
        }}
      >
        <Stack direction="column" sx={{ width: { xs: '100%', md: 'auto' } }}>
          <Typography variant="h3" color="inherit" noWrap textAlign="left">
            Crypto currencies
          </Typography>
          <Typography sx={{ mt: 2 }} textAlign="left">
            Create or update existing crypto currencies
          </Typography>
        </Stack>
      </Box>
      <Box sx={{ display: 'flex', mb: 3 }}>
        <LoadingButton
          variant="contained"
          startIcon={<CurrencyBitcoinIcon />}
          onClick={handleOpenCreateCurrencyModal}
          sx={{ width: { xs: '100%', sm: 'auto' } }}
        >
          Create new crypto currency
        </LoadingButton>
      </Box>
      <Box
        mb={2}
        sx={{
          display: 'flex',
          gap: 2,
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            flexDirection: { xs: 'column', sm: 'row' },
            width: { xs: '100%', sm: 'auto' },
          }}
        >
          <LoadingButton
            variant="contained"
            loading={isLoadingUpdateFireblocksList}
            onClick={handleUpdateFireblocksList}
          >
            Update Fireblocks
          </LoadingButton>
          <LoadingButton
            variant="contained"
            loading={isLoadingUpdateB2C2List}
            onClick={handleUpdateB2C2List}
          >
            Update B2C2
          </LoadingButton>
          <LoadingButton
            variant="contained"
            loading={isLoadingUpdateFuzeList}
            onClick={handleUpdateFuzeList}
          >
            Update Fuze
          </LoadingButton>
        </Box>
        <TextField
          placeholder="Search"
          size="small"
          onChange={onSearch}
          sx={{ width: { xs: '100%', sm: 'auto' } }}
        />
      </Box>
      <Paper sx={{ marginBottom: 5 }}>
        <TableContainer sx={{ maxHeight: 640 }}>
          <Table
            sx={{ minWidth: 800 }}
            aria-labelledby="tableTitle"
            size={'small'}
            aria-label="enhanced table"
            stickyHeader
          >
            <EnhancedTableHeadComponent headCells={headCells} />
            <TableBody>
              <NoItemsWrapper
                length={currenciesData?.items?.length as number}
                text={'There are no tier'}
                colSpan={4}
                isTableComponent
                isLoading={
                  !currenciesData?.items?.length
                    ? isFetchingCurrenciesData
                    : false
                }
              >
                {currenciesData?.items?.map((row: any, index: number) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <CurrenciesRow
                      key={row?.id}
                      row={row}
                      labelId={labelId}
                      isFetchingCurrenciesData={isFetchingCurrenciesData}
                      handleOpenModal={handleOpenCurrencyModal}
                    />
                  );
                })}
              </NoItemsWrapper>
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          // labelRowsPerPage={matches ? 'Rows per page:' : ''}
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={currenciesData?.count as number}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};
