import React, { useState } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Typography } from '@mui/material';

const SORT_VARIANT = ['asc', 'desc'];

export function useSortBy(
  label: string | undefined = 'Sort by date',
  value: boolean | undefined = false,
) {
  const [sort, setSort] = useState<boolean>(value);

  const sortHandler = () => {
    setSort((prevState) => !prevState);
  };

  const SortComponent = (
    <Typography
      title={sort ? 'asc' : 'desc'}
      onClick={sortHandler}
      textAlign="left"
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        cursor: 'pointer',
      }}
    >
      {label}{' '}
      <ArrowUpwardIcon
        sx={{
          transition: '0.2s',
          transform: `rotate(${sort ? '0deg' : '180deg'})`,
        }}
      />
    </Typography>
  );

  return [SORT_VARIANT[sort ? 0 : 1] as 'asc' | 'desc', SortComponent] as const;
}
