import React, { FC, useEffect } from 'react';
import {
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  Box,
  Typography,
  Button,
} from '@mui/material';
import { spacing } from '@mui/system';
import { styled } from '@mui/system';
import { useDispatch } from 'react-redux';
import { EnhancedTableHeadComponent } from '../../../../components';
import { HeadCell } from '../../../../components/table_head';
import { useNotification, useServerError } from '../../../../hooks';
import { useDialog } from '../../../../hooks/useDialog';
import {
  useDeleteTierMutation,
  useGetAllTierQuery,
} from '../../../../services';
import { NoItemsWrapper } from '../../../../shared';
import { setModalState, setTierState } from '../../../../store/ui';
import { TierRow } from './tier.row';

const Paper = styled(MuiPaper)(spacing);

const headCells: Array<HeadCell> = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'default',
    numeric: true,
    disablePadding: false,
    label: 'Is Default',
  },
  { id: 'button', numeric: true, disablePadding: false, label: 'Edit' },
  { id: 'buttonDelete', numeric: true, disablePadding: false, label: 'Delete' },
];

export const TierTable: FC = () => {
  const [handleOpenDialog, DialogComponent] = useDialog();

  const dispatch = useDispatch();
  const { showNotification } = useNotification();

  const {
    data: tierData,
    isFetching: isFetchingTierData,
    error: errorTierData,
    isError: isErrorTierData,
  } = useGetAllTierQuery(null);

  const [
    deleteTier,
    {
      isLoading: isLoadingDeleteTier,
      isError: isErrorDeleteTier,
      error: errorDeleteTier,
      isSuccess: isSuccessDeleteTier,
    },
  ] = useDeleteTierMutation();

  const handleOpenTierModal = ({
    id,
    name,
    isDefault,
  }: {
    id: number | null;
    name: string;
    isDefault: boolean;
  }) => {
    dispatch(
      setModalState({
        visible: true,
        name: 'tierModal',
      }),
    );
    dispatch(
      setTierState({
        id: id,
        name: name,
        isDefault: isDefault,
      }),
    );
  };

  const handleDeleteTier = ({ id, name }: { id: number; name: string }) => {
    handleOpenDialog({
      description: (
        <>
          Are you sure you want to delete tier <b>{name}</b>?
        </>
      ),
      buttonApplyHandler: () => deleteTier({ id }),
      loadingDelete: isLoadingDeleteTier,
    });
  };

  useServerError({ isError: isErrorDeleteTier, error: errorDeleteTier });
  useServerError({ isError: isErrorTierData, error: errorTierData });

  useEffect(() => {
    if (isSuccessDeleteTier) {
      showNotification('Activity deleted!', 'success');
    }
  }, [isSuccessDeleteTier]);

  return (
    <>
      <Box
        mb={2}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h3" color="inherit" noWrap textAlign="left">
          Tier
        </Typography>
        <Button
          variant="contained"
          onClick={() =>
            handleOpenTierModal({
              id: null,
              name: '',
              isDefault: false,
            })
          }
        >
          + Add tier
        </Button>
      </Box>
      <Paper sx={{ marginBottom: 5 }}>
        <TableContainer sx={{ maxHeight: 640 }}>
          <Table
            sx={{ minWidth: 800 }}
            aria-labelledby="tableTitle"
            size={'small'}
            aria-label="enhanced table"
            stickyHeader
          >
            <EnhancedTableHeadComponent headCells={headCells} />
            <TableBody>
              <NoItemsWrapper
                length={tierData?.length as number}
                text={'There are no tier'}
                colSpan={4}
                isTableComponent
                isLoading={!tierData?.length ? isFetchingTierData : false}
              >
                {tierData?.map((row: any, index: number) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TierRow
                      key={row?.id}
                      row={row}
                      labelId={labelId}
                      isLoadingTear={isFetchingTierData}
                      handleOpenModal={handleOpenTierModal}
                      handleDeleteTier={handleDeleteTier}
                    />
                  );
                })}
              </NoItemsWrapper>
            </TableBody>
          </Table>
        </TableContainer>
        {DialogComponent}
      </Paper>
    </>
  );
};
