import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UIState, ModalNames } from './types';

const initialState: UIState = {
  modalsVisibility: {
    changeRole: false,
    gasStationEdit: false,
    autoFuelEdit: false,
    tierModal: false,
    feeModal: false,
    fiatLimitModal: false,
    changeTierForUser: false,
    currencyModal: false,
    companyModal: false,
    inviteUser: false,
    bankAccountModal: false,
    bannersModal: false,
    emailTemplateModal: false,
  },
  userId: null,
  userRole: null,
  userName: null,
  tierId: null,
};

export const uiReducer = createSlice({
  name: 'uiSlice',
  initialState,
  reducers: {
    setModalState(
      state: UIState,
      action: PayloadAction<{
        name: ModalNames;
        visible: boolean;
      }>,
    ) {
      const { name, visible } = action.payload;
      state.modalsVisibility[name] = visible;
    },
    setRolesState(
      state: UIState,
      action: PayloadAction<{
        userId?: number | string | null;
        userRole?: string | null;
        userName?: string | null;
        vaultAutoFuel?: boolean | null;
        tierId?: number | null;
      }>,
    ) {
      const { userId, userRole, userName, vaultAutoFuel, tierId } =
        action.payload;
      state.userId = userId;
      state.userRole = userRole;
      state.userName = userName;
      state.vaultAutoFuel = vaultAutoFuel;
      state.tierId = tierId;
    },
  },
});

export const { setModalState, setRolesState } = uiReducer.actions;

export default uiReducer.reducer;
