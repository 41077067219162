import React, { FC } from 'react';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { IconButton, TableCell, TableRow, Box } from '@mui/material';
import { CurrencyIconComponent } from '@/components/currency_image';
import { ICurrency } from '@/types/currencies.interface';
import { getIconVerified } from '@/helpers/getIconVerifiedHelper';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';

type CurrenciesRowProps = {
  row: ICurrency;
  labelId: any;
  handleOpenModal: any;
  isFetchingCurrenciesData: boolean;
  disableEdit?: boolean;
};

const EMPTY_VALUE = '-';

export const CurrenciesRow: FC<CurrenciesRowProps> = ({
  row,
  labelId,
  handleOpenModal,
  isFetchingCurrenciesData,
}) => {
  return (
    <TableRow tabIndex={-1} key={row.id}>
      <TableCell id={labelId} align="center">
        {row?.icon ? (
          <CurrencyIconComponent iconString={row?.icon} />
        ) : (
          <ImageNotSupportedIcon color="disabled" />
        )}
      </TableCell>
      <TableCell align="center">{row?.customName}</TableCell>
      <TableCell align="center">
        {row?.b2c2CurrencyName || EMPTY_VALUE}
      </TableCell>
      <TableCell align="center">
        {row?.fuzeCurrencyName || EMPTY_VALUE}
      </TableCell>
      <TableCell align="center">{row?.networkQrCode || EMPTY_VALUE}</TableCell>
      <TableCell align="center">{row?.protocolQrCode || EMPTY_VALUE}</TableCell>
      <TableCell align="center">{row?.ordering}</TableCell>
      <TableCell align="center">{getIconVerified(row?.isDefault)}</TableCell>
      <TableCell align="center">{getIconVerified(row?.status)}</TableCell>
      <TableCell align="center">{getIconVerified(row?.isVisible)}</TableCell>
      <TableCell align="right">
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <IconButton
            aria-label="Settings"
            size="large"
            title="Edit"
            onClick={() =>
              handleOpenModal({
                id: row.id,
                customName: row.customName,
                icon: row.icon,
                status: row.status,
                protocolQrCode: row.protocolQrCode,
                networkQrCode: row.networkQrCode,
                isDefault: row.isDefault,
                b2c2CurrencyName: row.b2c2CurrencyName,
                fuzeCurrencyName: row.fuzeCurrencyName,
                ordering: row.ordering,
                externalId: row.externalId,
                reason: row.reason,
                isVisible: row.isVisible,
              })
            }
            disabled={isFetchingCurrenciesData}
          >
            <ModeEditIcon />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
};
