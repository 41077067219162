import React, { FC } from 'react';
import {
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  Box,
  Typography,
  TablePagination,
  TextField,
} from '@mui/material';
import { spacing } from '@mui/system';
import { styled } from '@mui/system';
import { EnhancedTableHeadComponent } from '@/components';
import { HeadCell } from '@/components/table_head';
import { useDebounce, useServerError, useSortBy } from '@/hooks';
import { usePagination } from '@/hooks';
import { useGetUserActivityQuery } from '@/services';
import { NoItemsWrapper } from '@/shared';
import { IUserActivityItem } from '@/types/user.interface';
import { ActivityRow } from './activity.row';

const Paper = styled(MuiPaper)(spacing);

const headCells: Array<HeadCell> = [
  {
    id: 'userId',
    numeric: true,
    disablePadding: true,
    label: 'User ID',
  },
  {
    id: 'userEmail',
    numeric: true,
    disablePadding: false,
    label: 'User email',
  },
  {
    id: 'action',
    numeric: true,
    disablePadding: false,
    label: 'Action',
  },
  {
    id: 'createdAt',
    numeric: true,
    disablePadding: false,
    label: 'Created at',
  },
  {
    id: 'metadata',
    numeric: true,
    disablePadding: false,
    label: 'Metadata',
  },
];

export const ActivityTable: FC = () => {
  const [sort, SortComponent] = useSortBy();

  const { onChangeRowsPerPage, onChangePage, pagination, onSearch } =
    usePagination();
  const { pagination: paginationUserId, onSearch: searchUserId } =
    usePagination();

  const searchEmailDebounce = useDebounce(pagination.search, 500);
  const searchUserIdDebounce = useDebounce(paginationUserId.search, 500);

  const {
    data: dataUserActivity,
    isFetching: isFetchingUserActivity,
    error: errorUserActivity,
    isError: isErrorUserActivity,
  } = useGetUserActivityQuery(
    {
      skip: pagination.page * pagination.rowsPerPage,
      take: pagination.rowsPerPage,
      userEmail: searchEmailDebounce,
      userId: searchUserIdDebounce,
      sortOrder: sort,
    },
    { refetchOnMountOrArgChange: true },
  );

  useServerError({
    isError: isErrorUserActivity,
    error: errorUserActivity,
  });

  return (
    <>
      <Box
        mb={2}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          flexWrap: 'wrap',
        }}
      >
        <Typography variant="h3" color="inherit" noWrap textAlign="left">
          Users activity
        </Typography>
        <Box sx={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
          <Box>
            <Typography sx={{ my: 1 }} textAlign="left">
              Search by email
            </Typography>
            <TextField placeholder="Email" size="small" onChange={onSearch} />
          </Box>
          <Box>
            <Typography sx={{ my: 1 }} textAlign="left">
              Search by user ID
            </Typography>
            <TextField
              placeholder="User ID"
              size="small"
              onChange={searchUserId}
            />
          </Box>
          <Box sx={{ display: 'flex', my: 1, alignItems: 'flex-end' }}>
            {SortComponent}
          </Box>
        </Box>
      </Box>
      <Paper sx={{ marginBottom: 5 }}>
        <TableContainer sx={{ maxHeight: 640 }}>
          <Table
            sx={{ minWidth: 800 }}
            aria-labelledby="tableTitle"
            size={'small'}
            aria-label="enhanced table"
            stickyHeader
          >
            <EnhancedTableHeadComponent headCells={headCells} />
            <TableBody>
              <NoItemsWrapper
                length={dataUserActivity?.items?.length as number}
                text={'There are no activity'}
                colSpan={5}
                isTableComponent
                isLoading={
                  !dataUserActivity?.items?.length
                    ? isFetchingUserActivity
                    : false
                }
              >
                {dataUserActivity?.items?.map(
                  (row: IUserActivityItem, index: number) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <ActivityRow key={row?.id} row={row} labelId={labelId} />
                    );
                  },
                )}
              </NoItemsWrapper>
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          // labelRowsPerPage={matches ? 'Rows per page:' : ''}
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={dataUserActivity?.count as number}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};
