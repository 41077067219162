import { IFuzeDepositAddressesList } from '@/types/fuze.deposit.addresses.interface';
import { PATH_ADMIN_API } from '../constants/api-routes';
import { baseApi } from './base.api';

export const fuzeService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllFuzeDepositAddresses: build.query<
      IFuzeDepositAddressesList,
      undefined
    >({
      query: (params) => ({
        url: `${PATH_ADMIN_API.FUZE_DEPOSIT_ADDRESSES}`,
        params,
      }),
      providesTags: ['FuzeDepositAddresses'],
    }),
  }),
});

export const { useGetAllFuzeDepositAddressesQuery } = fuzeService;
