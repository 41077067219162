import React, { ChangeEvent, FC, MouseEvent } from 'react';
import {
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { spacing } from '@mui/system';
import { styled } from '@mui/system';
import { useDispatch } from 'react-redux';
import { NoItemsWrapper } from '../../../../shared';
import { setModalState, setRolesState } from '../../../../store/ui';
import { UsersInterface } from '../../../../types/admin.interface';
import { UserRow } from './users.row';

type HeadCell = {
  id: string;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
};

type UsersTableProps = {
  users: UsersInterface[];
  count: number;
  rowsPerPage: number;
  isLoadingUsers: boolean;
  page: number;
  onPageChange: (e: MouseEvent<HTMLButtonElement> | null, page: number) => void;
  onRowsPerPageChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
};

const Paper = styled(MuiPaper)(spacing);

const StyledTableHead = styled(TableHead)(({ theme: styledTheme }) => ({
  height: 55,
  '& .MuiTableCell-root': {
    backgroundColor: styledTheme.palette.grey[200],
  },
}));

const headCells: Array<HeadCell> = [
  {
    id: '#',
    numeric: true,
    disablePadding: true,
    label: '#ID',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'last_name',
    numeric: false,
    disablePadding: false,
    label: 'Last Name',
  },
  { id: 'email', numeric: true, disablePadding: false, label: 'Email' },
  {
    id: 'kyc',
    numeric: true,
    disablePadding: false,
    label: 'KYC',
  },
  {
    id: 'vault-auto-fuel',
    numeric: true,
    disablePadding: false,
    label: 'Vault auto fuel',
  },
  {
    id: 'role',
    numeric: true,
    disablePadding: false,
    label: 'Role',
  },
  { id: 'button', numeric: true, disablePadding: false, label: 'Auto fuel' },
];

const EnhancedTableHead: FC = () => {
  return (
    <StyledTableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ padding: '6px 16px' }}
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
};

export const UsersGasStationTable: FC<UsersTableProps> = ({
  users,
  page,
  rowsPerPage,
  count,
  onPageChange,
  onRowsPerPageChange,
  isLoadingUsers,
}) => {
  const dispatch = useDispatch();

  const mediaTheme = useTheme();
  const matches = useMediaQuery(mediaTheme.breakpoints.up('sm'));

  const handleOpenModal = ({
    id,
    userRole,
    userName,
    vaultAutoFuel,
  }: {
    id: number;
    userRole: string;
    userName: string;
    vaultAutoFuel: boolean;
  }) => {
    dispatch(
      setModalState({
        visible: true,
        name: 'autoFuelEdit',
      }),
    );
    dispatch(
      setRolesState({
        userId: id as number,
        userRole,
        userName: userName,
        vaultAutoFuel,
      }),
    );
  };

  return (
    <Paper sx={{ marginBottom: 10 }}>
      <TableContainer sx={{ maxHeight: 640 }}>
        <Table
          sx={{ minWidth: 800 }}
          aria-labelledby="tableTitle"
          size={'small'}
          aria-label="enhanced table"
          stickyHeader
        >
          <EnhancedTableHead />
          <TableBody>
            {!!users?.length &&
              users?.map((row: any, index: number) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <UserRow
                    key={row?.id}
                    row={row}
                    labelId={labelId}
                    isLoadingUsers={isLoadingUsers}
                    handleOpenModal={handleOpenModal}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <NoItemsWrapper
        length={users?.length as number}
        text={'There are no users'}
      >
        <TablePagination
          labelRowsPerPage={matches ? 'Rows per page:' : ''}
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </NoItemsWrapper>
    </Paper>
  );
};
