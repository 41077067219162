// created by Artem
import React, { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Link, TextField, Typography } from '@mui/material';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';
import {
  Link as NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { object } from 'yup';
import { useServerError } from '@/hooks';
import { useVerifyMutation } from '@/services';
import { AuthVerifyFormValues } from '@/types/auth.interface';
import { emailScheme, stringScheme } from '@/utils';
import { SendCodeButton } from '../../components/auth/send.code.button';
import { LoadingButton } from '../../components/buttons_collection/loading.button';
import { StepsRegisterEnum } from '../../constants/spa-routes';
import { removeTimerSendCodeStorage } from '../../helpers/removeTimerSendCodeStorage';
import { TwoFactorTypesEnum } from '../../types/user.interface';

type AuthVerifyEmailPageProps = {};
export const AuthVerifyEmailPage: FC<AuthVerifyEmailPageProps> = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const verifyType: 'login' | 'register' =
    location.state?.verifyType || 'login';

  const email: string =
    searchParams.get('email') || location.state?.email || '';

  const phone: string = location.state?.phone;

  const codeLabel =
    location.state?.twoFactorType === TwoFactorTypesEnum.SMS
      ? 'QBS Code'
      : 'Code';

  const twoFactorType: string =
    location.state?.twoFactorType ||
    (location.search.includes(StepsRegisterEnum.VERIFY_EMAIL) &&
      TwoFactorTypesEnum.EMAIL) ||
    (location.search.includes(StepsRegisterEnum.VERIFY_PHONE) &&
      TwoFactorTypesEnum.SMS);

  const code: string = searchParams.get('code') || location.state?.code || '';

  const [verify, { isError, error, isLoading }] = useVerifyMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AuthVerifyFormValues>({
    defaultValues: { code, email, type: verifyType },
    resolver: yupResolver(
      object({
        code: stringScheme({ required: true, min: 6 }),
        email: emailScheme({ required: true }),
      }),
    ) as Resolver<any>,
  });

  const onSubmit: SubmitHandler<AuthVerifyFormValues> = (data) => {
    verify(data)
      .unwrap()
      .then(() => navigate('/transactions-users'))
      .catch(() => null);
  };

  useServerError({ isError, error });
  useServerError({
    isError: !!errors.email?.message,
    error: {
      status: '400',
      error: errors.email?.message,
    },
  });

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      sx={{ mt: 1 }}
    >
      <Typography component="h1" variant="h5">
        {`A verification code has been sent to your ${
          twoFactorType === TwoFactorTypesEnum.SMS
            ? `phone ${phone}`
            : `email ${email}`
        }`}
      </Typography>
      <TextField
        {...register('code')}
        error={!!errors?.code?.message}
        helperText={errors?.code?.message}
        margin="normal"
        required
        fullWidth
        id="code"
        label={codeLabel}
        name="code"
        autoComplete="off"
        autoFocus
      />
      {!email && (
        <TextField
          {...register('email')}
          error={!!errors?.email?.message}
          helperText={errors?.email?.message}
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="off"
        />
      )}
      <SendCodeButton
        email={email}
        phone={phone}
        sendCodeType={TwoFactorTypesEnum.EMAIL}
      />
      <LoadingButton type="submit" isLoading={isLoading} sx={{ mt: 3, mb: 2 }}>
        Submit
      </LoadingButton>
      <Link
        onClick={removeTimerSendCodeStorage}
        variant="body2"
        component={NavLink}
        to="/auth"
      >
        Cancel
      </Link>
    </Box>
  );
};
