import { PATH_ADMIN_API } from '@/constants/api-routes';
import {
  CurrencyPayload,
  ICurrencies,
  IFireblocksCurrency,
} from '../types/currencies.interface';
import { baseApi } from './base.api';

export const currenciesService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllCryptoCurrencies: build.query<
      ICurrencies,
      { skip: number; take: number; search?: string }
    >({
      query: (params) => ({
        url: PATH_ADMIN_API.CRYPTO_CURRENCY,
        params,
      }),
      providesTags: ['CryptoCurrencies'],
    }),
    getFireblocksNameList: build.query<IFireblocksCurrency[], void>({
      query: () => ({
        url: PATH_ADMIN_API.FIREBLOCKS_NAME_LIST,
      }),
      providesTags: ['FireblocksNameList'],
    }),
    getB2C2NameList: build.query<string[], {}>({
      query: () => ({
        url: PATH_ADMIN_API.B2C2_NAME_LIST,
      }),
      providesTags: ['B2C2NameList'],
    }),
    getFuzeNameList: build.query<string[], {}>({
      query: () => ({
        url: PATH_ADMIN_API.FUZE_NAME_LIST,
      }),
      providesTags: ['FuzeNameList'],
    }),
    createCryptoCurrency: build.mutation<any, CurrencyPayload>({
      query: ({ id, ...params }) => ({
        url: `${PATH_ADMIN_API.CRYPTO_CURRENCY}/${id}`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['CryptoCurrencies'],
    }),
    updateCryptoCurrency: build.mutation<any, CurrencyPayload>({
      query: ({ id, ...params }) => ({
        url: `${PATH_ADMIN_API.CRYPTO_CURRENCY}/${id}`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['CryptoCurrencies'],
    }),
    updateFireblocksList: build.mutation<void, void>({
      query: () => ({
        url: PATH_ADMIN_API.UPDATE_FIREBLOCKS_LIST,
        method: 'POST',
      }),
      invalidatesTags: ['CryptoCurrencies', 'FireblocksNameList'],
    }),
    updateB2C2List: build.mutation<void, void>({
      query: () => ({
        url: PATH_ADMIN_API.UPDATE_B2C2_LIST,
        method: 'POST',
      }),
      invalidatesTags: ['CryptoCurrencies', 'B2C2NameList'],
    }),
    updateFuzeList: build.mutation<void, void>({
      query: () => ({
        url: PATH_ADMIN_API.UPDATE_FUZE_LIST,
        method: 'POST',
      }),
      invalidatesTags: ['CryptoCurrencies', 'FuzeNameList'],
    }),
  }),
});

export const {
  useGetAllCryptoCurrenciesQuery,
  useCreateCryptoCurrencyMutation,
  useUpdateCryptoCurrencyMutation,
  useGetFireblocksNameListQuery,
  useGetB2C2NameListQuery,
  useGetFuzeNameListQuery,
  useUpdateFireblocksListMutation,
  useUpdateB2C2ListMutation,
  useUpdateFuzeListMutation,
} = currenciesService;
