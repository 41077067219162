import { PATH_ADMIN_API } from '../constants/api-routes';
import { BANNER_TYPE, IBanner } from '../types/banners.interface';
import { baseApi } from './base.api';

export const bannerService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllBanner: build.query<IBanner[], {}>({
      query: () => ({
        url: PATH_ADMIN_API.ALL_BANNER,
      }),
      providesTags: ['Banner'],
    }),
    getBannerTypes: build.query<BANNER_TYPE, {}>({
      query: () => ({
        url: PATH_ADMIN_API.BANNER_TYPES,
      }),
    }),
    createBanner: build.mutation<
      any,
      {
        text: string;
        text2: string;
        type: string;
        userAccountType: string;
        active: boolean;
        isFuze: boolean;
      }
    >({
      query: (body) => ({
        url: PATH_ADMIN_API.BANNER,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Banner'],
    }),
    updateBanner: build.mutation<
      any,
      {
        id: number;
        text: string;
        text2: string;
        type: string;
        userAccountType: string;
        active: boolean;
        isFuze: boolean;
      }
    >({
      query: (body) => ({
        url: `${PATH_ADMIN_API.BANNER}/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Banner'],
    }),
    deleteBanner: build.mutation<
      any,
      {
        id: number;
      }
    >({
      query: ({ id }) => ({
        url: `${PATH_ADMIN_API.BANNER}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Banner'],
    }),
  }),
});

export const {
  useGetAllBannerQuery,
  useGetBannerTypesQuery,
  useCreateBannerMutation,
  useDeleteBannerMutation,
  useUpdateBannerMutation,
} = bannerService;
