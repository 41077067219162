import React, { FC, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography,
  Collapse,
  SelectChangeEvent,
  MenuItem,
  Select,
  TablePagination,
  FormHelperText,
} from '@mui/material';
import { styled } from '@mui/system';
import {
  TransactionHistoryItem,
  TransactionStatusEnum,
  TransactionTypeEnum,
} from '@/types/operation-history.interface';
import { theme } from '../../../../assets/theme';
import { transformDate } from '../../../../helpers/dateTransformHelper';
import { usePagination } from '../../../../hooks/usePagination';
import { useGetAdminUserTransactionsQuery } from '../../../../services';
import { NoItemsWrapper } from '../../../../shared';
import { UsersInterface } from '../../../../types/admin.interface';
import { DateFormatEnum } from '../../../../types/dateTimeFormatOption';
import { formatPrice } from '../../../../utils';

const StyledTableHead = styled(TableHead)(({ theme: styledTheme }) => ({
  height: 40,
  '& .MuiTableCell-root': {
    backgroundColor: styledTheme.palette.grey[200],
  },
}));

type TransactionsRowProps = {
  row: UsersInterface;
  open: boolean;
};

export const TransactionsRow: FC<TransactionsRowProps> = ({ row, open }) => {
  const [status, setStatus] = useState<string>(TransactionStatusEnum.SUBMITTED);
  const [type, setType] = useState<string>(TransactionTypeEnum.EXCHANGE);

  const { onChangeRowsPerPage, onChangePage, pagination } = usePagination();

  const handleChangeStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };
  const handleChangeType = (event: SelectChangeEvent) => {
    setType(event.target.value);
  };

  const { data, isLoading, isFetching } = useGetAdminUserTransactionsQuery(
    {
      userId: row?.id as number,
      type,
      status,
      skip: pagination.page * pagination.rowsPerPage,
      take: pagination.rowsPerPage,
    },
    { skip: !open },
  );

  return (
    <>
      {open && (
        <TableRow sx={{ backgroundColor: theme.palette.grey[100] }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={16}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Transactions History
                </Typography>
              </Box>
              <Box sx={{ margin: 1, display: 'flex', gap: '10px' }}>
                <Select
                  value={status}
                  onChange={handleChangeStatus}
                  displayEmpty
                  size="small"
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value={TransactionStatusEnum.SUBMITTED}>
                    <em>SUBMITTED</em>
                  </MenuItem>
                  <MenuItem value={TransactionStatusEnum.COMPLETED}>
                    COMPLETED
                  </MenuItem>
                  <MenuItem value={TransactionStatusEnum.FAILED}>
                    FAILED
                  </MenuItem>
                </Select>
                <Select
                  value={type}
                  onChange={handleChangeType}
                  displayEmpty
                  size="small"
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value={TransactionTypeEnum.EXCHANGE}>
                    <em>EXCHANGE</em>
                  </MenuItem>
                  <MenuItem value={TransactionTypeEnum.WITHDRAW}>
                    WITHDRAW
                  </MenuItem>
                  <MenuItem value={TransactionTypeEnum.DEPOSIT}>
                    DEPOSIT
                  </MenuItem>
                </Select>
              </Box>
              {type === TransactionTypeEnum.DEPOSIT && (
                <Box sx={{ margin: 1 }}>
                  <Typography variant="h6" gutterBottom component="div">
                    History deposits
                  </Typography>
                  <Table size="small" aria-label="purchases">
                    <StyledTableHead>
                      <TableRow>
                        <TableCell align="center">Date</TableCell>
                        <TableCell align="center">AssetAmount</TableCell>
                        <TableCell align="center">Fiat</TableCell>
                        <TableCell align="center">FiatAmount</TableCell>
                        <TableCell align="center">From</TableCell>
                        <TableCell align="center">To</TableCell>
                        <TableCell align="center">Asset</TableCell>
                        <TableCell align="center">Status</TableCell>
                      </TableRow>
                    </StyledTableHead>
                    <TableBody>
                      <NoItemsWrapper
                        isLoading={isLoading || isFetching}
                        isTableComponent
                        colSpan={9}
                        length={data?.items?.length as number}
                        text={'There is no deposits'}
                      >
                        {data?.items?.map(
                          (rowDeposit: TransactionHistoryItem) => {
                            return (
                              <TableRow key={rowDeposit?.id}>
                                <TableCell
                                  align="center"
                                  component="th"
                                  scope="row"
                                >
                                  {transformDate(
                                    rowDeposit?.createdAt,
                                    DateFormatEnum.DATE_WITH_TIME_WITH_DOT,
                                  )}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="center"
                                >
                                  {rowDeposit?.assetAmount}
                                </TableCell>
                                <TableCell align="center">
                                  {rowDeposit?.fiat}
                                </TableCell>
                                <TableCell align="center">
                                  {formatPrice(rowDeposit?.fiatAmount)}
                                </TableCell>
                                <TableCell align="center">
                                  <Box>
                                    <Typography>
                                      {rowDeposit?.InternalFrom?.name}{' '}
                                      {rowDeposit?.InternalFrom?.lastName}
                                    </Typography>
                                    <Typography>
                                      {rowDeposit?.InternalFrom?.email}
                                    </Typography>
                                  </Box>
                                </TableCell>
                                <TableCell align="center">
                                  <Box>
                                    <Typography>
                                      {rowDeposit?.InternalTo?.name}{' '}
                                      {rowDeposit?.InternalTo?.lastName}
                                    </Typography>
                                    <Typography>
                                      {rowDeposit?.InternalTo?.email}
                                    </Typography>
                                  </Box>
                                </TableCell>
                                <TableCell align="center">
                                  {rowDeposit?.asset}
                                </TableCell>
                                <TableCell align="center">
                                  {rowDeposit?.status}
                                </TableCell>
                              </TableRow>
                            );
                          },
                        )}
                      </NoItemsWrapper>
                    </TableBody>
                  </Table>
                </Box>
              )}
              {type === TransactionTypeEnum.EXCHANGE && (
                <Box sx={{ margin: 1 }}>
                  <Typography variant="h6" gutterBottom component="div">
                    History exchanges
                  </Typography>
                  <Table size="small" aria-label="purchases">
                    <StyledTableHead>
                      <TableRow>
                        <TableCell align="center">Date</TableCell>
                        <TableCell align="center">Amount</TableCell>
                        <TableCell align="center">Fee</TableCell>
                        <TableCell align="center">Fiat amount</TableCell>
                        <TableCell align="center">Fiat</TableCell>
                        <TableCell align="center">From</TableCell>
                        <TableCell align="center">To</TableCell>
                        <TableCell align="center">Status</TableCell>
                      </TableRow>
                    </StyledTableHead>
                    <TableBody>
                      <NoItemsWrapper
                        isTableComponent
                        isLoading={isLoading || isFetching}
                        colSpan={9}
                        length={data?.items?.length as number}
                        text={'There is no exchanges'}
                      >
                        {data?.items?.map(
                          (rowExchange: TransactionHistoryItem) => {
                            return (
                              <TableRow key={rowExchange?.id}>
                                <TableCell
                                  align="center"
                                  component="th"
                                  scope="row"
                                >
                                  {transformDate(
                                    rowExchange?.createdAt,
                                    DateFormatEnum.DATE_WITH_TIME_WITH_DOT,
                                  )}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="center"
                                >
                                  {rowExchange?.assetAmount}
                                </TableCell>
                                <TableCell align="center">
                                  {formatPrice(rowExchange?.fee)}
                                </TableCell>
                                <TableCell align="center">
                                  {formatPrice(rowExchange?.fiatAmount)}
                                </TableCell>
                                <TableCell align="center">
                                  {rowExchange?.fiat}
                                </TableCell>
                                <TableCell align="center">
                                  <Box>
                                    <Typography>
                                      {rowExchange?.InternalFrom?.name}{' '}
                                      {rowExchange?.InternalFrom?.lastName}
                                    </Typography>
                                    <Typography>
                                      {rowExchange?.InternalFrom?.email}
                                    </Typography>
                                  </Box>
                                </TableCell>
                                <TableCell align="center">
                                  <Box>
                                    <Typography>
                                      {rowExchange?.InternalTo?.name}{' '}
                                      {rowExchange?.InternalTo?.lastName}
                                    </Typography>
                                    <Typography>
                                      {rowExchange?.InternalTo?.email}
                                    </Typography>
                                  </Box>
                                </TableCell>
                                <TableCell align="center">
                                  {rowExchange?.status}
                                </TableCell>
                              </TableRow>
                            );
                          },
                        )}
                      </NoItemsWrapper>
                    </TableBody>
                  </Table>
                </Box>
              )}
              {type === TransactionTypeEnum.WITHDRAW && (
                <Box sx={{ margin: 1 }}>
                  <Typography variant="h6" gutterBottom component="div">
                    History withdrawals
                  </Typography>
                  <Table size="small" aria-label="purchases">
                    <StyledTableHead>
                      <TableRow>
                        <TableCell align="center">Date</TableCell>
                        <TableCell align="center">Fiat</TableCell>
                        <TableCell align="center">Total</TableCell>
                        <TableCell align="center">User</TableCell>
                        <TableCell align="center">
                          Withdraw transaction status history
                        </TableCell>
                        <TableCell align="center">Status</TableCell>
                      </TableRow>
                    </StyledTableHead>
                    <TableBody>
                      <NoItemsWrapper
                        isLoading={isLoading || isFetching}
                        isTableComponent
                        colSpan={9}
                        length={data?.items?.length as number}
                        text={'There is no withdrawals'}
                      >
                        {data?.items?.map(
                          (rowWithdraw: TransactionHistoryItem) => {
                            return (
                              <TableRow key={rowWithdraw?.id}>
                                <TableCell
                                  align="center"
                                  component="th"
                                  scope="row"
                                >
                                  {transformDate(
                                    rowWithdraw?.createdAt,
                                    DateFormatEnum.DATE_WITH_TIME_WITH_DOT,
                                  )}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  component="th"
                                  scope="row"
                                >
                                  {rowWithdraw?.fiat}
                                </TableCell>
                                <TableCell align="center">
                                  {rowWithdraw?.total}
                                </TableCell>
                                <TableCell align="center">
                                  <Box>
                                    <Typography>
                                      {rowWithdraw?.User?.name}{' '}
                                      {rowWithdraw?.User?.lastName}
                                    </Typography>
                                    <Typography>
                                      {rowWithdraw?.User?.email}
                                    </Typography>
                                  </Box>
                                </TableCell>
                                <TableCell align="center">
                                  <Box>
                                    {rowWithdraw?.WithdrawTransactionStatusHistory?.map(
                                      (item) => {
                                        return (
                                          <Box key={item?.createdAt}>
                                            {item?.User?.email}
                                            <FormHelperText
                                              sx={{
                                                textAlign: 'center',
                                                mt: 0,
                                                mb: 0.5,
                                              }}
                                            >
                                              {transformDate(
                                                item?.createdAt,
                                                DateFormatEnum.DATE_WITH_TIME_WITH_DOT,
                                              )}
                                              {': '}
                                              {item?.status}
                                            </FormHelperText>
                                          </Box>
                                        );
                                      },
                                    )}
                                  </Box>
                                </TableCell>
                                <TableCell align="center">
                                  {rowWithdraw?.status}
                                </TableCell>
                              </TableRow>
                            );
                          },
                        )}
                      </NoItemsWrapper>
                    </TableBody>
                  </Table>
                </Box>
              )}
            </Collapse>
            {data && (
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={data?.meta?.count}
                rowsPerPage={pagination.rowsPerPage}
                page={pagination.page}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
              />
            )}
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
