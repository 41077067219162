import { HeadCell } from '@/components/table_head';

export const HEAD_CELLS: Array<HeadCell> = [
  {
    id: '#',
    numeric: false,
    disablePadding: false,
    label: '#',
  },
  {
    id: 'id',
    numeric: true,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'title',
    numeric: false,
    disablePadding: true,
    label: 'Title',
  },
  {
    id: 'feedback',
    numeric: false,
    disablePadding: false,
    label: 'Feedback',
  },
  { id: 'email', numeric: true, disablePadding: false, label: 'Email' },
  {
    id: 'createdAt',
    numeric: true,
    disablePadding: false,
    label: 'Date',
  },
  {
    id: 'button',
    numeric: true,
    disablePadding: false,
    label: 'Delete',
  },
];
