import React, { FC } from 'react';
import { Container, Grid } from '@mui/material';
import { SystemLogsTable } from './system-logs-table/system.logs.table';

type SystemLogsProps = {};
export const SystemLogs: FC<SystemLogsProps> = () => {
  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid item xs={12}>
          <SystemLogsTable />
        </Grid>
      </Grid>
    </Container>
  );
};
