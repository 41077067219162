import React, { FC } from 'react';
import { Container, Grid } from '@mui/material';
import { ActivityTable } from './activity-table/activity.table';

type TierProps = {};
export const Activity: FC<TierProps> = () => {
  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid item xs={12}>
          <ActivityTable />
        </Grid>
      </Grid>
    </Container>
  );
};
