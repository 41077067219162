import React, { FC, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { activityJsonParseHelper } from '../../../../helpers/activityJsonParseHelper';
import { transformDate } from '../../../../helpers/dateTransformHelper';
import { isJsonString } from '../../../../helpers/isJsonStringHelper';
import { DateFormatEnum } from '../../../../types/dateTimeFormatOption';
import { ISystemLogsItem } from '../../../../types/system.logs.interface';
import { StackTraceRow } from './system.logs.stack.trace.row';

type SystemLogsRowProps = {
  row: ISystemLogsItem;
  labelId: any;
};

export const SystemLogsRow: FC<SystemLogsRowProps> = ({ row, labelId }) => {
  const [open, setOpen] = useState<boolean>(false);
  const parsedMessage = isJsonString(row?.message)
    ? JSON.parse(row?.message)
    : row?.message;

  return (
    <>
      <TableRow tabIndex={-1} key={row.id}>
        <TableCell id={labelId} align="left">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell id={labelId} align="center">
          {row?.id}
        </TableCell>
        <TableCell align="center">
          {transformDate(
            row?.createdAt,
            DateFormatEnum.DATE_WITH_TIME_WITH_DOT,
          )}
        </TableCell>
        <TableCell align="center">{row?.type}</TableCell>
        <TableCell align="left" sx={{ whiteSpace: 'pre-line' }}>
          {isJsonString(row?.message)
            ? Object.keys(parsedMessage)?.map((el) => {
                return (
                  <Box key={el}>
                    <Typography textAlign="left">
                      {el}: {activityJsonParseHelper(parsedMessage[el])}
                    </Typography>
                  </Box>
                );
              })
            : parsedMessage}
        </TableCell>

        <TableCell align="left" sx={{ whiteSpace: 'pre-line' }}>
          {row?.stackTrace}
        </TableCell>
      </TableRow>
      <StackTraceRow row={row} open={open} />
    </>
  );
};
