import React, { FC } from 'react';
import {
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  Box,
  Typography,
  TablePagination,
  TextField,
} from '@mui/material';
import { spacing } from '@mui/system';
import { styled } from '@mui/system';
import { EnhancedTableHeadComponent } from '@/components';
import { HeadCell } from '@/components/table_head';
import { useDebounce, useServerError, usePagination, useSortBy } from '@/hooks';
import { useGetSystemLogsQuery } from '@/services';
import { NoItemsWrapper } from '@/shared';
import { ISystemLogsItem } from '@/types/system.logs.interface';
import { SystemLogsRow } from './system.logs.row';

const Paper = styled(MuiPaper)(spacing);

const headCells: Array<HeadCell> = [
  {
    id: '#',
    numeric: true,
    disablePadding: true,
    label: '#',
  },
  {
    id: 'id',
    numeric: true,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'createdAt',
    numeric: true,
    disablePadding: false,
    label: 'Created at',
  },
  {
    id: 'type',
    numeric: true,
    disablePadding: false,
    label: 'Type',
  },
  {
    id: 'message',
    numeric: true,
    disablePadding: false,
    label: 'Message',
  },
  {
    id: 'stackTrace',
    numeric: true,
    disablePadding: false,
    label: 'Stack trace',
  },
];

export const SystemLogsTable: FC = () => {
  const [sort, SortComponent] = useSortBy();

  const { onChangeRowsPerPage, onChangePage, pagination, onSearch } =
    usePagination();

  const searchDebounce = useDebounce(pagination.search, 500);

  const {
    data: dataSystemLogs,
    isFetching: isFetchingSystemLogs,
    error: errorSystemLogs,
    isError: isErrorSystemLogs,
  } = useGetSystemLogsQuery(
    {
      limit: pagination.rowsPerPage,
      page: pagination.page + 1,
      search: searchDebounce,
      sort,
    },
    { refetchOnMountOrArgChange: true },
  );

  useServerError({
    isError: isErrorSystemLogs,
    error: errorSystemLogs,
  });

  return (
    <>
      <Box
        mb={2}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          flexWrap: 'wrap',
        }}
      >
        <Typography variant="h3" color="inherit" noWrap textAlign="left">
          System logs
        </Typography>
        <Box sx={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
          <Box>
            <Typography sx={{ my: 1 }} textAlign="left">
              Search
            </Typography>
            <TextField placeholder="Search" size="small" onChange={onSearch} />
          </Box>
          <Box sx={{ display: 'flex', my: 1, alignItems: 'flex-end' }}>
            {SortComponent}
          </Box>
        </Box>
      </Box>
      <Paper sx={{ marginBottom: 5 }}>
        <TableContainer sx={{ maxHeight: 640 }}>
          <Table
            sx={{ minWidth: 800 }}
            aria-labelledby="tableTitle"
            size={'small'}
            aria-label="enhanced table"
            stickyHeader
          >
            <EnhancedTableHeadComponent headCells={headCells} />
            <TableBody>
              <NoItemsWrapper
                length={dataSystemLogs?.items?.length as number}
                text={'There are no system logs'}
                colSpan={5}
                isTableComponent
                isLoading={
                  !dataSystemLogs?.items?.length ? isFetchingSystemLogs : false
                }
              >
                {dataSystemLogs?.items?.map(
                  (row: ISystemLogsItem, index: number) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <SystemLogsRow
                        key={row?.id}
                        row={row}
                        labelId={labelId}
                      />
                    );
                  },
                )}
              </NoItemsWrapper>
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={dataSystemLogs?.meta.total as number}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          rowsPerPageOptions={[10, 20, 50]}
        />
      </Paper>
    </>
  );
};
