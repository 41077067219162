import React, { FC } from 'react';
import { Delete as DeleteIcon } from '@mui/icons-material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { IconButton, TableCell, TableRow, Box } from '@mui/material';
import { IFiatLimit } from '../../../../types/fiat.limit.interface';

type FiatLimitRowProps = {
  row: IFiatLimit;
  labelId: any;
  handleOpenModal: ({
    id,
    feeFiat,
    applyCondition,
    tierId,
    value,
  }: {
    applyCondition: string;
    feeFiat: string;
    id: number | null;
    tierId: number | null;
    value: number | null;
  }) => void;
  isLoadingFiatLimit: boolean;
  disableEdit?: boolean;
  handleDeleteTier: ({ id, name }: { id: number; name: string }) => void;
};

export const FiatLimitRow: FC<FiatLimitRowProps> = ({
  row,
  labelId,
  handleOpenModal,
  isLoadingFiatLimit,
  handleDeleteTier,
}) => {
  return (
    <TableRow tabIndex={-1} key={row.id}>
      <TableCell id={labelId} align="left">
        {row?.applyCondition}
      </TableCell>
      <TableCell align="center">{row?.Tier.name}</TableCell>
      <TableCell align="center">{row?.value}</TableCell>
      <TableCell align="center">{row?.fiat}</TableCell>
      <TableCell align="right">
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <IconButton
            aria-label="Settings"
            size="large"
            title="Edit"
            onClick={() =>
              handleOpenModal({
                id: row?.id as number,
                applyCondition: row?.applyCondition,
                value: row?.value,
                tierId: row?.Tier.id,
                feeFiat: row?.fiat,
              })
            }
            disabled={isLoadingFiatLimit}
          >
            <ModeEditIcon />
          </IconButton>
        </Box>
      </TableCell>
      <TableCell align="right">
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <IconButton
            aria-label="Delete"
            size="large"
            title="Delete"
            onClick={() =>
              handleDeleteTier({
                id: row?.id as number,
                name: row?.applyCondition,
              })
            }
            disabled={isLoadingFiatLimit}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
};
