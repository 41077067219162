import React, { FC } from 'react';
import { Box, Divider, FormHelperText, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { transformDate } from '../../helpers/dateTransformHelper';
import { getIconVerified } from '../../helpers/getIconVerifiedHelper';
import {
  BANK_ACCOUNT_ENUM,
  IBankAccount,
  IBankAccountSlice,
} from '../../types/bank.account.interface';
import { DateFormatEnum } from '../../types/dateTimeFormatOption';

type CompanyCardProps = {
  bankAccount: IBankAccount;
  updateBankAccount: (props: IBankAccountSlice) => void;
  deleteBankAccount: (id: number, companyName: string) => void;
};
export const BankAccountCard: FC<CompanyCardProps> = ({
  bankAccount,
  updateBankAccount = () => {},
  deleteBankAccount = () => {},
}) => {
  const handleUpdate = () => {
    updateBankAccount(bankAccount);
  };
  const handleDelete = () => {
    deleteBankAccount(bankAccount?.id, bankAccount.bankName);
  };

  const isIbanText = bankAccount.isIban ? 'IBAN' : 'Bank account';
  return (
    <Card sx={{ maxWidth: '100%' }} elevation={3}>
      <CardContent sx={{ display: 'flex' }}>
        <Box>
          <Box sx={{ mb: 1 }}>
            <Typography
              variant="h5"
              component="div"
              sx={{ position: 'absolute' }}
            >
              #{bankAccount.id}
            </Typography>
            <Typography variant="h5" component="div">
              {bankAccount.bankName}
            </Typography>
            <FormHelperText sx={{ textAlign: 'center', mt: 0 }}>
              {bankAccount.contactEmail}
            </FormHelperText>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <Box>
              <Grid container>
                {Object.keys(BANK_ACCOUNT_ENUM).map((el: string) => {
                  const enumKey = el as keyof typeof BANK_ACCOUNT_ENUM;
                  return (
                    <React.Fragment key={el}>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={3}
                        lg={3}
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          width="100%"
                        >
                          {BANK_ACCOUNT_ENUM[enumKey] === 'IBAN'
                            ? isIbanText
                            : BANK_ACCOUNT_ENUM[enumKey]}
                          :
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        md={9}
                        lg={9}
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Typography
                          variant="body2"
                          width="100%"
                          ml={1}
                          whiteSpace="pre-line"
                          textAlign="left"
                        >
                          {typeof bankAccount[enumKey] === 'boolean'
                            ? getIconVerified(
                                Boolean(bankAccount[enumKey]) as boolean,
                              )
                            : bankAccount[enumKey]}
                        </Typography>
                      </Grid>
                      <Divider sx={{ width: '100%', my: 1 }} />
                    </React.Fragment>
                  );
                })}
              </Grid>
            </Box>
          </Box>
        </Box>
      </CardContent>
      <CardActions>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            flexWrap: 'wrap',
            gap: '10px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
            }}
          >
            <Button size="small" variant="outlined" onClick={handleUpdate}>
              Update
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="error"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Box>
          <Box>
            <FormHelperText>
              Created at{' '}
              {transformDate(
                bankAccount?.createdAt,
                DateFormatEnum.DATE_WITH_TIME_WITH_DOT,
              )}
            </FormHelperText>
            {bankAccount?.updatedAt && (
              <FormHelperText sx={{ mt: 0 }}>
                Updated at{' '}
                {transformDate(
                  bankAccount?.updatedAt,
                  DateFormatEnum.DATE_WITH_TIME_WITH_DOT,
                )}
              </FormHelperText>
            )}
          </Box>
        </Box>
      </CardActions>
    </Card>
  );
};
