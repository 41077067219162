const ROOTS_AUTH = '/auth';
const ROOTS_USER = '/user';
const ROOTS_REVIEW = '/review';
const ROOTS_ADMIN = '/admin';
const ROOTS_WITHDRAW = '/withdraw';
const ROOTS_PDF = '/pdf';
const ROOTS_COMPLIENCE_OFFICER = '/complience-officer';
const ROOTS_ACCOUNT_STATEMENTS = '/account-statement';
const ROOTS_TIER = '/tier';
const ROOTS_FEE = '/fee';
const ROOTS_FIAT_LIMIT = '/fiat-limit';
const RECEIVE_FIAT_TRANSACTION = '/receive-fiat-transaction';
const ROOTS_COUNTRY = '/admin-country';

export const PATH_AUTH_API = {
  ROOT: ROOTS_AUTH,
  ADMIN_LOGIN: `${ROOTS_ADMIN}${ROOTS_AUTH}/login`,
  LOGIN: `${ROOTS_AUTH}/login`,
  REGISTER: `${ROOTS_AUTH}/register`,
  FORGOT_PASSWORD: `${ROOTS_AUTH}/forgot-password`,
  RESET_PASSWORD: `${ROOTS_AUTH}/reset-password`,
  CONFIRM_VERIFICATION: `${ROOTS_AUTH}/verify`,
  GET_USER: `${ROOTS_AUTH}/user/profile`,
  SEND_2FA_CODE: `${ROOTS_AUTH}/transaction-send-code`,
  VERIFY_2FA_CODE: `${ROOTS_AUTH}/transaction-verify-code`,
  GENERATE_2FA_TOTP: `${ROOTS_AUTH}/2fa-totp/generate`,
  VERIFY_LOGIN_2FA_TOTP: `${ROOTS_AUTH}/login/2fa-totp/verify`,
  VERIFY_2FA_TOTP: `${ROOTS_AUTH}/2fa-totp/verify`,
  SEND_PHONE_CODE: `${ROOTS_AUTH}/register/phone/send-code`,
  SEND_EMAIL_CODE: `${ROOTS_AUTH}/register/email/send-code`,
  VERIFY_PHONE_CODE: `${ROOTS_AUTH}/register/phone`,
  CHANGE_2FA: `${ROOTS_AUTH}/2fa`,
};

export const PATH_USER_API = {
  ROOT: ROOTS_USER,
  PROFILE: `${ROOTS_USER}/profile`,
  PASSWORD: `${ROOTS_USER}/password`,
  BALANCE: `${ROOTS_USER}/balance`,
  AVAILABLE_BALANCE: `${ROOTS_USER}/system-balance`,
  IBAN: `${ROOTS_USER}/iban`,
  CHANGE_2FA: `${ROOTS_USER}/2fa`,
  DELETE_2FA_TOTP: `${ROOTS_USER}/2fa-totp`,
};
export const PATH_USER_ACTIVITY_API = {
  ACTIVITY: `${ROOTS_ADMIN}/user-activity`,
};
export const PATH_SYSTEM_LOGS_API = {
  LOGS: `${ROOTS_ADMIN}/logs`,
};

export const PATH_USER_PDF_API = {
  ROOT: ROOTS_PDF,
  ACCOUNT_CONFIRMATION: `${ROOTS_PDF}/account-confirmation`,
  ACCOUNT_STATEMENT: `${ROOTS_PDF}/account-statement`,
};

export const PATH_ACCOUNT_STATEMENT_API = {
  ROOT: ROOTS_ACCOUNT_STATEMENTS,
  ACCOUNT_STATEMENT_FILTERS: `${ROOTS_ACCOUNT_STATEMENTS}/filters`,
};

export const PATH_COMPLIENCE_OFFICER_API = {
  ROOT: ROOTS_COMPLIENCE_OFFICER,
  ACCOUNT_CONFIRMATION: `${ROOTS_COMPLIENCE_OFFICER}/pdf/account-confirmation`,
};

export const PATH_TRANSACTIONS_API = {
  WITHDRAW_CRYPTO: '/withdraw/request/crypto',
  WITHDRAW_FIAT: '/withdraw/request/fiat',
  EXCHANGE: '/exchange/send',
  EXCHANGE_FEE: '/exchange/fee',
  HISTORY: '/transaction-history',
};

export const PATH_REVIEW_API = {
  ROOT: ROOTS_REVIEW,
  USER: `${ROOTS_REVIEW}/user`,
  MY: `${ROOTS_REVIEW}/my`,
};

export const PATH_ADMIN_API = {
  ROOT: ROOTS_ADMIN,
  USERS: `${ROOTS_ADMIN}/users`,
  USERS_TIER: `${ROOTS_ADMIN}/users/tier`,
  SETTINGS: `${ROOTS_ADMIN}/settings`,
  USERS_ROLES: `${ROOTS_ADMIN}/users/roles`,
  USERS_ROLE: `${ROOTS_ADMIN}/users/role`,
  USERS_INVITE: `${ROOTS_ADMIN}/users/invite`,
  USERS_REGISTER: `${ROOTS_ADMIN}/users/register`,
  USERS_IBANS: `${ROOTS_ADMIN}/users/ibans`,
  USERS_IBANS_VERIFY: `${ROOTS_ADMIN}/users/iban`,
  USERS_EXTERNAL_WALLETS_VERIFY: `${ROOTS_ADMIN}/users/external-wallet`,
  TRANSACTIONS: `${ROOTS_ADMIN}/transactions`,
  SYSTEM_BALANCE_DOPOSITE: `${ROOTS_ADMIN}/transactions/system-balance-deposit`,
  GAS_STATION: `${ROOTS_ADMIN}/settings/gas-station`,
  GAS_STATION_ASSETS: `${ROOTS_ADMIN}/settings/gas-station/assets`,
  ALL_TIER: `${ROOTS_ADMIN}${ROOTS_TIER}/all`,
  TIER: `${ROOTS_ADMIN}${ROOTS_TIER}`,
  FEE: `${ROOTS_ADMIN}${ROOTS_FEE}`,
  FEE_ALL: `${ROOTS_ADMIN}${ROOTS_FEE}/all`,
  FEE_CONDITIONS: `${ROOTS_ADMIN}${ROOTS_FEE}/conditions`,
  FIAT_LIMIT: `${ROOTS_ADMIN}${ROOTS_FIAT_LIMIT}`,
  FIAT_LIMIT_ALL: `${ROOTS_ADMIN}${ROOTS_FIAT_LIMIT}/all`,
  FIAT_LIMIT_CONDITIONS: `${ROOTS_ADMIN}${ROOTS_FIAT_LIMIT}/conditions`,
  CRYPTO_CURRENCY: `${ROOTS_ADMIN}/crypto-currency`,
  CRYPTO_CURRENCY_FOR_FILTERS: `${ROOTS_ADMIN}/crypto-currency/currencies-for-filter`,
  FIREBLOCKS_NAME_LIST: `${ROOTS_ADMIN}/crypto-currency/dropdown`,
  B2C2_NAME_LIST: `${ROOTS_ADMIN}/crypto-currency/b2c2-name-list`,
  FUZE_NAME_LIST: `${ROOTS_ADMIN}/crypto-currency/fuze-name-list`,
  UPDATE_FIREBLOCKS_LIST: `${ROOTS_ADMIN}/crypto-currency/update-fireblocks-data`,
  UPDATE_B2C2_LIST: `${ROOTS_ADMIN}/crypto-currency/update-b2c2-data`,
  UPDATE_FUZE_LIST: `${ROOTS_ADMIN}/crypto-currency/update-fuze-data`,
  STAMP_IMAGE: `${ROOTS_ADMIN}/stamp-image`,
  USER_PDF: `${ROOTS_ADMIN}/users/pdf-url`,
  COMPANY_PDF: `${ROOTS_ADMIN}/company-pdf`,
  COMPANY_PDF_IMAGE: `${ROOTS_ADMIN}/company-pdf/image`,
  ALL_BANK_ACCOUNT: `${ROOTS_ADMIN}/bank-account/all`,
  BANK_ACCOUNT: `${ROOTS_ADMIN}/bank-account`,
  ALL_BANNER: `${ROOTS_ADMIN}/banner/all`,
  BANNER: `${ROOTS_ADMIN}/banner`,
  BANNER_TYPES: `${ROOTS_ADMIN}/banner/types`,
  EMAIL_TEMPLATE: `${ROOTS_ADMIN}/email-template`,
  EMAIL_TEMPLATE_ALL: `${ROOTS_ADMIN}/email-template/all`,
  EMAIL_TEMPLATE_TYPES: `${ROOTS_ADMIN}/email-template/types`,
  EMAIL_TEMPLATE_KEYS: `${ROOTS_ADMIN}/email-template/keys`,
  USER_FEEDBACKS: `${ROOTS_ADMIN}/feedback`,
  FUZE_DEPOSIT_ADDRESSES: `${ROOTS_ADMIN}/fuze/deposit-addresses`,
  COUNTRIES: `${ROOTS_COUNTRY}`,
};

export const PATH_WITHDRAW_API = {
  ROOT: ROOTS_WITHDRAW,
  REQUESTS: `${ROOTS_WITHDRAW}/requests`,
  REQUESTS_SUBMIT: `${ROOTS_WITHDRAW}/submit`,
  REQUESTS_STATUS: `${ROOTS_WITHDRAW}/status`,
};
export const PATH_RECEIVE_FIAT_API = {
  ROOT: `${ROOTS_ADMIN}${RECEIVE_FIAT_TRANSACTION}`,
  ALL: `${ROOTS_ADMIN}${RECEIVE_FIAT_TRANSACTION}/all`,
};
