import React, { FC } from 'react';
import { Container, Grid } from '@mui/material';
import { BannersList } from './banners.list';

type BannersProps = {};
export const Banners: FC<BannersProps> = () => {
  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid item xs={12}>
          <BannersList />
        </Grid>
      </Grid>
    </Container>
  );
};
