import React, { FC } from 'react';
import { Box, Divider, FormHelperText, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { transformDate } from '../../helpers/dateTransformHelper';
import { getIconVerified } from '../../helpers/getIconVerifiedHelper';
import { useGetCompanyImageQuery } from '../../services';
import {
  COMPANY_ENUM,
  ICompanyPdf,
  ICompanySlice,
  IMAGE_TYPE,
} from '../../types/company.interface';
import { DateFormatEnum } from '../../types/dateTimeFormatOption';

const ImageBox = styled(Box)({
  height: '150px',
  padding: 3,
  border: '1px solid',
  borderRadius: '5px',
  svg: {
    fill: '#000071',
    maxWidth: '500px',
    maxHeight: '250px',
    height: '100%',
    width: '100%',
    path: {
      fill: '#000071 !important',
    },
  },
});

type CompanyCardProps = {
  company: ICompanyPdf;
  updateCompany: (props: ICompanySlice) => void;
  deleteCompany: (id: number, companyName: string) => void;
};
export const CompanyCard: FC<CompanyCardProps> = ({
  company,
  updateCompany = () => {},
  deleteCompany = () => {},
}) => {
  const { data: imageStampData, isError: isErrorImageStampData } =
    useGetCompanyImageQuery(
      { id: company.id as number, type: IMAGE_TYPE.STAMP },
      { skip: !company.id },
    );

  const { data: imageSignatureData, isError: isErrorImageSignatureData } =
    useGetCompanyImageQuery(
      {
        id: company.id as number,
        type: IMAGE_TYPE.SIGNATURE,
      },
      { skip: !company.id },
    );

  const handleUpdate = () => {
    updateCompany(company);
  };
  const handleDelete = () => {
    deleteCompany(company.id, company.companyName);
  };
  return (
    <Card sx={{ maxWidth: '100%' }} elevation={3}>
      <CardContent sx={{ display: 'flex' }}>
        <Box>
          <Box sx={{ mb: 1 }}>
            <Typography
              variant="h5"
              component="div"
              sx={{ position: 'absolute' }}
            >
              #{company.id}
            </Typography>
            <Typography variant="h5" component="div">
              {company.companyName}
            </Typography>
            <FormHelperText sx={{ textAlign: 'center', mt: 0 }}>
              {company.companyTag}
            </FormHelperText>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <Box>
              <Grid container>
                {Object.keys(COMPANY_ENUM).map((el: string) => {
                  const enumKey = el as keyof typeof COMPANY_ENUM;
                  return (
                    <React.Fragment key={el}>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={3}
                        lg={3}
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          width="100%"
                        >
                          {COMPANY_ENUM[enumKey]}:
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        md={9}
                        lg={9}
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Typography
                          variant="body2"
                          width="100%"
                          ml={1}
                          whiteSpace="pre-line"
                          textAlign="left"
                        >
                          {typeof company[enumKey] === 'boolean'
                            ? getIconVerified(
                                Boolean(company[enumKey]) as boolean,
                              )
                            : company[enumKey]}
                        </Typography>
                      </Grid>
                      <Divider sx={{ width: '100%', my: 1 }} />
                    </React.Fragment>
                  );
                })}
              </Grid>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
              gap: '20px',
            }}
          >
            {!isErrorImageStampData && imageStampData && (
              <Box>
                <FormHelperText>Stamp:</FormHelperText>
                <ImageBox
                  dangerouslySetInnerHTML={{
                    __html: imageStampData || '',
                  }}
                />
              </Box>
            )}
            {!isErrorImageSignatureData && imageSignatureData && (
              <Box>
                <FormHelperText>Signature:</FormHelperText>
                <ImageBox
                  dangerouslySetInnerHTML={{
                    __html: imageSignatureData || '',
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </CardContent>
      <CardActions>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            flexWrap: 'wrap',
            gap: '10px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
            }}
          >
            <Button size="small" variant="outlined" onClick={handleUpdate}>
              Update
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="error"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Box>
          <Box>
            <FormHelperText>
              Created at{' '}
              {transformDate(
                company?.createdAt,
                DateFormatEnum.DATE_WITH_TIME_WITH_DOT,
              )}
            </FormHelperText>
            {company?.updatedAt && (
              <FormHelperText sx={{ mt: 0 }}>
                Updated at{' '}
                {transformDate(
                  company?.updatedAt,
                  DateFormatEnum.DATE_WITH_TIME_WITH_DOT,
                )}
              </FormHelperText>
            )}
          </Box>
        </Box>
      </CardActions>
    </Card>
  );
};
