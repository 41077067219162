// created by Artem
import React, { FC, Fragment } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, Container, Divider, IconButton, Typography } from '@mui/material';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { useDispatch } from 'react-redux';
import { Loader, NoItemsWrapper } from '@/shared';
import { useNotification, useServerError } from '../../../hooks';
import { usePagination } from '../../../hooks/usePagination';
import {
  useGetGasStationAssetsQuery,
  useGetGasStationQuery,
  useGetUsersQuery,
} from '../../../services';
import { setModalState } from '../../../store/ui';
import { UsersGasStationTable } from './gas-station-users-table/users.gas.station.table';

export const UsersGasStationPage: FC = () => {
  const { onChangeRowsPerPage, onChangePage, pagination } = usePagination();

  const { showNotification } = useNotification();

  const { data, isFetching, error, isError } = useGetUsersQuery(
    {
      limit: pagination.rowsPerPage,
      page: pagination.page + 1,
    },
    { refetchOnMountOrArgChange: true },
  );
  const {
    data: gasStationData,
    isFetching: isFetchingGasStation,
    error: errorGasStation,
    isError: isErrorGasStation,
  } = useGetGasStationQuery({});

  const {
    data: gasStationAssetsData,
    isFetching: isFetchingGasStationAssets,
    error: errorGasStationAssets,
    isError: isErrorGasStationAssets,
  } = useGetGasStationAssetsQuery({});

  useServerError({ isError: isError, error: error });
  useServerError({ isError: isErrorGasStation, error: errorGasStation });
  useServerError({
    isError: isErrorGasStationAssets,
    error: errorGasStationAssets,
  });

  const dispatch = useDispatch();

  const handleOpenGasStationModal = () => {
    dispatch(
      setModalState({
        visible: true,
        name: 'gasStationEdit',
      }),
    );
  };

  const handleCopyAddress = (address: string) => {
    navigator.clipboard
      .writeText(address)
      .then(() => {
        showNotification('Address copied to clipboard!', 'success');
      })
      .catch((errorCopy) => {
        showNotification(`Error copying address: ${errorCopy}`, 'error');
      });
  };

  return (
    <Container maxWidth="xl">
      <Typography variant="h3" color="inherit" textAlign="left">
        Gas station
      </Typography>
      <Typography sx={{ my: 2 }} textAlign="left">
        Gas station settings
      </Typography>
      <Grid container sx={{ mb: 4 }} spacing={2}>
        <Grid item md={7} sm={12} xs={12}>
          <Card variant="outlined">
            <Typography
              color="text.secondary"
              gutterBottom
              variant="h6"
              sx={{ paddingTop: 2 }}
            >
              Gas Station Assets
            </Typography>
            <Divider />
            <NoItemsWrapper
              length={gasStationAssetsData?.length as number}
              text={'There are no data'}
              isLoading={isFetchingGasStationAssets}
            >
              {gasStationAssetsData?.map((gasItem) => {
                return (
                  <Fragment key={gasItem?.id}>
                    <CardContent>
                      <Typography
                        color="text.secondary"
                        gutterBottom
                        variant="h6"
                      >
                        {gasItem.id}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                        }}
                      >
                        <Typography
                          variant="h6"
                          component="div"
                          textAlign="left"
                          noWrap
                        >
                          Address:{' '}
                          <b title={gasItem?.address}>{gasItem?.address}</b>{' '}
                        </Typography>
                        <IconButton
                          onClick={() => handleCopyAddress(gasItem?.address)}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </Box>

                      <Typography variant="h6" component="div" textAlign="left">
                        Balance: <b>{gasItem?.balance}</b>
                      </Typography>
                    </CardContent>
                    <Divider />
                  </Fragment>
                );
              })}
            </NoItemsWrapper>
          </Card>
        </Grid>
        <Grid item md={5} sm={12} xs={12}>
          <Card variant="outlined">
            <Typography
              color="text.secondary"
              gutterBottom
              variant="h6"
              sx={{ paddingTop: 2 }}
            >
              Gas Station
            </Typography>
            <NoItemsWrapper
              length={
                (gasStationData &&
                  (Object.keys(gasStationData).length as number)) ||
                0
              }
              text={'There are no data'}
              isLoading={isFetchingGasStation}
            >
              <CardContent>
                <Typography variant="h5" component="div" textAlign="left">
                  Gas Cap: <b>{gasStationData?.gasCap as string}</b>
                </Typography>
                <Typography variant="h5" component="div" textAlign="left">
                  Gas Threshold: <b>{gasStationData?.gasThreshold}</b>
                </Typography>
                <Typography variant="h5" component="div" textAlign="left">
                  Max Gas Price: <b>{gasStationData?.maxGasPrice}</b>
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleOpenGasStationModal}
                >
                  Edit
                </Button>
              </CardActions>
            </NoItemsWrapper>
          </Card>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          {data?.data ? (
            <UsersGasStationTable
              users={data?.data}
              count={data?.meta.total}
              page={pagination?.page}
              rowsPerPage={pagination?.rowsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              isLoadingUsers={isFetching}
            />
          ) : (
            <Box display="flex" alignItems="center" justifyContent="center">
              {isFetching ? (
                <Loader />
              ) : (
                <Typography>Something went wrong!</Typography>
              )}
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
