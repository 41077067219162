export enum OperationStatusEnum {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}

export type OperationStatus = 'PENDING' | 'APPROVED' | 'COMPLETED' | 'CANCELED';
export type OperationStatusFiat =
  | 'PENDING'
  | 'SUBMITTED'
  | 'COMPLETED'
  | 'FAILED';

export enum OperationStatusFiatEnum {
  PENDING = 'PENDING',
  SUBMITTED = 'SUBMITTED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}
