import React, { FC, useEffect } from 'react';
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {
  useCreateEmailTemplateMutation,
  useGetEmailTemplateByIdQuery,
} from '@/services';
import { LoadingComponent } from '@/shared';
import { EmailTemplateForm } from './email-template-form/email-template.form';
import { ArrowBack } from '@mui/icons-material';
import { PATH_ADMIN } from '@/constants/spa-routes';
import { LoadingButton } from '@mui/lab';
import { useNotification, useServerError } from '@/hooks';
import DifferenceOutlinedIcon from '@mui/icons-material/DifferenceOutlined';

export const EmailTemplateItem: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { showNotification } = useNotification();

  const { data: emailTemplate, isLoading } = useGetEmailTemplateByIdQuery(
    { id },
    { skip: !id },
  );

  const [
    createEmailTemplate,
    {
      isLoading: isLoadingCreateEmailTemplate,
      isError: isErrorCreateEmailTemplate,
      error: errorCreateEmailTemplate,
      isSuccess: isSuccessCreateEmailTemplate,
      data: createEmailTemplateData,
    },
  ] = useCreateEmailTemplateMutation();

  const handleDuplicateTemplate = () => {
    if (emailTemplate) {
      createEmailTemplate({
        subject: emailTemplate.subject,
        type: emailTemplate.type,
        active: false,
        isFuze: false,
        html: emailTemplate.html,
        sendCopyTo: emailTemplate.sendCopyTo,
        htmlEditorConfig: emailTemplate.htmlEditorConfig,
      });
    }
  };

  useEffect(() => {
    if (isSuccessCreateEmailTemplate && createEmailTemplateData) {
      navigate(`${PATH_ADMIN.EMAIL_TEMPLATE}/${createEmailTemplateData.id}`);

      showNotification(
        `Email template #${id} duplicated successfully!`,
        'success',
      );
    }
  }, [isSuccessCreateEmailTemplate, createEmailTemplateData]);

  useServerError({
    isError: isErrorCreateEmailTemplate,
    error: errorCreateEmailTemplate,
  });

  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', alignItems: 'baseline', gap: '8px' }}>
                <Link
                  variant="body2"
                  component={NavLink}
                  to={PATH_ADMIN.EMAIL_TEMPLATE}
                >
                  <ArrowBack sx={{ color: '#1f1f21' }} fontSize="large" />
                </Link>
                <Typography
                  variant="h3"
                  color="inherit"
                  noWrap
                  textAlign="left"
                >
                  {id ? 'Email template update' : 'Email template create'}
                </Typography>
              </Box>
              {id && (
                <Typography align="left" ml={1} py={2} variant="h6">
                  Update Email template <b>#{id}</b>
                </Typography>
              )}
            </Box>
            <Box>
              {id && emailTemplate && (
                <LoadingButton
                  variant="contained"
                  loadingPosition="end"
                  loading={isLoadingCreateEmailTemplate}
                  startIcon={<DifferenceOutlinedIcon />}
                  onClick={handleDuplicateTemplate}
                >
                  Duplicate this template
                </LoadingButton>
              )}
            </Box>
          </Box>
          {!isLoading ? (
            <EmailTemplateForm id={id} data={emailTemplate} />
          ) : (
            <LoadingComponent fullscreen={false} size={120} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
