export interface IEmailTemplateSlice {
  id: number | null;
  text: string;
  subject: string;
  type: string;
  active: boolean;
  isFuze: boolean;
  sendCopyTo: string;
  html: string;
}
export interface IEmailTemplateSchema {
  subject: string;
  type: string;
  active: boolean;
  isFuze: boolean;
  sendCopyTo: string[];
  sendCopyToField: string;
}

export interface IEmailTemplate {
  id: number;
  subject: string;
  type: string;
  sendCopyTo: string;
  html: string;
  active: boolean;
  isFuze: boolean;
  createdAt: string;
  updatedAt: string;
  htmlEditorConfig: string;
}

export interface IEmailTemplateKeys {
  ACCOUNT_NUMBER: string;
  APPLICANT_ID: string;
  EMAIL: string;
  FIRST_NAME: string;
  LAST_NAME: string;
  MIDDLE_NAME: string;
}

export enum EMAIL_TEMPLATE_ENUM {
  subject = 'Subject',
  sendCopyTo = 'Send copy to',
  type = 'Type',
  active = 'Active',
  isFuze = 'Is Fuze',
}

export enum EMAIL_TYPE {
  SUCCESSFULL_REGISTRATION_EMAIL = 'SUCCESSFULL_REGISTRATION_EMAIL',
  KYC_VERIFIED_EMAIL = 'KYC_VERIFIED_EMAIL',
  PDF_CONFIRMATION_EMAIL = 'PDF_CONFIRMATION_EMAIL',
}

export type UpdateEmailTemplatePayload = Omit<
  IEmailTemplate,
  'createdAt' | 'updatedAt'
>;

export type CreateEmailTemplatePayload = Omit<
  IEmailTemplate,
  'id' | 'createdAt' | 'updatedAt'
>;
