import React, { FC } from 'react';
import { Container, Grid } from '@mui/material';
import { CurrenciesTable } from './currencies-table/currencies.table';

type TierProps = {};
export const Currencies: FC<TierProps> = () => {
  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid item xs={12}>
          <CurrenciesTable />
        </Grid>
      </Grid>
    </Container>
  );
};
