import React, { ReactNode, useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type DialogProps = {
  open: boolean;
  handleClose: () => void;
  buttonApplyHandler: () => void;
  title?: string;
  description?: ReactNode | string;
  applyBtnTitle?: string;
  applyButtonColor?:
    | 'primary'
    | 'inherit'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | undefined;
  loading?: boolean;
};

export type DialogHandlerProps = {
  title?: string;
  applyBtnTitle?: string;
  applyButtonColor?:
    | 'primary'
    | 'inherit'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | undefined;
  description?: ReactNode | string;
  loadingDelete?: boolean;
  buttonApplyHandler?: any;
};

export const DialogComp = (props: DialogProps) => {
  const {
    open,
    handleClose,
    buttonApplyHandler,
    title,
    description,
    applyBtnTitle,
    applyButtonColor,
    loading,
  } = props;

  const handleApplyBtn = () => {
    buttonApplyHandler();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <LoadingButton
          onClick={handleApplyBtn}
          color={applyButtonColor}
          autoFocus
          variant="outlined"
          loading={loading}
        >
          {applyBtnTitle}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export function useDialog() {
  const [open, setOpen] = useState(false);
  const [titleState, setTitleState] = useState<string>('');
  const [applyButtonColorState, setApplyButtonColorState] = useState<
    | 'primary'
    | 'inherit'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | undefined
  >(undefined);
  const [applyBtnTitleState, setApplyBtnTitleState] = useState<string>('');
  const [descriptionState, setDescriptionState] = useState<ReactNode | string>(
    '',
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonHandlerState, setButtonHandlerState] = useState<any>(null);

  const handleOpenDialog = ({
    title = 'Delete?',
    description = 'Are you sure you want to delete?',
    applyBtnTitle = 'Delete',
    buttonApplyHandler = () => {},
    applyButtonColor = 'error',
    loadingDelete = false,
  }: DialogHandlerProps) => {
    setLoading(loadingDelete as boolean);
    setTitleState(title);
    setDescriptionState(description);
    setApplyBtnTitleState(applyBtnTitle);
    setApplyButtonColorState(applyButtonColor);
    setButtonHandlerState(() => buttonApplyHandler);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    return () => {
      setTitleState('');
      setDescriptionState('');
      setButtonHandlerState(null);
    };
  }, []);

  const DialogComponent = (
    <DialogComp
      open={open}
      handleClose={handleClose}
      buttonApplyHandler={buttonHandlerState}
      title={titleState}
      applyButtonColor={applyButtonColorState}
      description={descriptionState}
      applyBtnTitle={applyBtnTitleState}
      loading={loading}
    />
  );

  return [handleOpenDialog, DialogComponent] as const;
}
