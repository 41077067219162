import React, { FC, useState } from 'react';
import { Delete as DeleteIcon } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import BusinessIcon from '@mui/icons-material/Business';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SettingsIcon from '@mui/icons-material/Settings';
import { IconButton, TableCell, TableRow, Box } from '@mui/material';
import { theme } from '../../../../assets/theme';
import { getIconVerified } from '../../../../helpers/getIconVerifiedHelper';
import { UsersInterface } from '../../../../types/admin.interface';
import { ExternalWalletsUsersRow } from './external.wallets.users.row';
import { UserIbans } from './ibans.users.row';
import { UserPdf } from './pdf.users.row';
import { TransactionsRow } from './transactions.row';

type handleOpenModalType = {
  id: number;
  isDeleted: boolean;
  hasTransactions: boolean;
  userName: string;
};
type handleOpenEditModalType = {
  id: number;
  userRole: string;
  userName: string;
};
type handleOpenEditModalTierType = {
  id: number;
  userName: string;
  tierId: number;
};
type handleOpenAddressModalType = {
  id: number;
  userName: string;
};

type UserRowProps = {
  row: UsersInterface;
  labelId: any;
  handleOpenDeleteModal: ({
    id,
    isDeleted,
    hasTransactions,
    userName,
  }: handleOpenModalType) => void;
  isLoadingDeactivate: boolean;
  isLoadingActivate: boolean;
  isLoadingUsers: boolean;
  disableEdit?: boolean;
  handleOpenEditModal?: ({
    id,
    userRole,
    userName,
  }: handleOpenEditModalType) => void;
  handleOpenEditTierModal?: ({
    id,
    tierId,
    userName,
  }: handleOpenEditModalTierType) => void;
  handleOpenAddressModal?: ({
    id,
    userName,
  }: handleOpenAddressModalType) => void;
};

export const UserRow: FC<UserRowProps> = ({
  row,
  labelId,
  handleOpenDeleteModal,
  isLoadingDeactivate,
  isLoadingActivate,
  isLoadingUsers,
  disableEdit,
  handleOpenEditModal = () => {},
  handleOpenEditTierModal = () => {},
  handleOpenAddressModal = () => {},
}) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <TableRow
        tabIndex={-1}
        key={row.id}
        sx={{
          backgroundColor: row.isDeleted
            ? theme.palette.error.light
            : theme.palette.background.default,
          position: open ? 'sticky' : 'static',
          top: '55px',
          zIndex: 10,
        }}
      >
        <TableCell id={labelId} align="left">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell id={labelId} align="center">
          {row?.name}
        </TableCell>
        <TableCell align="center" id={labelId} scope="row" padding="normal">
          {row?.lastName}
        </TableCell>
        <TableCell align="center">{row?.accountNumber}</TableCell>
        <TableCell align="center">{row?.kycAplicant?.applicantId}</TableCell>
        <TableCell align="center">{row?.email}</TableCell>
        <TableCell align="center">
          {getIconVerified(row?.emailVerified)}
        </TableCell>
        <TableCell align="center">
          {getIconVerified(row?.phoneVerified)}
        </TableCell>
        <TableCell align="center">
          {getIconVerified(row?.kycVerified)}
        </TableCell>
        <TableCell align="center">
          {getIconVerified(row?.addressVerified)}
        </TableCell>
        <TableCell align="center">{row?.role}</TableCell>
        <TableCell align="center" sx={{ textWrap: 'nowrap' }}>
          {row?.Tier?.name}
        </TableCell>
        <TableCell align="center">
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton
              aria-label="verify address"
              size="medium"
              title="Verify address"
              onClick={() =>
                handleOpenAddressModal({
                  id: row.id,
                  userName: row?.name + ' ' + row?.lastName,
                })
              }
              disabled={isLoadingUsers || row?.addressVerified}
            >
              <BusinessIcon />
            </IconButton>
          </Box>
        </TableCell>
        <TableCell align="center">
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton
              aria-label="Deactivate"
              size="medium"
              title={
                row?.hasTransactions
                  ? row?.isDeleted
                    ? 'Restore current user'
                    : 'Deactivate current user'
                  : 'Delete current user'
              }
              onClick={() =>
                handleOpenDeleteModal({
                  id: row.id,
                  isDeleted: row?.isDeleted,
                  hasTransactions: row?.hasTransactions,
                  userName: row?.name + ' ' + row?.lastName,
                })
              }
              disabled={
                isLoadingDeactivate || isLoadingActivate || isLoadingUsers
              }
            >
              {row?.isDeleted ? <AddIcon /> : <DeleteIcon />}
            </IconButton>
          </Box>
        </TableCell>
        <TableCell align="center">
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton
              aria-label="Deactivate"
              size="medium"
              title={
                row?.hasTransactions
                  ? row?.isDeleted
                    ? 'Restore current user'
                    : 'Deactivate current user'
                  : 'Delete current user'
              }
              onClick={() =>
                handleOpenEditModal({
                  id: row.id,
                  userRole: row?.role,
                  userName: `${row?.name} ${row?.lastName}`,
                })
              }
              disabled={isLoadingUsers || disableEdit}
            >
              <SettingsIcon />
            </IconButton>
          </Box>
        </TableCell>
        <TableCell align="center">
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton
              aria-label="Deactivate"
              size="medium"
              title="Edit tier id"
              onClick={() =>
                handleOpenEditTierModal({
                  id: row.id,
                  tierId: row?.Tier?.id,
                  userName: `${row?.name} ${row?.lastName}`,
                })
              }
              disabled={isLoadingUsers}
            >
              <EditIcon />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <UserPdf row={row} open={open} />
      <UserIbans row={row} open={open} />
      <ExternalWalletsUsersRow row={row} open={open} />
      <TransactionsRow row={row} open={open} />
    </>
  );
};
