import React, { FC, memo, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  CardContent,
  Dialog,
  Divider,
  FormControl,
  TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { object } from 'yup';
import { useNotification, useServerError } from '../../../hooks';
import {
  useCreateFeeMutation,
  useGetAllFeeQuery,
  useGetAllTierQuery,
  useGetFeeConditionsQuery,
  useUpdateFeeMutation,
} from '../../../services';
import { IFeeSchema } from '../../../types/fee.interface';
import { ITier } from '../../../types/tier.interface';
import { numberScheme, stringScheme } from '../../../utils';

type FeeCreateEditModalProps = {
  open: boolean;
  onClose: () => void;
  feeTierId: number | null;
  id: number | null;
  feeValue: number | null;
  feeApplyCondition: string;
};

export const FeeCreateEditModal: FC<FeeCreateEditModalProps> = memo(
  ({ open, onClose, feeTierId, id, feeValue, feeApplyCondition }) => {
    const { showNotification } = useNotification();

    const { data: feeData } = useGetAllFeeQuery(null);

    const [
      createFee,
      {
        isLoading: isLoadingCreateFee,
        isError: isErrorCreateFee,
        error: errorCreateFee,
        isSuccess: isSuccessCreateFee,
      },
    ] = useCreateFeeMutation();

    const [
      updateFee,
      {
        isLoading: isLoadingUpdateFee,
        isError: isErrorUpdateFee,
        error: errorUpdateFee,
        isSuccess: isSuccessUpdateFee,
      },
    ] = useUpdateFeeMutation();

    const {
      data: feeConditionsData,
      isFetching: isFetchingFeeConditions,
      error: errorFeeConditions,
      isError: isErrorFeeConditions,
    } = useGetFeeConditionsQuery(null);

    const { data: tierData, isFetching: isFetchingTierData } =
      useGetAllTierQuery(null);

    const { handleSubmit, control } = useForm<IFeeSchema>({
      defaultValues: {
        tierId: feeTierId,
        feeValue: feeValue,
        applyCondition: feeApplyCondition,
      },
      resolver: yupResolver(
        object({
          tierId: stringScheme({ required: true }).test(
            'unique-tier',
            'This tier id is already taken for this condition',
            function (value, context) {
              const { applyCondition } = context.parent;

              return feeTierId === (value && +value)
                ? true
                : !feeData?.some(
                    (item) =>
                      item.Tier.id === (value && +value) &&
                      item.applyCondition === applyCondition,
                  );
            },
          ),
          feeValue: numberScheme({ required: true }).integer(
            'Fee Value must be an integer',
          ),
          applyCondition: stringScheme({ required: true }),
        }),
      ) as Resolver<IFeeSchema>,
    });

    const onSubmit = (data: IFeeSchema) => {
      if (id) {
        updateFee({
          id: id,
          value: data?.feeValue && +data?.feeValue,
          applyCondition: data.applyCondition,
          tierId: data?.tierId && +data?.tierId,
        });
      } else {
        createFee({
          value: data?.feeValue && +data?.feeValue,
          applyCondition: data.applyCondition,
          tierId: data?.tierId && +data?.tierId,
        });
      }
    };

    useServerError({
      isError: isErrorCreateFee,
      error: errorCreateFee,
    });
    useServerError({
      isError: isErrorUpdateFee,
      error: errorUpdateFee,
    });
    useServerError({
      isError: isErrorFeeConditions,
      error: errorFeeConditions,
    });

    useEffect(() => {
      if (isSuccessCreateFee || isSuccessUpdateFee) {
        onClose();
        showNotification(
          !id ? `Fee was created!` : `Fee was updated!`,
          'success',
        );
      }
    }, [isSuccessCreateFee, isSuccessUpdateFee]);

    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <CardContent sx={{ overflow: 'auto' }}>
          <CardHeader
            sx={{ textAlign: 'center' }}
            title={id ? 'Fee update' : 'Fee create'}
          />
          <Divider />
          <Typography m={1} py={2} variant="h5">
            {id ? (
              <Typography variant="h6">
                Update fee{' '}
                <b>
                  {feeApplyCondition}, ID #{id}
                </b>
              </Typography>
            ) : (
              <Typography variant="h6">Create new fee</Typography>
            )}
          </Typography>
          <Box
            sx={{
              minWidth: 120,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '20px',
            }}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="tierId"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl error={!!error} fullWidth>
                  <InputLabel id="demo-simple-select-error-label">
                    Tier ID
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-error-label"
                    id="demo-simple-select-error"
                    value={value as string}
                    label="Tier ID"
                    onChange={onChange}
                    disabled={isFetchingTierData}
                  >
                    {tierData &&
                      tierData?.map((el: ITier) => {
                        return (
                          <MenuItem key={el?.id} value={el?.id as any}>
                            {el?.name}, ID {el.id}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {error && <FormHelperText>{error.message}</FormHelperText>}
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="feeValue"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="number"
                    required
                    onChange={onChange}
                    value={value}
                    fullWidth
                    id="feeValue"
                    label="Fee Value"
                    name="feeValue"
                    autoComplete="feeValue"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="applyCondition"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl error={!!error} fullWidth>
                  <InputLabel id="demo-simple-select-error-label">
                    Apply Condition
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-error-label"
                    id="demo-simple-select-error"
                    value={value}
                    label="Apply Condition"
                    onChange={onChange}
                    disabled={isFetchingFeeConditions}
                  >
                    {feeConditionsData &&
                      Object?.values(feeConditionsData)?.map((el, index) => {
                        return (
                          <MenuItem key={el} value={el}>
                            {el}, {Object.keys(feeConditionsData)[index]}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {error && <FormHelperText>{error.message}</FormHelperText>}
                </FormControl>
              )}
            />
          </Box>
          <Button sx={{ mt: 3, ml: 1 }} onClick={onClose} variant="outlined">
            Close
          </Button>
          <LoadingButton
            endIcon={<SaveIcon />}
            loadingPosition="end"
            variant="contained"
            loading={isLoadingCreateFee || isLoadingUpdateFee}
            onClick={handleSubmit(onSubmit)}
            sx={{ mt: 3, ml: 1 }}
            type="submit"
          >
            {id ? 'Update' : 'Create'}
          </LoadingButton>
        </CardContent>
      </Dialog>
    );
  },
);
