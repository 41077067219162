import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import EmailRedactor from './EmailRedactor';
import theme from './theme';

type EmailEditorProps = {
  config: string;
};

export const EmailEditor: React.FC<EmailEditorProps> = ({ config }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <EmailRedactor config={config} />
  </ThemeProvider>
);
