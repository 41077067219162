import { PATH_TRANSACTIONS_API, PATH_USER_API } from '@/constants/api-routes';
import { Crypto } from '@/store/wallet/types';
import { TransactionHistoryResponse } from '@/types/operation-history.interface';
import {
  GetOriginalWalletBalanceResponse,
  MappedWalletBalanceResponse,
  SystemBalance,
  SendCryptoPayload,
  SendFiatPayload,
  ExchangePayload,
} from '@/types/wallet.interface';
import { baseApi } from './base.api';

export const walletService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getTransactions: build.query<
      any[],
      { transactionType?: string } | undefined
    >({
      query: (params) => ({
        url: `${PATH_TRANSACTIONS_API.HISTORY}`,
        params,
      }),
      transformResponse: (response: TransactionHistoryResponse) => {
        return Object.keys(response).reduce((acc: any[], key) => {
          const el: any[] = (response as any)?.[key]?.map((v: any) => {
            const date = v?.createdAt && new Date(v.createdAt);

            return {
              ...v,
              type: key,
              date: `${date.getFullYear()}/${
                date.getMonth() + 1
              }/${date.getDate()}`,
              time: `${date.getHours()}h:${date.getMinutes()}m`,
            };
          });

          return [...acc, ...(el || [])];
        }, []);
      },
      providesTags: ['Transactions'],
    }),
    sendCrypto: build.mutation<any, SendCryptoPayload>({
      query: (params) => ({
        url: `${PATH_TRANSACTIONS_API.WITHDRAW_CRYPTO}`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Balance', 'Transactions'],
    }),
    sendFiat: build.mutation<any, SendFiatPayload>({
      query: (params) => ({
        url: `${PATH_TRANSACTIONS_API.WITHDRAW_FIAT}`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Balance', 'Transactions'],
    }),
    exchange: build.mutation<any, ExchangePayload>({
      query: (params) => ({
        url: `${PATH_TRANSACTIONS_API.EXCHANGE}`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Balance', 'System Balance', 'Transactions'],
    }),
    getExchangeFee: build.query<{ fee: number }, {}>({
      query: () => ({
        url: `${PATH_TRANSACTIONS_API.EXCHANGE_FEE}`,
      }),
    }),
    getBalance: build.query<MappedWalletBalanceResponse, any>({
      query: () => ({
        url: `${PATH_USER_API.BALANCE}`,
      }),
      providesTags: ['Balance'],
      transformResponse: (
        res: GetOriginalWalletBalanceResponse,
      ): MappedWalletBalanceResponse => {
        const notAllowed = ['ETH_TEST3'];
        const mappedCrypto = res.cryptoBalances
          .filter((bal) => !notAllowed.includes(bal.asset))
          .map(({ asset, total, address }) => {
            const [assetSymbol] = asset.split('_');
            return {
              [assetSymbol as Crypto]: {
                total,
                address,
                value: asset,
              },
            };
          })
          .reduce((acc, curr) => ({ ...acc, ...curr }), {}) as any;
        const mappedFiat = res.fiatBalances
          .map(({ total, fiat }) => ({
            [fiat]: { total: total as string },
          }))
          .reduce((acc, curr) => ({ ...acc, ...curr }), {}) as any;
        return {
          withdraw: mappedFiat,
          deposit: mappedCrypto,
          total: res.total,
        };
      },
    }),
    getSystemBalance: build.query<SystemBalance, {}>({
      query: () => ({
        url: `${PATH_USER_API.AVAILABLE_BALANCE}`,
      }),
      providesTags: ['System Balance'],
    }),
  }),
});

export const {
  useExchangeMutation,
  useGetBalanceQuery,
  useGetTransactionsQuery,
  useGetSystemBalanceQuery,
  useGetExchangeFeeQuery,
  useSendCryptoMutation,
  useSendFiatMutation,
} = walletService;
