import { FC, useState, MouseEvent, useRef } from 'react';
import { ClickAwayListener } from '@mui/base';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import EmailIcon from '@mui/icons-material/Email';
import GroupIcon from '@mui/icons-material/Group';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import MenuIcon from '@mui/icons-material/Menu';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PublicIcon from '@mui/icons-material/Public';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import ThreePIcon from '@mui/icons-material/ThreeP';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import WalletIcon from '@mui/icons-material/Wallet';
import { Grid, styled } from '@mui/material';
import {
  Box,
  IconButton,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Tooltip,
  Avatar,
} from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Theme, CSSObject } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Logo from '@/assets/img/QBS-fg.svg';
import { useGetUserQuery } from '@/services';
import { ConfirmDialogComponent } from '@/shared';
import { theme as assetsTheme } from '../../assets/theme';
import { PATH_ADMIN } from '../../constants/spa-routes';

const drawerWidth = 260;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: 0,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const pages = [
  {
    name: 'Users & Transactions',
    route: PATH_ADMIN.TRANSACTION_USERS,
    icon: <GroupIcon />,
  },
  {
    name: 'Account Confirmation',
    route: PATH_ADMIN.ACCOUNT_CONFIRMATION,
    icon: <AdminPanelSettingsIcon />,
  },
  {
    name: 'Gas station',
    route: PATH_ADMIN.GAS_STATION,
    icon: <LocalGasStationIcon />,
  },
  {
    name: 'System balance',
    route: PATH_ADMIN.SYSTEM_BALANCE,
    icon: <AccountBalanceWalletIcon />,
  },
  {
    name: 'Operations',
    route: PATH_ADMIN.OPERATIONS,
    icon: <TransferWithinAStationIcon />,
  },
  {
    name: 'Tier',
    route: PATH_ADMIN.TIER,
    icon: <DynamicFeedIcon />,
  },
  {
    name: 'Crypto currency',
    route: PATH_ADMIN.CURRENCIES,
    icon: <CurrencyBitcoinIcon />,
  },
  {
    name: 'User activity',
    route: PATH_ADMIN.USER_ACTIVITY,
    icon: <PendingActionsIcon />,
  },
  {
    name: 'System logs',
    route: PATH_ADMIN.SYSTEM_LOGS,
    icon: <AnnouncementIcon />,
  },
  {
    name: 'Company PDF',
    route: PATH_ADMIN.COMPANY_PDF,
    icon: <HistoryEduIcon />,
  },
  {
    name: 'Bank account',
    route: PATH_ADMIN.BANK_ACCOUNT,
    icon: <AccountBalanceIcon />,
  },
  {
    name: 'Banners',
    route: PATH_ADMIN.BANNERS,
    icon: <SafetyCheckIcon />,
  },
  {
    name: 'Email template',
    route: PATH_ADMIN.EMAIL_TEMPLATE,
    icon: <EmailIcon />,
  },
  {
    name: 'User feedbacks',
    route: PATH_ADMIN.USER_FEEDBACKS,
    icon: <ThreePIcon />,
  },
  {
    name: 'Deposit addresses',
    route: PATH_ADMIN.DEPOSIT_ADDRESSES,
    icon: <WalletIcon />,
  },
  {
    name: 'Countries',
    route: PATH_ADMIN.COUNTRIES,
    icon: <PublicIcon />,
  },
];

const NavLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  white-space: nowrap;

  &:not(:last-child) {
    margin-right: 2rem;
  }

  &:hover {
    opacity: 0.8;
  }
`;

type HeaderAdminProps = {};
export const HeaderAdmin: FC<HeaderAdminProps> = () => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const storedToken = localStorage.getItem('token');
  const { data: user } = useGetUserQuery(undefined, { skip: !storedToken });

  const handleDrawerOpen = () => {
    setMobileOpen(true);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  const handleOpenUserMenu = ({ currentTarget }: MouseEvent<HTMLElement>) =>
    setAnchorElUser(currentTarget);

  const handleCloseUserMenu = () => setAnchorElUser(null);

  const logoutClickHandle = () => {
    handleCloseUserMenu();
    setConfirm(true);
  };

  const logoutHandle = () => {
    localStorage.removeItem('token');
    navigate('/auth');
  };

  const handleDrawerToggle = () => {
    if (mobileOpen) {
      setMobileOpen(!mobileOpen);
    }
  };

  const burgerRef = useRef<HTMLButtonElement | null>(null);
  const handleClickOutside = (event: any) => {
    if (
      mobileOpen &&
      event.target !== burgerRef.current &&
      !burgerRef?.current?.contains(event.target)
    ) {
      setMobileOpen(!mobileOpen);
    }
  };

  return (
    <>
      <ConfirmDialogComponent
        isOpen={confirm}
        onClose={setConfirm}
        onApprove={logoutHandle}
      />

      <Box sx={{ display: 'flex' }}>
        <AppBar
          position="fixed"
          open={mobileOpen}
          sx={{ backgroundColor: 'grey.900' }}
        >
          <Toolbar>
            <IconButton
              ref={burgerRef}
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                ...(mobileOpen && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Box
              sx={{
                maxWidth: '100%',
                width: '100%',
              }}
            >
              <Toolbar
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <NavLink
                  to="/"
                  sx={{
                    mr: 2,
                    display: { xs: 'none', md: 'flex' },
                    color: 'inherit',
                    textDecoration: 'none',
                  }}
                >
                  <img src={Logo} height="50" alt="qbs" />
                </NavLink>
                <Typography
                  component={NavLink}
                  to="/"
                  sx={{
                    display: { xs: 'flex', md: 'none' },
                    flexGrow: 1,
                    color: 'inherit',
                    textDecoration: 'none',
                    width: '140px',
                  }}
                >
                  <img src={Logo} height="50" alt="qce" />
                </Typography>
                <Grid container justifyContent="flex-end" wrap="nowrap">
                  <Grid item>
                    <Box sx={{ flexGrow: 0 }}>
                      <Tooltip title="Open settings">
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                          <Avatar>{user?.name?.charAt(0) || 'A'}</Avatar>
                        </IconButton>
                      </Tooltip>
                      <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        keepMounted
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                      >
                        <MenuItem onClick={logoutClickHandle}>
                          <Typography textAlign="center">Logout</Typography>
                        </MenuItem>
                      </Menu>
                    </Box>
                  </Grid>
                </Grid>
              </Toolbar>
            </Box>
          </Toolbar>
        </AppBar>
        <ClickAwayListener onClickAway={handleClickOutside}>
          <Drawer variant="permanent" open={mobileOpen}>
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {assetsTheme.direction === 'rtl' ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              {pages.map(({ route, name, icon }) => (
                <ListItem
                  title={name}
                  key={route}
                  disablePadding
                  onClick={handleDrawerToggle}
                >
                  <NavLink to={route} key={route} sx={{ width: '100%' }}>
                    <ListItemButton
                      sx={{
                        backgroundColor:
                          location.pathname === route
                            ? 'rgba(0,0,0,0.1)'
                            : 'transparent',
                      }}
                    >
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText primary={name} />
                    </ListItemButton>
                  </NavLink>
                </ListItem>
              ))}
            </List>
          </Drawer>
        </ClickAwayListener>
        <Box component="main" sx={{ flexGrow: 1 }}>
          <DrawerHeader />
        </Box>
      </Box>
    </>
  );
};
