import {
  BaseQueryMeta,
  BaseQueryResult,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { removeTimerSendCodeStorage } from '../helpers/removeTimerSendCodeStorage';

export const transformTokenResponse = (
  baseQueryReturnValue: BaseQueryResult<any>,
  meta: BaseQueryMeta<any>,
) => {
  removeTimerSendCodeStorage();
  const token =
    meta?.response?.headers?.get('Authorization') ||
    baseQueryReturnValue?.token;
  if (token) localStorage.setItem('token', token);

  return baseQueryReturnValue;
};
