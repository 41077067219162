import { PATH_SYSTEM_LOGS_API } from '../constants/api-routes';
import { ISystemLogs } from '../types/system.logs.interface';
import { baseApi } from './base.api';

export const systemLogsService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getSystemLogs: build.query<
      ISystemLogs,
      { page: number; limit: number; search?: string; sort?: string }
    >({
      query: (params) => ({
        url: `${PATH_SYSTEM_LOGS_API.LOGS}`,
        params,
      }),
      providesTags: ['SystemLogs'],
    }),
    getSystemLogsStackTrace: build.query<{ message: string }, { id: number }>({
      query: ({ id }) => ({
        url: `${PATH_SYSTEM_LOGS_API.LOGS}/${id}/stack-trace`,
      }),
    }),
  }),
});

export const { useGetSystemLogsQuery, useGetSystemLogsStackTraceQuery } =
  systemLogsService;
