import React, { FC, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography,
  Collapse,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/system';
import { theme } from '../../../../assets/theme';
import { transformDate } from '../../../../helpers/dateTransformHelper';
import { getBgColorByStatusHelper } from '../../../../helpers/getBgColorByStatusHelper';
import { getIconVerified } from '../../../../helpers/getIconVerifiedHelper';
import { useNotification, useServerError } from '../../../../hooks';
import { useDialog } from '../../../../hooks/useDialog';
import {
  useGetUserIbansByIdQuery,
  useVerifyUserIbanMutation,
} from '../../../../services';
import { NoItemsWrapper } from '../../../../shared';
import { UsersInterface } from '../../../../types/admin.interface';
import { DateFormatEnum } from '../../../../types/dateTimeFormatOption';
import { IUserIbans } from '../../../../types/withdraw.interface';

const StyledTableHead = styled(TableHead)(({ theme: styledTheme }) => ({
  height: 40,
  '& .MuiTableCell-root': {
    backgroundColor: styledTheme.palette.grey[200],
  },
}));

type TransactionsRowProps = {
  row: UsersInterface;
  open: boolean;
};

export const UserIbans: FC<TransactionsRowProps> = ({ row, open }) => {
  const [handleOpenDialog, DialogComponent] = useDialog();

  const { showNotification } = useNotification();

  const {
    data: dataIbans,
    isError: isErrorIbans,
    error: errorIbans,
    isLoading: isLoadingGetUserIbans,
  } = useGetUserIbansByIdQuery(
    { userId: row?.id },
    { skip: !open, refetchOnMountOrArgChange: true },
  );

  const [
    verifyUserIban,
    {
      isError: isErrorVerifyUserIban,
      error: errorVerifyUserIban,
      isSuccess: isSuccessVerifyIban,
    },
  ] = useVerifyUserIbanMutation();

  const verifyIbanHandler = (id: number) => {
    verifyUserIban({ ibanId: id as number });
  };

  const handleOpenModal = ({ id }: { id: number }) => {
    handleOpenDialog({
      title: 'Verify bank account?',
      description: 'Are you sure you want to verify bank account?',
      applyBtnTitle: 'Verify',
      applyButtonColor: 'success',
      buttonApplyHandler: () => verifyIbanHandler(id),
    });
  };

  useServerError({ isError: isErrorIbans, error: errorIbans });
  useServerError({
    isError: isErrorVerifyUserIban,
    error: errorVerifyUserIban,
  });

  useEffect(() => {
    if (isSuccessVerifyIban) {
      showNotification('Iban verified!', 'success');
    }
  }, [isSuccessVerifyIban]);

  return (
    <>
      {open && (
        <TableRow sx={{ backgroundColor: theme.palette.grey[100] }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={16}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  User bank account
                </Typography>
              </Box>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <StyledTableHead>
                    <TableRow>
                      <TableCell align="center">Created at</TableCell>
                      <TableCell align="center">Updated at</TableCell>
                      <TableCell align="center">Account</TableCell>
                      <TableCell align="center">Bank name</TableCell>
                      <TableCell align="center">Is IBAN</TableCell>
                      <TableCell align="center">Is verified</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </StyledTableHead>
                  <TableBody>
                    <NoItemsWrapper
                      isTableComponent
                      colSpan={9}
                      length={dataIbans?.length as number}
                      text={'There is no bank account'}
                      isLoading={isLoadingGetUserIbans}
                    >
                      {dataIbans?.map((iban: IUserIbans) => {
                        return (
                          <TableRow
                            key={iban?.id}
                            sx={{
                              backgroundColor: getBgColorByStatusHelper(
                                iban.isVerified,
                              ),
                            }}
                          >
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              {transformDate(
                                iban?.createdAt,
                                DateFormatEnum.DATE_WITH_TIME_WITH_DOT,
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {transformDate(
                                iban?.updatedAt,
                                DateFormatEnum.DATE_WITH_TIME_WITH_DOT,
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {iban?.account}
                            </TableCell>
                            <TableCell align="center">
                              {iban?.bankName}
                            </TableCell>
                            <TableCell align="center">
                              {getIconVerified(iban?.isIban)}
                            </TableCell>
                            <TableCell align="center">
                              {getIconVerified(iban?.isVerified)}
                            </TableCell>
                            <TableCell align="right">
                              <IconButton
                                aria-label="Verify"
                                size="small"
                                title={'Verify Iban'}
                                disabled={iban?.isVerified}
                                onClick={() =>
                                  handleOpenModal({
                                    id: iban.id,
                                  })
                                }
                              >
                                <AddIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </NoItemsWrapper>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
            {DialogComponent}
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
