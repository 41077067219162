import React, { FC } from 'react';
import { Container, Grid } from '@mui/material';
import { EmailTemplateList } from './emailTemplate.list';

type EmailTemplateProps = {};
export const EmailTemplate: FC<EmailTemplateProps> = () => {
  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid item xs={12}>
          <EmailTemplateList />
        </Grid>
      </Grid>
    </Container>
  );
};
