// created by Artem
import { FC, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  Grid,
  Button,
  DialogActions,
} from '@mui/material';

type ConfirmDialogComponentProps = {
  isOpen?: boolean;
  data?: any;
  onApprove?: (data?: any) => void;
  onClose?: (status: boolean) => void;
};
export const ConfirmDialogComponent: FC<ConfirmDialogComponentProps> = ({
  isOpen,
  data,
  onClose,
  onApprove,
}) => {
  const [open, setOpen] = useState(!!isOpen);

  const handleClose = () => {
    setOpen((prev) => !prev);
    onClose?.(!open);
  };

  const approveHandle = () => {
    onApprove?.(data);
    handleClose();
  };

  useEffect(() => {
    if (isOpen !== open) setOpen(!!isOpen);
    //  eslint-disable-next-line
  }, [isOpen, setOpen]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs">
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Button onClick={approveHandle} color="primary" variant="outlined">
              Yes
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={handleClose} color="error" variant="outlined">
              No
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
