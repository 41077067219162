import { TransactionHistoryResponse } from '@/types/operation-history.interface';
import {
  PATH_ADMIN_API,
  PATH_COMPLIENCE_OFFICER_API,
  PATH_RECEIVE_FIAT_API,
  PATH_WITHDRAW_API,
} from '../constants/api-routes';
import { IAccountConfirmation } from '../types/account.confirmation.interface';
import {
  AdminBalanceInterface,
  AdminUsersInterface,
  DisableActivateUserInterface,
  IGasStation,
  IGasStationAssets,
  SystemSettingsPayload,
  UpdateSystemSettingsPayload,
  UserRolesInterface,
} from '../types/admin.interface';
import { OperationStatusFiat } from '../types/operations.interface';
import { IReceiveFiat } from '../types/receive.fiat.interface';
import {
  ICryptoCurrencyForFilter,
  IUserIbans,
  IWithdrawRequests,
} from '../types/withdraw.interface';
import { transformTokenResponse } from '../utils';
import { baseApi } from './base.api';

export const adminService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<
      AdminUsersInterface,
      { page: number; limit: number; search?: string }
    >({
      query: (params) => ({
        url: `${PATH_ADMIN_API.USERS}`,
        params,
      }),
      providesTags: ['AdminUsers'],
    }),
    deactivateUser: build.mutation<
      DisableActivateUserInterface,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `${PATH_ADMIN_API.USERS}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AdminUsers'],
    }),
    activateUser: build.mutation<DisableActivateUserInterface, { id: number }>({
      query: ({ id }) => ({
        url: `${PATH_ADMIN_API.USERS}/${id}/activate`,
        method: 'POST',
      }),
      invalidatesTags: ['AdminUsers'],
    }),
    verifyAddress: build.mutation<DisableActivateUserInterface, { id: number }>(
      {
        query: ({ id }) => ({
          url: `${PATH_ADMIN_API.USERS}/${id}/verify-address`,
          method: 'POST',
        }),
        invalidatesTags: ['AdminUsers'],
      },
    ),
    getAdminSettings: build.query<SystemSettingsPayload, any>({
      query: () => ({
        url: PATH_ADMIN_API.SETTINGS,
      }),
      providesTags: ['AdminSettings'],
    }),
    updateAdminSettings: build.mutation<any, UpdateSystemSettingsPayload>({
      query: (params) => ({
        url: PATH_ADMIN_API.SETTINGS,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['AdminSettings'],
    }),
    systemDeposite: build.mutation<
      AdminBalanceInterface,
      { total: number; fiat: string }
    >({
      query: (params) => ({
        url: `${PATH_ADMIN_API.SYSTEM_BALANCE_DOPOSITE}`,
        method: 'PUT',
        body: params,
      }),
    }),
    getAdminUserTransactions: build.query<
      TransactionHistoryResponse,
      {
        type?: string;
        userId: number;
        status?: string;
        skip: number;
        take: number;
      }
    >({
      query: (params) => ({
        url: `${PATH_ADMIN_API.TRANSACTIONS}`,
        params,
      }),
    }),
    getWithdrawRequests: build.query<
      IWithdrawRequests,
      {
        skip: number;
        take: number;
        sortOrder?: string;
        fiat?: string;
        cryptoAssetId?: number;
        status?: string;
        search?: string;
      }
    >({
      query: (params) => ({
        url: `${PATH_WITHDRAW_API.REQUESTS}`,
        params,
      }),
      providesTags: ['Withdraw'],
    }),
    getCryptoCurrencyForFilters: build.query<ICryptoCurrencyForFilter[], {}>({
      query: (params) => ({
        url: `${PATH_ADMIN_API.CRYPTO_CURRENCY_FOR_FILTERS}`,
        params,
      }),
    }),
    getReceiveFiatTransaction: build.query<
      IReceiveFiat,
      {
        skip: number;
        take: number;
        sortOrder?: string;
        currency?: string;
        status?: string;
        search?: string;
      }
    >({
      query: (params) => ({
        url: `${PATH_RECEIVE_FIAT_API.ALL}`,
        params,
      }),
      providesTags: ['ReceiveFiat'],
    }),
    getAccountConfirmations: build.query<
      IAccountConfirmation,
      { isApproved?: boolean; skip: number; take: number }
    >({
      query: (params) => ({
        url: `${PATH_COMPLIENCE_OFFICER_API.ACCOUNT_CONFIRMATION}`,
        params,
      }),
      providesTags: ['AccountConfirmations'],
    }),
    submitWithdrawRequest: build.mutation<
      IWithdrawRequests,
      { withdrawRequestId: number }
    >({
      query: ({ withdrawRequestId }) => ({
        url: `${PATH_WITHDRAW_API.REQUESTS_SUBMIT}/${withdrawRequestId}`,
      }),
    }),
    getUserIbans: build.mutation<IWithdrawRequests, undefined>({
      query: () => ({
        url: `${PATH_ADMIN_API.USERS_IBANS}`,
      }),
    }),
    registerNewUser: build.mutation<
      {
        message: string;
        token: string;
      },
      {
        name: string;
        email: string;
        lastName: string;
        password: string;
        phone: string;
      }
    >({
      query: (params) => ({
        url: `${PATH_ADMIN_API.USERS_REGISTER}`,
        method: 'POST',
        body: params,
      }),
      transformResponse: transformTokenResponse,
      invalidatesTags: ['User'],
    }),
    inviteNewUser: build.mutation<
      { message: string },
      { email: string; role: string }
    >({
      query: (params) => ({
        url: `${PATH_ADMIN_API.USERS_INVITE}`,
        method: 'POST',
        body: params,
      }),
    }),
    getUserRoles: build.query<UserRolesInterface, {}>({
      query: () => ({
        url: `${PATH_ADMIN_API.USERS_ROLES}`,
      }),
    }),
    getUserIbansById: build.query<IUserIbans[], { userId: number }>({
      query: ({ userId }) => ({
        url: `${PATH_ADMIN_API.USERS}/${userId}/ibans`,
      }),
      providesTags: ['UserIbans'],
    }),
    verifyUserIban: build.mutation<any, { ibanId: number }>({
      query: ({ ibanId }) => ({
        url: `${PATH_ADMIN_API.USERS_IBANS_VERIFY}/${ibanId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['UserIbans'],
    }),
    changeStatusWithdrawRequest: build.mutation<
      IWithdrawRequests,
      {
        withdrawRequestId: number;
        status: OperationStatusFiat;
      }
    >({
      query: ({ withdrawRequestId, status }) => ({
        url: `${PATH_WITHDRAW_API.REQUESTS_STATUS}/${withdrawRequestId}`,
        method: 'PUT',
        body: {
          status,
        },
      }),
      invalidatesTags: ['Withdraw'],
    }),
    changeStatusReceiveFiat: build.mutation<
      any,
      {
        id: number;
        status: OperationStatusFiat;
      }
    >({
      query: ({ id, status }) => ({
        url: `${PATH_RECEIVE_FIAT_API.ROOT}/${id}`,
        method: 'PUT',
        body: {
          status,
        },
      }),
      invalidatesTags: ['ReceiveFiat'],
    }),
    approveAccountConfirmations: build.mutation<
      any,
      {
        pdfId: number;
      }
    >({
      query: ({ pdfId }) => ({
        url: `${PATH_COMPLIENCE_OFFICER_API.ACCOUNT_CONFIRMATION}/${pdfId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['AccountConfirmations'],
    }),
    changeUserRole: build.mutation<
      { success: boolean },
      {
        userId: number;
        role: string;
      }
    >({
      query: (body) => ({
        url: `${PATH_ADMIN_API.USERS_ROLE}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['AdminUsers'],
    }),
    getGasStation: build.query<IGasStation, {}>({
      query: () => ({
        url: `${PATH_ADMIN_API.GAS_STATION}`,
      }),
      providesTags: ['GasStation'],
    }),
    gasStationEdit: build.mutation<IGasStation, IGasStation>({
      query: (body) => ({
        url: `${PATH_ADMIN_API.GAS_STATION}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['GasStation'],
    }),
    getGasStationAssets: build.query<IGasStationAssets[], {}>({
      query: () => ({
        url: `${PATH_ADMIN_API.GAS_STATION_ASSETS}`,
      }),
    }),
    changeAutoFuel: build.mutation<any, { userId: number; autoFuel: boolean }>({
      query: ({ userId, autoFuel }) => ({
        url: `${PATH_ADMIN_API.USERS}/${userId}/auto-fuel`,
        method: 'PUT',
        body: {
          autoFuel,
        },
      }),
      invalidatesTags: ['AdminUsers'],
    }),
    getUserPdf: build.query<any, { key: string }>({
      query: ({ key }) => ({
        url: PATH_ADMIN_API.USER_PDF,
        params: {
          key,
        },
        headers: {
          Accept: 'application/octet-stream',
        },
        responseHandler: async (data) => {
          return data.text().then((url: any) => {
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        },
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useDeactivateUserMutation,
  useActivateUserMutation,
  useGetAdminUserTransactionsQuery,
  useSystemDepositeMutation,
  useGetWithdrawRequestsQuery,
  useSubmitWithdrawRequestMutation,
  useChangeStatusWithdrawRequestMutation,
  useGetUserIbansByIdQuery,
  useVerifyUserIbanMutation,
  useGetAccountConfirmationsQuery,
  useApproveAccountConfirmationsMutation,
  useGetUserRolesQuery,
  useInviteNewUserMutation,
  useRegisterNewUserMutation,
  useChangeUserRoleMutation,
  useGetAdminSettingsQuery,
  useUpdateAdminSettingsMutation,
  useGetGasStationQuery,
  useGetGasStationAssetsQuery,
  useChangeAutoFuelMutation,
  useGasStationEditMutation,
  useGetReceiveFiatTransactionQuery,
  useChangeStatusReceiveFiatMutation,
  useGetUserPdfQuery,
  useGetCryptoCurrencyForFiltersQuery,
  useVerifyAddressMutation,
} = adminService;
