export interface IBannerSlice {
  id: number | null;
  text: string;
  text2: string;
  type: string;
  userAccountType: string;
  active: boolean;
  isFuze: boolean;
}
export interface IBannerSchema {
  text: string;
  text2: string;
  type: string;
  userAccountType: string;
  active: boolean;
  isFuze: boolean;
}

export interface IBanner {
  id: number;
  text: string;
  text2: string;
  type: string;
  userAccountType: string;
  active: boolean;
  isFuze: boolean;
  createdAt: string;
  updatedAt: string;
}

export enum BANNER_ENUM {
  text = 'Title',
  text2 = 'Description',
  type = 'Type',
  userAccountType = 'User account type',
  active = 'Active',
  isFuze = 'Is Fuze',
}

export enum ACCOUNT_TYPE {
  PRIVATE = 'PRIVATE',
  CORPORATE = 'CORPORATE',
}
export enum BANNER_TYPE {
  USER_ADDRESS_NOT_VERIFIED = 'USER_ADDRESS_NOT_VERIFIED',
  USER_KYC_KYB_NOT_VERIFIED = 'USER_KYC_NOT_VERIFIED',
}

export const USER_KYC_KYB_NOT_VERIFIED = 'USER_KYC_KYB_NOT_VERIFIED';
