export const activityJsonParseHelper = (value: any): any => {
  const valueTypeIsArray = Array.isArray(value);

  const valueType = typeof value;

  if (valueTypeIsArray) {
    return value
      ?.map((item) =>
        typeof item === 'object' ? Object.values(item)[0] : item,
      )
      .join(', ');
  }

  if (value) {
    if (valueType === 'object') {
      const item = Object.values(value)[0];
      return activityJsonParseHelper(item);
    } else {
      return value;
    }
  }
};
