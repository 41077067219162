import React, { FC, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { IconButton, TableCell, TableRow } from '@mui/material';
import { transformDate } from '../../../helpers/dateTransformHelper';
import { getIconVerified } from '../../../helpers/getIconVerifiedHelper';
import { IAccountConfirmationItem } from '../../../types/account.confirmation.interface';
import { DateFormatEnum } from '../../../types/dateTimeFormatOption';

type RequestsRowProps = {
  row: IAccountConfirmationItem;
  handleOpenModal: any;
  labelId: string;
  isFetching?: boolean;
};

export const RequestsRow: FC<RequestsRowProps> = ({
  row,
  labelId,
  handleOpenModal,
  isFetching = false,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleCheckClick = (type: string) => {
    setOpen(!open);
    handleOpenModal({ id: row.id, email: row?.Requester?.email, type });
  };

  return (
    <>
      <TableRow tabIndex={-1} key={row.id}>
        <TableCell id={labelId} align="left">
          {row?.Requester?.email}
        </TableCell>
        <TableCell id={labelId} align="center">
          {row?.Requester?.name + ' ' + row?.Requester?.lastName}
        </TableCell>
        <TableCell id={labelId} align="center">
          {row?.Requester?.phone}
        </TableCell>
        <TableCell align="center" id={labelId} scope="row" padding="normal">
          {transformDate(
            row?.createdAt,
            DateFormatEnum.DATE_WITH_TIME_WITH_DOT,
          )}
        </TableCell>
        <TableCell align="center">
          {getIconVerified(row?.Requester?.kycVerified)}
        </TableCell>
        <TableCell align="center">{getIconVerified(row?.isApproved)}</TableCell>
        <TableCell align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            disabled={row?.isApproved || isFetching}
            onClick={() => handleCheckClick('approve')}
            title="Approve request"
          >
            <CheckIcon color={row?.isApproved ? 'success' : 'inherit'} />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};
