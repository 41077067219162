import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICompanySlice } from '../../types/company.interface';

export const initialCompanyState: ICompanySlice = {
  id: null,
  companyName: '',
  companyTag: '',
  companyCode: null,
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  addressLine4: '',
  officerName: '',
  officerMail: '',
  officerPosition: '',
  isDefault: false,
  isFuze: false,
  accountStatementFooter: '',
};

export const companySlice = createSlice({
  name: 'uiSlice',
  initialState: initialCompanyState,
  reducers: {
    setCompanyState(
      state: ICompanySlice,
      action: PayloadAction<{
        companyName: string;
        companyTag: string;
        id: number | null;
        companyCode: number | null;
        addressLine1: string;
        addressLine2: string;
        addressLine3: string;
        addressLine4: string;
        officerName: string;
        officerMail: string;
        officerPosition: string;
        isDefault: boolean;
        isFuze: boolean;
        accountStatementFooter: string;
      }>,
    ) {
      const {
        id,
        companyName,
        companyTag,
        companyCode,
        addressLine1,
        addressLine2,
        addressLine3,
        addressLine4,
        officerName,
        officerMail,
        officerPosition,
        isDefault,
        isFuze,
        accountStatementFooter,
      } = action.payload;
      state.id = id;
      state.companyName = companyName;
      state.companyTag = companyTag;
      state.companyCode = companyCode;
      state.addressLine1 = addressLine1;
      state.addressLine2 = addressLine2;
      state.addressLine3 = addressLine3;
      state.addressLine4 = addressLine4;
      state.officerName = officerName;
      state.isDefault = isDefault;
      state.isFuze = isFuze;
      state.officerMail = officerMail;
      state.officerPosition = officerPosition;
      state.accountStatementFooter = accountStatementFooter;
    },
  },
});

export const { setCompanyState } = companySlice.actions;

export default companySlice.reducer;
