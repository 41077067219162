// created by Artem
import { FC } from 'react';
import { CircularProgress } from '@mui/material';
import { LoadingWrap } from './styles';

type LoadingComponentProps = {
  fullscreen?: boolean;
  byContainer?: boolean;
  size?: number;
};
export const LoadingComponent: FC<LoadingComponentProps> = ({
  fullscreen,
  byContainer,
  size = 200,
}) => {
  return (
    <LoadingWrap fullscreen={fullscreen} byContainer={byContainer}>
      <CircularProgress size={size} />
    </LoadingWrap>
  );
};
