// created by Artem
import React, { FC } from 'react';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import {
  Box,
  Container,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Loader } from '@/shared';
import { useDebounce, useServerError } from '../../../hooks';
import { usePagination } from '../../../hooks/usePagination';
import { useGetUsersQuery } from '../../../services';
import { setModalState } from '../../../store/ui';
import { TransactionsUsersTable } from './transactions-users-table/transactions.users.table';

export const TransactionsUsersPage: FC = () => {
  const { onChangeRowsPerPage, onChangePage, pagination, onSearch } =
    usePagination();

  const dispatch = useDispatch();

  const searchDebounce = useDebounce(pagination.search, 500);

  const { data, isFetching, error, isError } = useGetUsersQuery(
    {
      limit: pagination.rowsPerPage,
      page: pagination.page + 1,
      search: searchDebounce,
    },
    { refetchOnMountOrArgChange: true },
  );

  useServerError({ isError: isError, error: error });

  const handleOpenUserInvite = () => {
    dispatch(
      setModalState({
        visible: true,
        name: 'inviteUser',
      }),
    );
  };

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Typography variant="h3" color="inherit" textAlign="left">
              Users & Transactions
            </Typography>
            <IconButton onClick={handleOpenUserInvite}>
              <PersonAddAltIcon />
            </IconButton>
          </Box>
          <Typography sx={{ my: 2 }} textAlign="left">
            List of Users & Transactions
          </Typography>
        </Box>
        <Box
          sx={{
            mb: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <FormHelperText>
            Search by account number, name, last name
          </FormHelperText>
          <TextField placeholder="Search" size="small" onChange={onSearch} />
        </Box>
      </Box>
      <Grid container>
        <Grid item xs={12}>
          {data?.data ? (
            <TransactionsUsersTable
              users={data?.data}
              count={data?.meta.total}
              page={pagination?.page}
              rowsPerPage={pagination?.rowsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              isLoadingUsers={isFetching}
            />
          ) : (
            <Box display="flex" alignItems="center" justifyContent="center">
              {isFetching ? (
                <Loader />
              ) : (
                <Typography>Something went wrong!</Typography>
              )}
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
