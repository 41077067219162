import React, { FC } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { CountriesTable } from '@/pages/admin/countries/countries-table/countries.table';

export const CountriesPage: FC = () => (
  <Container maxWidth="xl">
    <Typography variant="h3" color="inherit" noWrap textAlign="left">
      Countries
    </Typography>
    <Grid container>
      <Grid item xs={12}>
        <CountriesTable />
      </Grid>
    </Grid>
  </Container>
);
