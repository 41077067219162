import React, { FC, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  TableCell,
  TableRow,
  Box,
  Collapse,
  SelectChangeEvent,
  MenuItem,
  Select,
} from '@mui/material';
import { theme } from '../../../../assets/theme';
import {
  OperationStatusFiat,
  OperationStatusFiatEnum,
} from '../../../../types/operations.interface';
import { ReceiveFiatItem } from '../../../../types/receive.fiat.interface';

type ChangeStatusRowProps = {
  row: ReceiveFiatItem;
  handleChangeReceiveFiatStatus: ({
    id,
    status,
  }: {
    id: number;
    status: OperationStatusFiat;
  }) => void;
  isLoadingChangeStatus: boolean;
  open: boolean;
};

export const ChangeStatusRow: FC<ChangeStatusRowProps> = ({
  row,
  handleChangeReceiveFiatStatus,
  isLoadingChangeStatus,
  open,
}) => {
  const [status, setStatus] = useState<string>(row?.status);

  const handleChangeStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  const confirmChangeStatus = () => {
    handleChangeReceiveFiatStatus({
      id: row.id,
      status: status as OperationStatusFiat,
    });
  };

  return (
    <TableRow sx={{ backgroundColor: theme.palette.grey[100] }}>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1, display: 'flex', gap: '10px' }}>
            <Select
              value={status}
              onChange={handleChangeStatus}
              displayEmpty
              size="small"
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value={OperationStatusFiatEnum.SUBMITTED}>
                {OperationStatusFiatEnum.SUBMITTED}
              </MenuItem>
              <MenuItem value={OperationStatusFiatEnum.PENDING}>
                {OperationStatusFiatEnum.PENDING}
              </MenuItem>
              <MenuItem value={OperationStatusFiatEnum.COMPLETED}>
                {OperationStatusFiatEnum.COMPLETED}
              </MenuItem>
              <MenuItem value={OperationStatusFiatEnum.FAILED}>
                {OperationStatusFiatEnum.FAILED}
              </MenuItem>
            </Select>
            <LoadingButton
              loading={isLoadingChangeStatus}
              variant="contained"
              onClick={confirmChangeStatus}
              disabled={row?.status === status}
            >
              Change
            </LoadingButton>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};
