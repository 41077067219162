import React, { FC, useEffect, useState } from 'react';
import EmailIcon from '@mui/icons-material/Email';
import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { EmailTemplateCard } from '../../../components/email_template_card';
import { useNotification, useServerError } from '../../../hooks';
import { useDialog } from '../../../hooks/useDialog';
import {
  useCreateEmailTemplateMutation,
  useDeleteEmailTemplateMutation,
  useGetAllEmailTemplateQuery,
} from '../../../services';
import { NoItemsWrapper } from '../../../shared';
import { Link as NavLink, useNavigate } from 'react-router-dom';
import { PATH_ADMIN } from '@/constants/spa-routes';
import { IEmailTemplate } from '@/types/email.template.interface';

export const EmailTemplateList: FC = () => {
  const { showNotification } = useNotification();

  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(
    null,
  );
  const [handleOpenDialog, DialogComponent] = useDialog();

  const navigate = useNavigate();

  const {
    data: emailTemplateData,
    isLoading: isLoadingEmailTemplateData,
    isError: isErrorEmailTemplateData,
    error: errorEmailTemplateData,
  } = useGetAllEmailTemplateQuery({});

  const [
    deleteEmailTemplate,
    {
      isLoading: isLoadingDeleteEmailTemplate,
      isError: isErrorDeleteEmailTemplate,
      error: errorDeleteEmailTemplate,
      isSuccess: isSuccessDeleteEmailTemplate,
    },
  ] = useDeleteEmailTemplateMutation();

  const [
    createEmailTemplate,
    {
      isLoading: isLoadingCreateEmailTemplate,
      isError: isErrorCreateEmailTemplate,
      error: errorCreateEmailTemplate,
      isSuccess: isSuccessCreateEmailTemplate,
      data: createEmailTemplateData,
    },
  ] = useCreateEmailTemplateMutation();

  const handleDuplicateTemplate = (template: IEmailTemplate) => {
    if (template) {
      setSelectedTemplateId(template.id);

      createEmailTemplate({
        subject: template.subject,
        type: template.type,
        active: false,
        isFuze: false,
        html: template.html,
        sendCopyTo: template.sendCopyTo,
        htmlEditorConfig: template.htmlEditorConfig,
      });
    }
  };

  useEffect(() => {
    if (isSuccessCreateEmailTemplate && createEmailTemplateData) {
      navigate(`${PATH_ADMIN.EMAIL_TEMPLATE}/${createEmailTemplateData.id}`);
    }
  }, [isSuccessCreateEmailTemplate, createEmailTemplateData]);

  useServerError({
    isError: isErrorCreateEmailTemplate,
    error: errorCreateEmailTemplate,
  });

  useServerError({
    isError: isErrorDeleteEmailTemplate,
    error: errorDeleteEmailTemplate,
  });
  useServerError({
    isError: isErrorEmailTemplateData,
    error: errorEmailTemplateData,
  });

  const handleDeleteEmailTemplate = (id: number) => {
    handleOpenDialog({
      description: (
        <>
          Are you sure you want to delete email template, <b>#{id}</b>?
        </>
      ),
      buttonApplyHandler: () => deleteEmailTemplate({ id }),
      loadingDelete: isLoadingDeleteEmailTemplate,
    });
  };

  useEffect(() => {
    if (isSuccessDeleteEmailTemplate) {
      showNotification('Email template was deleted!', 'success');
    }
  }, [isSuccessDeleteEmailTemplate]);

  return (
    <>
      <Box
        mb={2}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          flexWrap: 'wrap',
        }}
      >
        <Typography variant="h3" color="inherit" noWrap textAlign="left">
          Email templates
        </Typography>
        <Typography sx={{ my: 2 }} textAlign="left">
          Create or update email templates
        </Typography>
        <Box>
          <NavLink to={PATH_ADMIN.CREATE_EMAIL_TEMPLATE}>
            <LoadingButton variant="contained" startIcon={<EmailIcon />}>
              Create new email template
            </LoadingButton>
          </NavLink>
        </Box>
        <Box
          sx={{
            display: 'flex',
            my: 5,
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <NoItemsWrapper
            isLoading={isLoadingEmailTemplateData}
            length={emailTemplateData?.length || 0}
            text="There are no email templates"
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                width: '100%',
              }}
            >
              {emailTemplateData?.map((emailTemplate) => {
                return (
                  <EmailTemplateCard
                    key={emailTemplate?.id}
                    emailTemplate={emailTemplate}
                    handleDuplicateTemplate={handleDuplicateTemplate}
                    isLoadingDuplicate={
                      selectedTemplateId === emailTemplate?.id &&
                      isLoadingCreateEmailTemplate
                    }
                    deleteEmailTemplate={handleDeleteEmailTemplate}
                  />
                );
              })}
            </Box>
          </NoItemsWrapper>
        </Box>
        {DialogComponent}
      </Box>
    </>
  );
};
