import React, { FC, memo, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  CardContent,
  Dialog,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { Controller, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { object } from 'yup';
import { useNotification, useServerError } from '../../../hooks';
import {
  useGetUserRolesQuery,
  useInviteNewUserMutation,
} from '../../../services';
import { InviteUserInterface } from '../../../types/admin.interface';
import { emailScheme, stringScheme } from '../../../utils';

type InviteUserProps = {
  open: boolean;
  onClose: () => void;
};

export const InviteUser: FC<InviteUserProps> = memo(({ open, onClose }) => {
  const [
    inviteNewUser,
    {
      isLoading,
      isError: isErrorSystemDeposite,
      error: errorSystemDeposite,
      isSuccess,
    },
  ] = useInviteNewUserMutation();

  const {
    data: userRolesData,
    isFetching: isFetchingUserRoles,
    error: errorUserRoles,
    isError: isErrorUserRoles,
  } = useGetUserRolesQuery({});

  const { showNotification } = useNotification();

  const { handleSubmit, control, reset } = useForm<InviteUserInterface>({
    defaultValues: {
      role: '',
      email: '',
    },
    resolver: yupResolver(
      object({
        role: stringScheme({ required: true }),
        email: emailScheme({ required: true }),
      }),
    ) as Resolver<InviteUserInterface>,
  });

  const onSubmit: SubmitHandler<InviteUserInterface> = (data) => {
    inviteNewUser({ role: data.role, email: data.email });
  };

  useServerError({
    isError: isErrorSystemDeposite,
    error: errorSystemDeposite,
  });
  useServerError({
    isError: isErrorUserRoles,
    error: errorUserRoles,
  });

  useEffect(() => {
    if (isSuccess) {
      reset();
      showNotification('Invite was sent!', 'success');
      onClose();
    }
  }, [isSuccess]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <CardContent sx={{ overflow: 'auto' }}>
        <CardHeader sx={{ textAlign: 'center' }} title="Invite user" />
        <Divider />
        <Typography m={1} py={2} variant="h5">
          Invite new user
        </Typography>
        <Box
          sx={{
            minWidth: 120,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px',
          }}
          component="form"
        >
          <Controller
            control={control}
            name="role"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-helper-label">
                  Role
                </InputLabel>
                <Select
                  disabled={isFetchingUserRoles}
                  value={value}
                  onChange={onChange}
                  error={!!error?.message}
                  label="Role"
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  fullWidth
                >
                  {userRolesData?.availableRoles?.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        <em>{item}</em>
                      </MenuItem>
                    );
                  })}
                </Select>
                {error && (
                  <FormHelperText error={!!error.message}>
                    {error.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                error={!!error?.message}
                helperText={error?.message}
                required
                value={value}
                onChange={onChange}
                fullWidth
                id="email"
                label="Email"
                autoComplete="Email"
              />
            )}
          />
        </Box>
        <Box sx={{ mt: 3, display: 'flex', gap: '20px' }}>
          <Button onClick={onClose} variant="outlined">
            Close
          </Button>
          <LoadingButton
            endIcon={<SendIcon />}
            loading={isLoading}
            loadingPosition="end"
            variant="contained"
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            <span>Send</span>
          </LoadingButton>
        </Box>
      </CardContent>
    </Dialog>
  );
});
