import React, { FC, useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import { useNotification } from '@/hooks';
import { theme } from '../../assets/theme';
import { useSendVerificationCode } from '../../hooks/useSendVerificationCode';
import { useVerificationCodeTimer } from '../../hooks/useVerificationCodeTimer';
import { useGetUserQuery } from '../../services';
import { Loader } from '../../shared';
import { TwoFactorTypesEnum } from '../../types/user.interface';

type AuthVerifyEmailPageProps = {
  sendCodeType: string | TwoFactorTypesEnum.SMS | TwoFactorTypesEnum.EMAIL;
  timerDelay?: number;
  email?: string;
  phone?: string;
  disabled?: boolean;
  withDescription?: boolean;
};
export const SendCodeButton: FC<AuthVerifyEmailPageProps> = ({
  sendCodeType,
  timerDelay = 30,
  email = '',
  phone = '',
  disabled,
  withDescription = true,
}) => {
  const { showNotification } = useNotification();

  const { sendEmailCode, sendPhoneCode, isSuccessSending, isLoadingSending } =
    useSendVerificationCode();

  const storedToken = localStorage.getItem('token');
  const { data: user } = useGetUserQuery(undefined, {
    skip: !storedToken,
  });

  const sendCodeIconLoader = isLoadingSending && <Loader size={15} />;

  const [timer, startTimer, isTimerRunning] = useVerificationCodeTimer({
    initialTime: timerDelay,
    type: sendCodeType,
  });

  const emailOrPhoneText =
    sendCodeType === TwoFactorTypesEnum.EMAIL
      ? ` email ${email}`
      : ` phone ${phone}`;

  const handleSend = () => {
    if (sendCodeType === TwoFactorTypesEnum.EMAIL) {
      sendEmailCode({ email: email || (user?.email as string) });
    } else {
      sendPhoneCode({ phone: phone || (user?.phone as string) });
    }
  };

  useEffect(() => {
    if (isSuccessSending) {
      startTimer();
      showNotification(
        `Confirmation key was sent to your${emailOrPhoneText}`,
        'success',
      );
    }
  }, [isSuccessSending]);

  return (
    <>
      {withDescription && isSuccessSending && (
        <Typography variant="subtitle1">
          Confirmation key was resented to your {emailOrPhoneText}
        </Typography>
      )}
      <Button
        endIcon={isTimerRunning ? timer : sendCodeIconLoader}
        type="button"
        disabled={isTimerRunning || isLoadingSending || disabled}
        fullWidth
        onClick={handleSend}
        sx={{ mt: 3, color: theme.palette.secondary.main }}
      >
        Send code again
      </Button>
    </>
  );
};
