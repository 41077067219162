import React, { FC, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography,
  Collapse,
  IconButton,
  TablePagination,
} from '@mui/material';
import { styled } from '@mui/system';
import { usePagination } from '@/hooks/usePagination';
import { theme } from '../../../../assets/theme';
import { transformDate } from '../../../../helpers/dateTransformHelper';
import { getBgColorByStatusHelper } from '../../../../helpers/getBgColorByStatusHelper';
import { getIconVerified } from '../../../../helpers/getIconVerifiedHelper';
import { useNotification, useServerError } from '../../../../hooks';
import { useDialog } from '../../../../hooks/useDialog';
import {
  useGetUserExternalWalletsByIdQuery,
  useVerifyUserExternalWalletMutation,
} from '../../../../services';
import { NoItemsWrapper } from '../../../../shared';
import { UsersInterface } from '../../../../types/admin.interface';
import { DateFormatEnum } from '../../../../types/dateTimeFormatOption';

const StyledTableHead = styled(TableHead)(({ theme: styledTheme }) => ({
  height: 40,
  '& .MuiTableCell-root': {
    backgroundColor: styledTheme.palette.grey[200],
  },
}));

type ExternalWalletsUsersRowProps = {
  row: UsersInterface;
  open: boolean;
};

export const ExternalWalletsUsersRow: FC<ExternalWalletsUsersRowProps> = ({
  row,
  open,
}) => {
  const [handleOpenDialog, DialogComponent] = useDialog();

  const { showNotification } = useNotification();

  const { onChangeRowsPerPage, onChangePage, pagination } = usePagination();

  const {
    data: externalWalletsData,
    isError: isErrorExternalWallets,
    error: errorExternalWallets,
    isLoading: isLoadingExternalWallets,
  } = useGetUserExternalWalletsByIdQuery(
    {
      userId: row?.id,
      skip: pagination.page * pagination.rowsPerPage,
      take: pagination.rowsPerPage,
    },
    { skip: !open, refetchOnMountOrArgChange: true },
  );

  const [
    verifyUserExternalWallet,
    {
      isError: isErrorVerifyUserExternalWallet,
      error: errorVerifyUserExternalWallet,
      isSuccess: isSuccessVerifyUserExternalWallet,
    },
  ] = useVerifyUserExternalWalletMutation();

  const verifyUserExternalWalletHandler = (id: number) => {
    verifyUserExternalWallet({ externalWalletId: id as number });
  };

  const handleOpenModal = ({ id }: { id: number }) => {
    handleOpenDialog({
      title: 'Verify external wallet?',
      description: 'Are you sure you want to verify external wallet?',
      applyBtnTitle: 'Verify',
      applyButtonColor: 'success',
      buttonApplyHandler: () => verifyUserExternalWalletHandler(id),
    });
  };

  useServerError({
    isError: isErrorExternalWallets,
    error: errorExternalWallets,
  });
  useServerError({
    isError: isErrorVerifyUserExternalWallet,
    error: errorVerifyUserExternalWallet,
  });

  useEffect(() => {
    if (isSuccessVerifyUserExternalWallet) {
      showNotification('Iban verified!', 'success');
    }
  }, [isSuccessVerifyUserExternalWallet]);

  return (
    <>
      {open && (
        <TableRow sx={{ backgroundColor: theme.palette.grey[100] }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={16}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  User external wallets
                </Typography>
              </Box>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <StyledTableHead>
                    <TableRow>
                      <TableCell align="center">Created at</TableCell>
                      <TableCell align="center">Currency</TableCell>
                      <TableCell align="center">Address</TableCell>
                      <TableCell align="center">Is verified</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </StyledTableHead>
                  <TableBody>
                    <NoItemsWrapper
                      isTableComponent
                      colSpan={9}
                      length={externalWalletsData?.items?.length ?? 0}
                      text={'There are no external wallets'}
                      isLoading={isLoadingExternalWallets}
                    >
                      {externalWalletsData?.items?.map((externalWallet) => {
                        return (
                          <TableRow
                            key={externalWallet?.id}
                            sx={{
                              backgroundColor: getBgColorByStatusHelper(
                                externalWallet.isVerified,
                              ),
                            }}
                          >
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              {transformDate(
                                externalWallet?.createdAt,
                                DateFormatEnum.DATE_WITH_TIME_WITH_DOT,
                              )}
                            </TableCell>

                            <TableCell align="center">
                              {externalWallet?.Currency.externalId}
                            </TableCell>
                            <TableCell align="center">
                              {externalWallet?.address}
                            </TableCell>
                            <TableCell align="center">
                              {getIconVerified(externalWallet?.isVerified)}
                            </TableCell>
                            <TableCell align="right">
                              <IconButton
                                aria-label="Verify"
                                size="small"
                                title={'Verify external wallet'}
                                disabled={externalWallet?.isVerified}
                                onClick={() =>
                                  handleOpenModal({
                                    id: externalWallet.id,
                                  })
                                }
                              >
                                <AddIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </NoItemsWrapper>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
            {DialogComponent}
            {externalWalletsData && (
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={externalWalletsData?.meta?.count}
                rowsPerPage={pagination.rowsPerPage}
                page={pagination.page}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
              />
            )}
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
