import { IUserActivity } from '@/types/user.interface';
import { PATH_USER_ACTIVITY_API } from '../constants/api-routes';
import { baseApi } from './base.api';

export const userService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUserActivity: build.query<
      IUserActivity,
      {
        userId?: string;
        userEmail?: string;
        sortOrder?: 'desc' | 'asc';
        skip: number;
        take: number;
      }
    >({
      query: (params) => ({
        url: PATH_USER_ACTIVITY_API.ACTIVITY,
        params,
      }),
    }),
  }),
});

export const { useGetUserActivityQuery } = userService;
