// created by Artem
import React, { FC, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { LockOutlined } from '@mui/icons-material';
import GoogleIcon from '@mui/icons-material/Google';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { useForm, SubmitHandler, Resolver } from 'react-hook-form';
import {
  Link as NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { object } from 'yup';
import { useServerError } from '@/hooks';
import { use2faTotpLoginVerifyMutation } from '@/services';
import { LoginGoogleFormValues } from '@/types/auth.interface';
import { emailScheme, passwordScheme, stringScheme } from '@/utils';
import { PATH_REGISTER } from '../../constants/spa-routes';
import { Loader } from '../../shared';

type GoogleAuthPageProps = {};
export const GoogleAuthPage: FC<GoogleAuthPageProps> = () => {
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState<'password' | 'text'>(
    'password',
  );
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const email: string =
    searchParams.get('email') || location.state?.email || '';
  const password: string =
    searchParams.get('password') || location.state?.password || '';
  const [
    login2FaTotp,
    {
      isLoading: login2FaTotpIsLoading,
      isError: login2FaTotpIsError,
      error: login2FaTotpError,
      isSuccess: isSuccessLogin2FaTotp,
    },
  ] = use2faTotpLoginVerifyMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginGoogleFormValues>({
    defaultValues: {
      email: email,
      password: password,
      code: '',
    },
    resolver: yupResolver(
      object({
        email: emailScheme({ required: true }),
        password: passwordScheme({ required: true }),
        code: stringScheme({ required: true }),
      }),
    ) as Resolver<LoginGoogleFormValues>,
  });

  const onSubmit: SubmitHandler<LoginGoogleFormValues> = (data) => {
    login2FaTotp(data);
  };

  const changePassTypeHandle = () =>
    setPasswordType((prev) => (prev === 'password' ? 'text' : 'password'));

  useServerError({ isError: login2FaTotpIsError, error: login2FaTotpError });

  useEffect(() => {
    if (isSuccessLogin2FaTotp) {
      navigate('/');
    }
  }, [isSuccessLogin2FaTotp]);

  return (
    <Box
      sx={{
        marginTop: 8,
        marginBottom: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlined />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign in with Google Auth
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{ mt: 1 }}
      >
        <TextField
          {...register('email')}
          error={!!errors?.email?.message}
          helperText={errors?.email?.message}
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
        />
        <TextField
          {...register('password')}
          error={!!errors?.password?.message}
          helperText={errors?.password?.message}
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type={passwordType}
          id="password"
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={changePassTypeHandle}>
                  {passwordType === 'text' ? (
                    <RemoveRedEyeOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          {...register('code')}
          error={!!errors?.code?.message}
          helperText={errors?.code?.message}
          margin="normal"
          required
          fullWidth
          name="code"
          label="Code"
          id="code"
          autoComplete="code"
          autoFocus
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={login2FaTotpIsLoading}
          endIcon={
            login2FaTotpIsLoading ? <Loader size={15} /> : <GoogleIcon />
          }
          sx={{ mt: 3, mb: 2 }}
        >
          Sign In
        </Button>
        <Grid container>
          <Grid item xs>
            <Link variant="body2" component={NavLink} to={PATH_REGISTER.ROOT}>
              Back
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
