import React, { FC } from 'react';
import {
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material';
import { spacing } from '@mui/system';
import { styled } from '@mui/system';
import { EnhancedTableHeadComponent } from '@/components';
import { IFuzeDepositAddressesList } from '@/types/fuze.deposit.addresses.interface';
import { NoItemsWrapper } from '../../../../shared';
import { HEAD_CELLS } from './constants';
import { DepositAddressesRow } from './deposit.address.row';

type DepositAddressesTableProps = {
  depositAddresses: IFuzeDepositAddressesList;
};

const Paper = styled(MuiPaper)(spacing);

export const DepositAddressesTable: FC<DepositAddressesTableProps> = ({
  depositAddresses,
}) => {
  return (
    <Paper sx={{ marginBottom: 10 }}>
      <TableContainer sx={{ maxHeight: 640 }}>
        <Table
          sx={{ minWidth: 800 }}
          aria-labelledby="tableTitle"
          size={'small'}
          aria-label="enhanced table"
          stickyHeader
        >
          <EnhancedTableHeadComponent headCells={HEAD_CELLS} />
          <TableBody>
            {depositAddresses?.map((row) => {
              return <DepositAddressesRow key={row?.id} row={row} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <NoItemsWrapper
        length={depositAddresses?.length}
        text={'There are no Fuze deposit addresses found'}
      ></NoItemsWrapper>
    </Paper>
  );
};
