import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFeeSlice } from '../../types/fee.interface';

const initialState: IFeeSlice = {
  applyCondition: '',
  feeFiat: '',
  id: null,
  tierId: null,
  value: null,
};

export const feeFiatSlice = createSlice({
  name: 'uiSlice',
  initialState,
  reducers: {
    setFeeFiatState(
      state: IFeeSlice,
      action: PayloadAction<{
        applyCondition: string;
        feeFiat: string;
        id: number | null;
        tierId: number | null;
        value: number | null;
      }>,
    ) {
      const { applyCondition, id, tierId, value, feeFiat } = action.payload;
      state.applyCondition = applyCondition;
      state.id = id;
      state.tierId = tierId;
      state.value = value;
      state.feeFiat = feeFiat;
    },
  },
});

export const { setFeeFiatState } = feeFiatSlice.actions;

export default feeFiatSlice.reducer;
