import React, { FC, useEffect } from 'react';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { CompanyCard } from '../../../components';
import { useNotification, useServerError } from '../../../hooks';
import { useDialog } from '../../../hooks/useDialog';
import {
  useDeleteCompanyPdfMutation,
  useGetCompanyPdfQuery,
} from '../../../services';
import { NoItemsWrapper } from '../../../shared';
import { setCompanyState, setModalState } from '../../../store/ui';
import { ICompanySlice } from '../../../types/company.interface';

export const CompanyPdfList: FC = () => {
  const { showNotification } = useNotification();
  const [handleOpenDeleteDialog, DialogComponent] = useDialog();

  const dispatch = useDispatch();

  const {
    data: companyPdfData,
    isFetching: isFetchingCompanyPdf,
    isError: isErrorCompanyPdf,
    error: errorCompanyPdf,
  } = useGetCompanyPdfQuery({});

  const [
    deleteCompany,
    {
      isLoading: isLoadingDeleteCompany,
      isError: isErrorDeleteCompany,
      error: errorDeleteCompany,
      isSuccess: isSuccessDeleteCompany,
    },
  ] = useDeleteCompanyPdfMutation();

  useServerError({
    isError: isErrorDeleteCompany,
    error: errorDeleteCompany,
  });
  useServerError({
    isError: isErrorCompanyPdf,
    error: errorCompanyPdf,
  });

  const handleDeleteCompany = (id: number, companyName: string) => {
    handleOpenDeleteDialog({
      description: (
        <>
          Are you sure you want to delete company, <b>{companyName}</b>?
        </>
      ),
      buttonApplyHandler: () => deleteCompany({ id }),
      loadingDelete: isLoadingDeleteCompany,
    });
  };

  const handleCreateCompany = () => {
    dispatch(
      setModalState({
        visible: true,
        name: 'companyModal',
      }),
    );
  };
  const handleUpdateCompany = (company: ICompanySlice) => {
    dispatch(
      setModalState({
        visible: true,
        name: 'companyModal',
      }),
    );
    dispatch(setCompanyState(company));
  };

  useEffect(() => {
    if (isSuccessDeleteCompany) {
      showNotification('Company was deleted!', 'success');
    }
  }, [isSuccessDeleteCompany]);

  return (
    <>
      <Box
        mb={2}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          flexWrap: 'wrap',
        }}
      >
        <Typography variant="h3" color="inherit" noWrap textAlign="left">
          Company PDF
        </Typography>
        <Typography sx={{ my: 2 }} textAlign="left">
          Create or update existing company info
        </Typography>
        <Box>
          <LoadingButton
            variant="contained"
            startIcon={<AddBusinessIcon />}
            onClick={handleCreateCompany}
          >
            Create new company
          </LoadingButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            my: 5,
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <NoItemsWrapper
            isLoading={isFetchingCompanyPdf}
            length={companyPdfData?.length || 0}
            text="There are no companies"
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              {companyPdfData?.map((company) => {
                return (
                  <CompanyCard
                    key={company?.id}
                    company={company}
                    updateCompany={handleUpdateCompany}
                    deleteCompany={handleDeleteCompany}
                  />
                );
              })}
            </Box>
          </NoItemsWrapper>
        </Box>
        {DialogComponent}
      </Box>
    </>
  );
};
