import React, { FC, memo, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  Button,
  CardContent,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Switch,
  TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { object } from 'yup';
import { useNotification, useServerError } from '@/hooks';
import {
  useCreateBankAccountMutation,
  useGetAllBankAccountQuery,
  useUpdateBankAccountMutation,
} from '../../../services';
import {
  IBankAccountSchema,
  IBankAccountSlice,
} from '../../../types/bank.account.interface';
import { FiatCurrencies } from '../../../types/currencies.interface';
import {
  booleanScheme,
  EMAIL_REGEXP,
  ibanScheme,
  stringScheme,
} from '../../../utils';

type BankAccountModalProps = IBankAccountSlice & {
  open: boolean;
  onClose: () => void;
};

export const BankAccountModal: FC<BankAccountModalProps> = memo(
  ({
    open,
    onClose,
    id,
    sbaName,
    currency,
    multicurrency,
    isIban,
    iban,
    bic,
    accountNumber,
    bankName,
    beneficiaryInformation,
    receivingBankInformation,
    contactEmail,
    active,
  }) => {
    const [isIbanSchema, setIsIbanSchema] = useState<boolean>(false);
    const [isMulticurrency, setIsMulticurrency] = useState<boolean>(false);
    const { showNotification } = useNotification();

    const { data: bankAccountData } = useGetAllBankAccountQuery({});

    const [
      createBankAccount,
      {
        isLoading: isLoadingCreateBankAccount,
        isError: isErrorCreateBankAccount,
        error: errorCreateBankAccount,
        isSuccess: isSuccessCreateBankAccount,
      },
    ] = useCreateBankAccountMutation();

    const [
      updateBankAccount,
      {
        isLoading: isLoadingUpdateBankAccount,
        isError: isErrorUpdateBankAccount,
        error: errorUpdateBankAccount,
        isSuccess: isSuccessUpdateBankAccount,
      },
    ] = useUpdateBankAccountMutation();

    const {
      handleSubmit,
      control,
      watch,
      trigger,
      formState: { isDirty },
    } = useForm<IBankAccountSchema>({
      defaultValues: {
        sbaName,
        currency,
        multicurrency,
        isIban,
        iban,
        bic,
        accountNumber,
        bankName,
        beneficiaryInformation,
        receivingBankInformation,
        contactEmail,
        active,
      },
      resolver: yupResolver(
        object({
          sbaName: stringScheme({ required: true }).label('SBA name'),
          currency: stringScheme({ required: !isMulticurrency })
            .nullable()
            .label('Currency'),
          multicurrency: booleanScheme({ required: false }).label(
            'Multicurrency',
          ),
          isIban: booleanScheme({ required: false }).label('Is iban'),
          iban: stringScheme({ required: true }).when(
            'isIban',
            (isIbanVal, schema) => {
              if (isIbanVal[0]) {
                return ibanScheme(schema, { required: true })
                  .label('IBAN')
                  .test(
                    'unique-iban',
                    `This IBAN is already taken`,
                    function (value) {
                      if (iban !== value) {
                        return !bankAccountData?.some(
                          (item: any) => item.iban === value,
                        );
                      } else {
                        return true;
                      }
                    },
                  );
              }
              return schema
                .label('Bank account')
                .test(
                  'unique-account',
                  `This bank account is already taken`,
                  function (value) {
                    if (iban !== value) {
                      return !bankAccountData?.some(
                        (item: any) => item.iban === value,
                      );
                    } else {
                      return true;
                    }
                  },
                );
            },
          ),
          bic: stringScheme({ required: false }).label('BIC'),
          accountNumber: isIbanSchema
            ? stringScheme({ required: false })
                .label('Account number')
                .nullable()
            : stringScheme({ required: true })
                .label('Account number')
                .test(
                  'unique-account-number',
                  'This account number is already taken',
                  function (value) {
                    if (accountNumber !== value) {
                      return !bankAccountData?.some(
                        (item: any) => item.accountNumber === value,
                      );
                    } else {
                      return true;
                    }
                  },
                )
                .nullable(),
          bankName: stringScheme({ required: true }).label('Bank name'),
          beneficiaryInformation: stringScheme({ required: false }).label(
            'Beneficiary information',
          ),
          receivingBankInformation: stringScheme({ required: false }).label(
            'Receiving bank information',
          ),
          contactEmail: stringScheme({ required: false })
            .label('Contact email')
            .test('validEmail', 'Email must be valid', function (value) {
              if (value) {
                return EMAIL_REGEXP.test(value || '');
              } else {
                return true;
              }
            })
            .nullable(),
          active: booleanScheme({ required: false }).label('Active'),
        }),
      ) as Resolver<IBankAccountSchema>,
    });

    const activeWatch = watch('active');
    const isIbanWatch = watch('isIban');
    const multicurrencyWatch = watch('multicurrency');

    const onSubmit = (data: IBankAccountSchema) => {
      if (id) {
        updateBankAccount({
          sbaName: data.sbaName,
          currency: data.currency,
          multicurrency: data.multicurrency,
          iban: data.iban,
          bic: data.bic,
          accountNumber: data.accountNumber,
          bankName: data.bankName,
          beneficiaryInformation: data.beneficiaryInformation,
          receivingBankInformation: data.receivingBankInformation,
          contactEmail: data.contactEmail,
          active: data.active,
          isIban: data.isIban,
          id,
        });
      } else {
        createBankAccount({
          sbaName: data.sbaName,
          currency: data.currency ? data.currency : null,
          multicurrency: data.multicurrency,
          iban: data.iban,
          bic: data.bic,
          accountNumber: data.accountNumber,
          bankName: data.bankName,
          beneficiaryInformation: data.beneficiaryInformation,
          receivingBankInformation: data.receivingBankInformation,
          contactEmail: data.contactEmail ? data.contactEmail : null,
          active: data.active,
          isIban: data.isIban,
        });
      }
    };

    useServerError({
      isError: isErrorCreateBankAccount,
      error: errorCreateBankAccount,
    });
    useServerError({
      isError: isErrorUpdateBankAccount,
      error: errorUpdateBankAccount,
    });

    useEffect(() => {
      if (isSuccessCreateBankAccount) {
        onClose();
        showNotification('Bank account was created!', 'success');
      }
      if (isSuccessUpdateBankAccount) {
        onClose();
        showNotification('Bank account was updated!', 'success');
      }
    }, [isSuccessCreateBankAccount, isSuccessUpdateBankAccount]);

    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <CardContent sx={{ overflow: 'auto' }}>
          <CardHeader
            sx={{ textAlign: 'center' }}
            title={id ? 'Bank account update' : 'Bank account create'}
          />
          <Divider />
          <Typography m={1} py={2} variant="h5">
            {id && (
              <Typography variant="h6">
                Update bank account <b>{bankName}</b>
              </Typography>
            )}
          </Typography>
          <Box
            sx={{
              minWidth: 120,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="bankName"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="string"
                    required
                    onChange={onChange}
                    value={value}
                    fullWidth
                    id="bankName"
                    label="Bank name"
                    name="bankName"
                    autoComplete="off"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="sbaName"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="string"
                    onChange={onChange}
                    value={value}
                    fullWidth
                    required
                    id="sbaName"
                    label="SBA name"
                    name="sbaName"
                    autoComplete="off"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="accountNumber"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                    required={!isIbanSchema}
                    id="accountNumber"
                    label="Account number"
                    name="accountNumber"
                    autoComplete="off"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="iban"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="string"
                    onChange={onChange}
                    value={value}
                    fullWidth
                    required
                    id="iban"
                    label={isIbanSchema ? 'IBAN' : 'Bank account'}
                    name="iban"
                    autoComplete="off"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="isIban"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl sx={{ mt: 2 }}>
                  <InputLabel id="demo-simple-select-helper-label" shrink>
                    Is IBAN
                  </InputLabel>

                  <FormControlLabel
                    onChange={(e) => {
                      setIsIbanSchema((prevState) => !prevState);
                      onChange(e);
                      trigger('iban');
                    }}
                    control={<Switch defaultChecked checked={value} />}
                    label={`${isIbanWatch}`}
                  />
                  {error && (
                    <FormHelperText error={!!error.message}>
                      {error.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="currency"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <Autocomplete
                    sx={{ m: '16px 0 8px 0' }}
                    disablePortal
                    id="combo-box-demo"
                    onChange={(event, val) => onChange(val)}
                    value={value}
                    options={FiatCurrencies}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Currency"
                        error={!!error?.message}
                        helperText={error?.message}
                      />
                    )}
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="multicurrency"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl
                  sx={{
                    mt: 2,
                  }}
                >
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    shrink
                    sx={{ textAlign: 'center', overflow: 'visible' }}
                  >
                    Multicurrency
                  </InputLabel>

                  <FormControlLabel
                    onChange={(e) => {
                      setIsMulticurrency((prevState) => !prevState);
                      onChange(e);
                    }}
                    control={<Switch defaultChecked checked={value} />}
                    label={`${multicurrencyWatch}`}
                  />
                  {error && (
                    <FormHelperText error={!!error.message}>
                      {error.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="bic"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="string"
                    onChange={onChange}
                    value={value}
                    fullWidth
                    id="bic"
                    label="BIC"
                    name="bic"
                    autoComplete="off"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="beneficiaryInformation"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="string"
                    onChange={onChange}
                    value={value}
                    fullWidth
                    multiline
                    id="beneficiaryInformation"
                    label="Beneficiary information"
                    name="beneficiaryInformation"
                    autoComplete="off"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="receivingBankInformation"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="string"
                    onChange={onChange}
                    value={value}
                    fullWidth
                    multiline
                    id="receivingBankInformation"
                    label="Receiving bank information"
                    name="receivingBankInformation"
                    autoComplete="off"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="contactEmail"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="string"
                    onChange={onChange}
                    value={value}
                    fullWidth
                    id="contactEmail"
                    label="Contact email"
                    name="contactEmail"
                    autoComplete="off"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="active"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl sx={{ mt: 2 }}>
                  <InputLabel id="demo-simple-select-helper-label" shrink>
                    Active
                  </InputLabel>

                  <FormControlLabel
                    onChange={onChange}
                    control={<Switch defaultChecked checked={value} />}
                    label={`${activeWatch}`}
                  />
                  {error && (
                    <FormHelperText error={!!error.message}>
                      {error.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Box>
          <Button sx={{ mt: 3, ml: 1 }} onClick={onClose} variant="outlined">
            Close
          </Button>
          <LoadingButton
            endIcon={<SaveIcon />}
            loadingPosition="end"
            variant="contained"
            disabled={!isDirty}
            loading={isLoadingCreateBankAccount || isLoadingUpdateBankAccount}
            onClick={handleSubmit(onSubmit)}
            sx={{ mt: 3, ml: 1 }}
            type="submit"
          >
            {id ? 'Update' : 'Create'}
          </LoadingButton>
        </CardContent>
      </Dialog>
    );
  },
);
