import { IUserFeedbacksPaginated } from '@/types/user.feedbacks.interface';
import { PATH_ADMIN_API } from '../constants/api-routes';
import { baseApi } from './base.api';

export const userFeedbacksService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllUserFeedbacks: build.query<
      IUserFeedbacksPaginated,
      { page: number; limit: number; search?: string; sort?: string }
    >({
      query: (params) => ({
        url: `${PATH_ADMIN_API.USER_FEEDBACKS}`,
        params,
      }),
      providesTags: ['UserFeedbacks'],
    }),
    deleteUserFeedback: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `${PATH_ADMIN_API.USER_FEEDBACKS}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['UserFeedbacks'],
    }),
  }),
});

export const { useGetAllUserFeedbacksQuery, useDeleteUserFeedbackMutation } =
  userFeedbacksService;
