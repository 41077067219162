import { FC, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Save as SaveIcon } from '@mui/icons-material';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { Controller, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { Link as NavLink, useLocation, useNavigate } from 'react-router-dom';
import { object, string } from 'yup';
import { useServerError } from '@/hooks';
import { useRegisterNewUserMutation } from '@/services';
import { RegisterFormValues } from '@/types/auth.interface';
import { emailScheme, passwordConfirmScheme, passwordScheme } from '@/utils';
import { theme } from '../../assets/theme';
import { PATH_ADMIN } from '../../constants/spa-routes';

const StyledPhoneInput = styled(PhoneInput)(({ theme: styledTheme }) => ({
  '&.react-tel-input .form-control:focus': {
    borderColor: styledTheme.palette.primary.main,
    boxShadow: `0px 0px 0px 1px ${styledTheme.palette.primary.main}`,
  },
}));

type RegisterPageProps = {};
export const AuthRegisterFormPage: FC<RegisterPageProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const emailFromUrl = searchParams.get('email');

  const [
    registerRequest,
    { isLoading, isError: isRegisterError, error: registerError },
  ] = useRegisterNewUserMutation();

  const [passwordType, setPasswordType] = useState<'password' | 'text'>(
    'password',
  );
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    control,
    formState: { errors },
  } = useForm<RegisterFormValues>({
    defaultValues: {
      name: '',
      email: emailFromUrl ?? '',
      lastName: '',
      password: '',
      passwordConfirmation: '',
      phone: '',
    },
    resolver: yupResolver(
      object({
        name: string().required().min(4),
        email: emailScheme({ required: true }),
        lastName: string().required().min(4),
        password: passwordScheme({ required: true }),
        passwordConfirmation: passwordConfirmScheme({ required: true }),
        phone: string().required().min(4),
      }),
    ) as Resolver<RegisterFormValues>,
  });

  const { phone } = getValues();

  const phoneHandler = (phoneNumber: string, isErrorMessage: boolean) => {
    setValue('phone', `+${phoneNumber}`);
    if (isErrorMessage) {
      trigger('phone');
    }
  };

  const inputPhoneStyle = {
    width: '100%',
    padding: '18px 14px 18px 84px',
    borderColor: errors?.phone?.message ? theme.palette.error.main : '',
  };

  const navigateToAdminPanel = () => {
    navigate(PATH_ADMIN.TRANSACTION_USERS);
  };

  const onSubmit: SubmitHandler<RegisterFormValues> = (data) =>
    registerRequest({
      name: data.name,
      email: data.email,
      lastName: data.lastName,
      password: data.password,
      phone: data.phone,
    })
      .unwrap()
      .then(() => navigateToAdminPanel())
      .catch(() => null);

  const changePassTypeHandle = () =>
    setPasswordType((prev) => (prev === 'password' ? 'text' : 'password'));

  useServerError({ isError: isRegisterError, error: registerError });

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      sx={{ mt: 1 }}
    >
      <TextField
        {...register('name')}
        error={!!errors?.name?.message}
        helperText={errors?.name?.message}
        margin="normal"
        required
        fullWidth
        id="v"
        label="Name"
        name="name"
        autoComplete="given-name"
        autoFocus
      />
      <TextField
        {...register('lastName')}
        error={!!errors?.lastName?.message}
        helperText={errors?.lastName?.message}
        margin="normal"
        required
        fullWidth
        id="lastName"
        label="Last Name"
        name="lastName"
        autoComplete="family-name"
      />
      <TextField
        {...register('email')}
        error={!!errors?.email?.message}
        helperText={errors?.email?.message}
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email"
        name="email"
        type="email"
        autoComplete="email"
        sx={{ marginBottom: '24px' }}
      />
      <Controller
        control={control}
        name="phone"
        render={({ fieldState: { error } }) => (
          <>
            <StyledPhoneInput
              country={'us'}
              value={phone}
              onChange={(phoneNumber) =>
                phoneHandler(phoneNumber, !!error?.message)
              }
              inputClass="phone-input"
              inputStyle={inputPhoneStyle}
            />
            {!!error?.message && (
              <Typography
                align="left"
                variant="caption"
                color={theme.palette.error.main}
                sx={{
                  margin: '4px 14px 0 14px',
                  width: '100%',
                  display: 'block',
                }}
              >
                {error?.message}
              </Typography>
            )}
          </>
        )}
      />
      <TextField
        {...register('password')}
        error={!!errors?.password?.message}
        helperText={errors?.password?.message}
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type={passwordType}
        id="password"
        autoComplete="new-password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={changePassTypeHandle}>
                {passwordType === 'text' ? (
                  <RemoveRedEyeOutlinedIcon />
                ) : (
                  <VisibilityOffOutlinedIcon />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        {...register('passwordConfirmation')}
        error={!!errors?.passwordConfirmation?.message}
        helperText={errors?.passwordConfirmation?.message}
        margin="normal"
        required
        fullWidth
        name="passwordConfirmation"
        label="Repeat password"
        type={passwordType}
        id="passwordConfirmation"
        autoComplete="new-password"
      />
      <Button
        type="submit"
        disabled={isLoading}
        endIcon={
          isLoading ? (
            <CircularProgress color="secondary" size={20} />
          ) : (
            <SaveIcon />
          )
        }
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Sign Up
      </Button>
      <Grid container>
        <Grid item>
          <Link variant="body2" component={NavLink} to="/auth">
            Already has account
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};
