import React from 'react';
import { Box } from '@mui/material';

type CurrencyIconComponentType = {
  iconString: string;
  height?: number | string;
  width?: number | string;
};
export const CurrencyIconComponent = ({
  iconString,
  height = 30,
  width = 30,
}: CurrencyIconComponentType) => {
  const isSVG = /<svg[\s\S]*<\/svg>/i.test(iconString);

  if (iconString) {
    if (isSVG) {
      return (
        <Box
          dangerouslySetInnerHTML={{ __html: iconString }}
          height={height}
          width={width}
        />
      );
    } else {
      return <img src={iconString} alt="Icon" height={height} width={width} />;
    }
  } else return <></>;
};
