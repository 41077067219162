import React, { FC } from 'react';
import { RemoveCircle } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IconButton, TableCell, TableRow, Box } from '@mui/material';

type CountriesRowProps = any;

export const CountriesRow: FC<CountriesRowProps> = ({
  row,
  isFetchingCountriesData,
  handleChangeStatus,
}) => {
  return (
    <TableRow tabIndex={-1} key={row.id}>
      <TableCell align="left">{row?.id}</TableCell>
      <TableCell align="center">{row?.name}</TableCell>
      <TableCell align="center">
        <img
          loading="lazy"
          width="32"
          srcSet={`https://flagcdn.com/w80/${row?.isoCode?.toLowerCase()}.png 2x`}
          src={`https://flagcdn.com/w40/${row?.isoCode?.toLowerCase()}.png`}
          alt=""
        />
      </TableCell>
      <TableCell align="center">{row?.isoCode}</TableCell>
      <TableCell align="center">
        {row?.status ? (
          <CheckCircleIcon color="success" />
        ) : (
          <CancelIcon color="error" />
        )}
      </TableCell>
      <TableCell align="right">
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <IconButton
            aria-label="Settings"
            size="large"
            title="Edit"
            onClick={() => handleChangeStatus(row.id, !row.status)}
            disabled={isFetchingCountriesData}
          >
            {row?.status ? <RemoveCircle /> : <AddCircleIcon />}
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
};
