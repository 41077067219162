import { HeadCell } from '@/components/table_head';

export const HEAD_CELLS: Array<HeadCell> = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'address',
    numeric: false,
    disablePadding: true,
    label: 'Address',
  },
  {
    id: 'asset',
    numeric: false,
    disablePadding: false,
    label: 'Asset',
  },
  { id: 'chain', numeric: false, disablePadding: false, label: 'Chain' },
  {
    id: 'fireblocksWalletId',
    numeric: false,
    disablePadding: false,
    label: 'Fireblocks wallet id',
  },
  { id: 'fuzeId', numeric: false, disablePadding: false, label: 'Fuze id' },
  {
    id: 'userWalletId',
    numeric: true,
    disablePadding: false,
    label: 'User wallet id',
  },
];
