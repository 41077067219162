import React, { FC, memo, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  CardContent,
  Dialog,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { object } from 'yup';
import { useNotification, useServerError } from '@/hooks';
import {
  useChangeUserRoleMutation,
  useGetUserRolesQuery,
} from '../../../services';
import { Loader } from '../../../shared';
import { IUserRoles } from '../../../types/admin.interface';
import { stringScheme } from '../../../utils';

type ChangeLoginTypeProps = {
  open: boolean;
  onClose: () => void;
  userId: string | number | null | undefined;
  userRole: string | null | undefined;
  userName: string | null | undefined;
};

export const ChangeUserRole: FC<ChangeLoginTypeProps> = memo(
  ({ open, onClose, userRole = '', userId, userName }) => {
    const { showNotification } = useNotification();

    const {
      data: userRolesData,
      isFetching: isFetchingUserRoles,
      error: errorUserRoles,
      isError: isErrorUserRoles,
    } = useGetUserRolesQuery({}, { skip: !open });

    const [
      changeUserRole,
      {
        isLoading: isLoadingChangeUserRole,
        isError: isErrorChangeUserRole,
        error: errorChangeUserRole,
        isSuccess: isSuccessChangeUserRole,
      },
    ] = useChangeUserRoleMutation();

    const { handleSubmit, control, watch } = useForm<IUserRoles>({
      defaultValues: {
        role: userRole as string,
      },
      resolver: yupResolver(
        object({
          role: stringScheme({ required: true }),
        }),
      ) as Resolver<IUserRoles>,
    });

    const role = watch('role');

    const onSubmit = () => {
      changeUserRole({ userId: userId as number, role });
    };

    useServerError({ isError: isErrorUserRoles, error: errorUserRoles });
    useServerError({
      isError: isErrorChangeUserRole,
      error: errorChangeUserRole,
    });

    useEffect(() => {
      if (isSuccessChangeUserRole) {
        onClose();
        showNotification(
          `User role for user ID ${userId} was changed!`,
          'success',
        );
      }
    }, [isSuccessChangeUserRole]);

    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <CardContent sx={{ overflow: 'auto' }}>
          <CardHeader sx={{ textAlign: 'center' }} title="User Role" />
          <Divider />
          <Typography m={1} py={2} variant="h5">
            Changing user role for{' '}
            <b style={{ wordBreak: 'break-word' }}>{userName}</b>
          </Typography>
          {isFetchingUserRoles ? (
            <Loader pad={1} />
          ) : (
            <Box
              sx={{
                minWidth: 120,
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
              component="form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Controller
                control={control}
                name="role"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-helper-label">
                      Role
                    </InputLabel>
                    <Select
                      disabled={isFetchingUserRoles}
                      value={value}
                      onChange={onChange}
                      error={!!error?.message}
                      label="Role"
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      fullWidth
                    >
                      {userRolesData?.availableRoles?.map((item) => {
                        return (
                          <MenuItem key={item} value={item}>
                            <em>{item}</em>
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {error && (
                      <FormHelperText error={!!error.message}>
                        {error.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Box>
          )}
          <Button sx={{ mt: 3, ml: 1 }} onClick={onClose} variant="outlined">
            Close
          </Button>
          <LoadingButton
            endIcon={<SaveIcon />}
            loadingPosition="end"
            disabled={userRole === role}
            loading={isLoadingChangeUserRole}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            sx={{ mt: 3, ml: 1 }}
            type="submit"
          >
            Save
          </LoadingButton>
        </CardContent>
      </Dialog>
    );
  },
);
