import React, { FC, memo, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  CardContent,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Switch,
  TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { object } from 'yup';
import { useNotification, useServerError } from '@/hooks';
import {
  useCreateCompanyPdfMutation,
  useDeleteCompanyImageMutation,
  useGetCompanyImageQuery,
  useUpdateCompanyPdfMutation,
  useUploadCompanyImageMutation,
} from '../../../services';
import {
  ICompanySchema,
  ICompanySlice,
  IMAGE_TYPE,
} from '../../../types/company.interface';
import {
  booleanScheme,
  emailScheme,
  numberScheme,
  stringScheme,
} from '../../../utils';
import { ImageSection } from './image.section';

type CompanyModalProps = ICompanySlice & {
  open: boolean;
  onClose: () => void;
};

export const CompanyModal: FC<CompanyModalProps> = memo(
  ({
    open,
    onClose,
    id,
    companyName,
    companyTag,
    companyCode,
    addressLine1,
    addressLine2,
    addressLine3,
    addressLine4,
    officerName,
    officerMail,
    officerPosition,
    isDefault,
    isFuze,
    accountStatementFooter,
  }) => {
    const { showNotification } = useNotification();

    const {
      data: imageStampData,
      isFetching: isFetchingImageStampData,
      isError: isErrorImageStampData,
    } = useGetCompanyImageQuery(
      { id: id as number, type: IMAGE_TYPE.STAMP },
      { skip: !id },
    );

    const {
      data: imageSignatureData,
      isFetching: isFetchingImageSignatureData,
      isError: isErrorImageSignatureData,
    } = useGetCompanyImageQuery(
      {
        id: id as number,
        type: IMAGE_TYPE.SIGNATURE,
      },
      { skip: !id },
    );

    const [
      createCompanyPdf,
      {
        isLoading: isLoadingCreateCompanyPdf,
        isError: isErrorCreateCompanyPdf,
        error: errorCreateCompanyPdf,
        isSuccess: isSuccessCreateCompanyPdf,
      },
    ] = useCreateCompanyPdfMutation();

    const [
      updateCompanyPdf,
      {
        isLoading: isLoadingUpdateCompanyPdf,
        isError: isErrorUpdateCompanyPdf,
        error: errorUpdateCompanyPdf,
        isSuccess: isSuccessUpdateCompanyPdf,
      },
    ] = useUpdateCompanyPdfMutation();

    const [
      uploadCompanyImage,
      {
        isLoading: isLoadingUploadCompanyImage,
        isError: isErrorUploadCompanyImage,
        error: errorUploadCompanyImage,
        isSuccess: isSuccessUploadCompanyImage,
      },
    ] = useUploadCompanyImageMutation();

    const [
      deleteCompanyImage,
      {
        isLoading: isLoadingDeleteCompanyImage,
        isError: isErrorDeleteCompanyImage,
        error: errorDeleteCompanyImage,
        isSuccess: isSuccessDeleteCompanyImage,
      },
    ] = useDeleteCompanyImageMutation();

    const {
      handleSubmit,
      control,
      watch,
      formState: { isDirty },
    } = useForm<ICompanySchema>({
      defaultValues: {
        'Company name': companyName,
        'Company tag': companyTag,
        'Company code': companyCode,
        'Address line 1': addressLine1,
        'Address line 2': addressLine2,
        'Address line 3': addressLine3,
        'Address line 4': addressLine4,
        'Officer name': officerName,
        'Officer mail': officerMail,
        'Officer position': officerPosition,
        'Is default': isDefault,
        'Is Fuze': isFuze,
        'Account statement footer': accountStatementFooter,
      },
      resolver: yupResolver(
        object({
          'Company name': stringScheme({ required: true }),
          'Company tag': stringScheme({ required: false }).nullable(),
          'Company code': numberScheme({ required: false }).nullable(),
          'Address line 1': stringScheme({ required: true }),
          'Address line 2': stringScheme({ required: true }),
          'Address line 3': stringScheme({ required: true }),
          'Address line 4': stringScheme({ required: true }),
          'Officer name': stringScheme({ required: true }),
          'Officer mail': emailScheme({ required: true }),
          'Officer position': stringScheme({ required: true }),
          'Account statement footer': stringScheme({ required: true }),
          'Is default': booleanScheme({ required: false }),
        }),
      ) as Resolver<any>,
    });

    const isDefaultWatch = watch('Is default');
    const isFuzeWatch = watch('Is Fuze');

    const onSubmit = (data: ICompanySchema) => {
      if (id) {
        updateCompanyPdf({
          companyName: data['Company name'],
          companyTag: data['Company tag'],
          companyCode: data['Company code'] as number,
          addressLine1: data['Address line 1'],
          addressLine2: data['Address line 2'],
          addressLine3: data['Address line 3'],
          addressLine4: data['Address line 4'],
          officerName: data['Officer name'],
          officerMail: data['Officer mail'],
          officerPosition: data['Officer position'],
          isDefault: data['Is default'],
          isFuze: data['Is Fuze'],
          accountStatementFooter: data['Account statement footer'],
          id,
        });
      } else {
        createCompanyPdf({
          companyName: data['Company name'],
          companyTag: data['Company tag'],
          companyCode: data['Company code'] as number,
          addressLine1: data['Address line 1'],
          addressLine2: data['Address line 2'],
          addressLine3: data['Address line 3'],
          addressLine4: data['Address line 4'],
          officerName: data['Officer name'],
          officerMail: data['Officer mail'],
          officerPosition: data['Officer position'],
          isDefault: data['Is default'],
          isFuze: data['Is Fuze'],
          accountStatementFooter: data['Account statement footer'],
        });
      }
    };

    const handleUploadImage = (image: string | Blob, type: string) => {
      if (image) {
        const formData = new FormData();
        formData.append('image', image);
        uploadCompanyImage({
          id: id as number,
          type: type,
          body: formData,
        });
      }
    };

    const handleDeleteImage = (type: string) => {
      deleteCompanyImage({
        id: id as number,
        type: type,
      });
    };

    useServerError({
      isError: isErrorCreateCompanyPdf,
      error: errorCreateCompanyPdf,
    });
    useServerError({
      isError: isErrorUpdateCompanyPdf,
      error: errorUpdateCompanyPdf,
    });
    useServerError({
      isError: isErrorUploadCompanyImage,
      error: errorUploadCompanyImage,
    });
    useServerError({
      isError: isErrorDeleteCompanyImage,
      error: errorDeleteCompanyImage,
    });

    useEffect(() => {
      if (isSuccessCreateCompanyPdf) {
        onClose();
        showNotification('Company was created!', 'success');
      }
      if (isSuccessUpdateCompanyPdf) {
        onClose();
        showNotification('Company was updated!', 'success');
      }
    }, [isSuccessCreateCompanyPdf, isSuccessUpdateCompanyPdf]);

    useEffect(() => {
      if (isSuccessUploadCompanyImage) {
        showNotification('Image was added!', 'success');
      }
      if (isSuccessDeleteCompanyImage) {
        showNotification('Image was deleted!', 'success');
      }
    }, [isSuccessUploadCompanyImage, isSuccessDeleteCompanyImage]);

    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <CardContent sx={{ overflow: 'auto' }}>
          <CardHeader
            sx={{ textAlign: 'center' }}
            title={id ? 'Company update' : 'Company create'}
          />
          <Divider />
          <Typography m={1} py={2} variant="h5">
            {id && (
              <Typography variant="h6">
                Update company <b>{companyName}</b>
              </Typography>
            )}
          </Typography>
          <Box
            sx={{
              minWidth: 120,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="Company name"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="string"
                    required
                    onChange={onChange}
                    value={value}
                    fullWidth
                    id="companyName"
                    label="Company name"
                    name="companyName"
                    autoComplete="companyName"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="Company tag"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="string"
                    onChange={onChange}
                    value={value}
                    fullWidth
                    id="companyTag"
                    label="Company tag"
                    name="companyTag"
                    autoComplete="companyTag"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="Company code"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="number"
                    onChange={onChange}
                    value={value}
                    fullWidth
                    id="companyCode"
                    label="Company code"
                    name="companyCode"
                    autoComplete="companyCode"
                    inputProps={{ min: 0 }}
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="Address line 1"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="string"
                    required
                    onChange={onChange}
                    value={value}
                    fullWidth
                    id="addressLine1"
                    label="Address line 1"
                    name="addressLine1"
                    autoComplete="addressLine1"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="Address line 2"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="string"
                    required
                    onChange={onChange}
                    value={value}
                    fullWidth
                    id="addressLine2"
                    label="Address line 2"
                    name="addressLine2"
                    autoComplete="addressLine2"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="Address line 3"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="string"
                    required
                    onChange={onChange}
                    value={value}
                    fullWidth
                    id="addressLine3"
                    label="Address line 3"
                    name="addressLine3"
                    autoComplete="addressLine3"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="Address line 4"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="string"
                    required
                    onChange={onChange}
                    value={value}
                    fullWidth
                    id="ordering"
                    label="Address line 4"
                    name="ordering"
                    autoComplete="ordering"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="Officer name"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="string"
                    required
                    onChange={onChange}
                    value={value}
                    fullWidth
                    id="officerName"
                    label="Officer name"
                    name="officerName"
                    autoComplete="officerName"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="Officer mail"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="string"
                    required
                    onChange={onChange}
                    value={value}
                    fullWidth
                    id="officerMail"
                    label="Officer mail"
                    name="officerMail"
                    autoComplete="officerMail"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="Officer position"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="string"
                    required
                    onChange={onChange}
                    value={value}
                    fullWidth
                    id="officerPosition"
                    label="Officer position"
                    name="officerPosition"
                    autoComplete="officerPosition"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="Account statement footer"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="string"
                    required
                    onChange={onChange}
                    value={value}
                    fullWidth
                    multiline
                    id="accountStatementFooter"
                    label="Account statement footer"
                    name="accountStatementFooter"
                    autoComplete="accountStatementFooter"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="Is default"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl sx={{ mt: 2 }}>
                  <InputLabel id="demo-simple-select-helper-label" shrink>
                    Is default
                  </InputLabel>

                  <FormControlLabel
                    onChange={onChange}
                    control={<Switch defaultChecked checked={value} />}
                    label={`${isDefaultWatch}`}
                  />
                  {error && (
                    <FormHelperText error={!!error.message}>
                      {error.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="Is Fuze"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl sx={{ mt: 2 }}>
                  <InputLabel id="demo-simple-select-helper-label" shrink>
                    Is Fuze
                  </InputLabel>

                  <FormControlLabel
                    onChange={onChange}
                    control={<Switch defaultChecked checked={value} />}
                    label={`${isFuzeWatch}`}
                  />
                  {error && (
                    <FormHelperText error={!!error.message}>
                      {error.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Box>
          {id && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '20px',
                alignItems: 'center',
                marginTop: '20px',
              }}
            >
              <ImageSection
                isLoading={
                  isLoadingUploadCompanyImage || isLoadingDeleteCompanyImage
                }
                image={imageSignatureData}
                isError={isErrorImageSignatureData}
                handleUpload={handleUploadImage}
                imageType={IMAGE_TYPE.SIGNATURE}
                handleDelete={handleDeleteImage}
                title="Upload signature"
                isFetchingImage={isFetchingImageSignatureData}
              />
              <ImageSection
                isLoading={
                  isLoadingUploadCompanyImage || isLoadingDeleteCompanyImage
                }
                image={imageStampData}
                isError={isErrorImageStampData}
                handleUpload={handleUploadImage}
                imageType={IMAGE_TYPE.STAMP}
                handleDelete={handleDeleteImage}
                title="Upload stamp"
                isFetchingImage={isFetchingImageStampData}
              />
            </Box>
          )}
          <Button sx={{ mt: 3, ml: 1 }} onClick={onClose} variant="outlined">
            Close
          </Button>
          <LoadingButton
            endIcon={<SaveIcon />}
            loadingPosition="end"
            variant="contained"
            disabled={!isDirty}
            loading={isLoadingCreateCompanyPdf || isLoadingUpdateCompanyPdf}
            onClick={handleSubmit(onSubmit)}
            sx={{ mt: 3, ml: 1 }}
            type="submit"
          >
            {id ? 'Update' : 'Create'}
          </LoadingButton>
        </CardContent>
      </Dialog>
    );
  },
);
