import React, { FC, useEffect, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography,
  Collapse,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/system';
import { theme } from '../../../../assets/theme';
import { transformDate } from '../../../../helpers/dateTransformHelper';
import { useServerError } from '../../../../hooks';
import { useGetUserPdfQuery } from '../../../../services';
import { NoItemsWrapper } from '../../../../shared';
import { IUserPdf, UsersInterface } from '../../../../types/admin.interface';
import { DateFormatEnum } from '../../../../types/dateTimeFormatOption';

const StyledTableHead = styled(TableHead)(({ theme: styledTheme }) => ({
  height: 40,
  '& .MuiTableCell-root': {
    backgroundColor: styledTheme.palette.grey[200],
  },
}));

type UserPdfProps = {
  row: UsersInterface;
  open: boolean;
};

export const UserPdf: FC<UserPdfProps> = ({ row, open }) => {
  const [pdfKey, setPdfKey] = useState<null | string>(null);

  const {
    isError: isErrorDataPdf,
    error: errorDataPdf,
    isSuccess,
  } = useGetUserPdfQuery(
    { key: pdfKey as string },
    { skip: !pdfKey, refetchOnMountOrArgChange: true },
  );

  const handleSetPdfKey = (key: string) => {
    setPdfKey(key);
  };

  useServerError({ isError: isErrorDataPdf, error: errorDataPdf });

  useEffect(() => {
    if (isSuccess) {
      setPdfKey(null);
    }
  }, [isSuccess]);

  return (
    <>
      {open && (
        <TableRow sx={{ backgroundColor: theme.palette.grey[100] }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={16}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  User PDF list
                </Typography>
              </Box>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="a dense table">
                  <StyledTableHead>
                    <TableRow>
                      <TableCell padding="none" align="center">
                        Created at
                      </TableCell>
                      <TableCell padding="none" align="center">
                        Path
                      </TableCell>
                    </TableRow>
                  </StyledTableHead>
                  <TableBody>
                    <NoItemsWrapper
                      isTableComponent
                      colSpan={9}
                      length={row?.PdfArchive?.length as number}
                      text={'There is no PDF'}
                    >
                      {row?.PdfArchive?.map((pdf: IUserPdf) => {
                        return (
                          <TableRow
                            key={pdf?.id}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell align="center">
                              {transformDate(
                                pdf?.createdAt,
                                DateFormatEnum.DATE_WITH_TIME_WITH_DOT,
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                aria-label="download"
                                size="small"
                                title="Download"
                                onClick={() => handleSetPdfKey(pdf.path)}
                              >
                                <DownloadIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </NoItemsWrapper>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
