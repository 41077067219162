export interface ICompanySlice {
  id: number | null;
  companyName: string;
  companyTag: string;
  companyCode: number | null;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  officerName: string;
  officerMail: string;
  officerPosition: string;
  isDefault: boolean;
  isFuze: boolean;
  accountStatementFooter: string;
}
export interface ICompanySchema {
  'Company name': string;
  'Company tag': string;
  'Company code': number | null;
  'Address line 1': string;
  'Address line 2': string;
  'Address line 3': string;
  'Address line 4': string;
  'Officer name': string;
  'Officer mail': string;
  'Officer position': string;
  'Is default': boolean;
  'Is Fuze': boolean;
  'Account statement footer': string;
}

export interface ICompanyPdf {
  accountStatementFooter: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  companyCode: number;
  companyName: string;
  companyTag: string;
  createdAt: string;
  deletedAt: string;
  id: number;
  isDefault: boolean;
  isFuze: boolean;
  officerMail: string;
  officerName: string;
  officerPosition: string;
  updatedAt: string;
}

export enum COMPANY_ENUM {
  officerName = 'Officer name',
  officerMail = 'Officer mail',
  officerPosition = 'Officer position',
  companyCode = 'Company code',
  addressLine1 = 'Address 1',
  addressLine2 = 'Address 2',
  addressLine3 = 'Address 3',
  addressLine4 = 'Address 4',
  isDefault = 'Is default',
  isFuze = 'Is Fuze',
  accountStatementFooter = 'Account statement footer',
}

export enum IMAGE_TYPE {
  SIGNATURE = 'signature',
  STAMP = 'stamp',
}
