import React, { useEffect } from 'react';
import { Stack, useTheme } from '@mui/material';
import {
  resetDocument,
  useInspectorDrawerOpen,
} from '../documents/editor/EditorContext';
import InspectorDrawer, { INSPECTOR_DRAWER_WIDTH } from './InspectorDrawer';
import TemplatePanel from './TemplatePanel';
import validateJsonStringValue from '@/components/email_editor/EmailRedactor/TemplatePanel/ImportJson/validateJsonStringValue';
import EMPTY_EMAIL_MESSAGE from '@/components/email_editor/getConfiguration/sample/empty-email-message';

function useDrawerTransition(
  cssProperty: 'margin-left' | 'margin-right',
  open: boolean,
) {
  const { transitions } = useTheme();
  return transitions.create(cssProperty, {
    easing: !open ? transitions.easing.sharp : transitions.easing.easeOut,
    duration: !open
      ? transitions.duration.leavingScreen
      : transitions.duration.enteringScreen,
  });
}

export default function EmailRedactor({ config }: { config: string }) {
  const inspectorDrawerOpen = useInspectorDrawerOpen();

  const marginRightTransition = useDrawerTransition(
    'margin-right',
    inspectorDrawerOpen,
  );

  useEffect(() => {
    if (config) {
      const { data } = validateJsonStringValue(config);

      if (data) {
        resetDocument(data);
      }
    }

    return () => {
      resetDocument(EMPTY_EMAIL_MESSAGE);
    };
  }, [config]);

  return (
    <>
      <InspectorDrawer />
      <Stack
        // width="100%"
        sx={{
          marginRight: inspectorDrawerOpen ? `${INSPECTOR_DRAWER_WIDTH}px` : 0,
          marginLeft: 0,
          transition: [marginRightTransition].join(', '),
          border: '1px solid rgba(0, 0, 0, 0.23)',
          borderRadius: '4px',
        }}
      >
        <TemplatePanel />
      </Stack>
    </>
  );
}
