import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from '@/utils';

export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders,
    // mode: 'no-cors'
  }),
  tagTypes: [
    'User',
    'AdminUsers',
    'Withdraw',
    'UserIbans',
    'UserExternalWallets',
    'Kyc',
    'Review',
    'Balance',
    'AdminSettings',
    'Transactions',
    'AccountConfirmations',
    'System Balance',
    'GasStation',
    'Tier',
    'Fee',
    'FiatLimit',
    'ReceiveFiat',
    'CryptoCurrencies',
    'Stamp',
    'CompanyPdf',
    'CompanyPdfStamp',
    'CompanyPdfSignature',
    'BankAccount',
    'Banner',
    'EmailTemplatesList',
    'EmailTemplate',
    'SystemLogs',
    'UserFeedbacks',
    'FuzeDepositAddresses',
    'Countries',
    'FireblocksNameList',
    'B2C2NameList',
    'FuzeNameList',
  ],
  endpoints: () => ({}),
});
