import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';
import { Skeleton } from '@mui/lab';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { array, object, string } from 'yup';
import { useNotification, useServerError } from '@/hooks';
import {
  useCreateEmailTemplateMutation,
  useGetEmailTemplateKeysQuery,
  useGetEmailTemplateTypesQuery,
  useUpdateEmailTemplateMutation,
} from '@/services';
import {
  EMAIL_TYPE,
  IEmailTemplate,
  IEmailTemplateSchema,
} from '@/types/email.template.interface';
import { booleanScheme, emailScheme, stringScheme } from '@/utils';
import { EmailEditor } from '@/components/email_editor';
import { useDocument } from '@/components/email_editor/documents/editor/EditorContext';
import { renderToStaticMarkup } from '@usewaypoint/email-builder';
import { useNavigate } from 'react-router-dom';
import { PATH_ADMIN } from '@/constants/spa-routes';
import DoneIcon from '@mui/icons-material/Done';

const DEFAULT_VALUES = {
  subject: '',
  type: '',
  active: false,
  isFuze: false,
  sendCopyTo: [],
  sendCopyToField: '',
};

const TIMEOUT_INTERVAL = 2000;

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

type EmailTemplateModalProps = {
  id: string | undefined;
  data: IEmailTemplate | undefined;
};

interface ChipData {
  key: string;
  label: string;
}

export const EmailTemplateForm: FC<EmailTemplateModalProps> = memo(
  ({ id, data }) => {
    const { showNotification } = useNotification();

    const navigate = useNavigate();

    const [chipData, setChipData] = useState<ChipData[]>([]);
    const [showTooltip, setShowTooltip] = useState<string | null>(null);

    const {
      data: emailTemplatesTypes,
      isFetching: isFetchingEmailTemplatesTypes,
    } = useGetEmailTemplateTypesQuery({});

    const {
      data: emailTemplatesKeys,
      isFetching: isFetchingEmailTemplatesKeys,
    } = useGetEmailTemplateKeysQuery({});

    const [
      createEmailTemplate,
      {
        isLoading: isLoadingCreateEmailTemplate,
        isError: isErrorCreateEmailTemplate,
        error: errorCreateEmailTemplate,
        isSuccess: isSuccessCreateEmailTemplate,
      },
    ] = useCreateEmailTemplateMutation();

    const [
      updateEmailTemplate,
      {
        isLoading: isLoadingUpdateEmailTemplate,
        isError: isErrorUpdateEmailTemplate,
        error: errorUpdateEmailTemplate,
        isSuccess: isSuccessUpdateEmailTemplate,
      },
    ] = useUpdateEmailTemplateMutation();

    const {
      handleSubmit,
      control,
      watch,
      setValue,
      clearErrors,
      setError,
      reset,
      formState: { isDirty },
    } = useForm<IEmailTemplateSchema>({
      defaultValues: DEFAULT_VALUES,
      resolver: yupResolver(
        object({
          subject: stringScheme({ required: true }).nullable().label('Subject'),
          type: stringScheme({ required: true }).label('Type'),
          sendCopyTo: array().of(string().trim()).label('Send copy to'),
          sendCopyToField: stringScheme(),
          active: booleanScheme({ required: false }).label('Active'),
          isFuze: booleanScheme({ required: false }).label('Is Fuze'),
        }),
      ) as Resolver<IEmailTemplateSchema>,
    });

    const documentConfig = useDocument();

    useEffect(() => {
      if (data) {
        reset({
          subject: data.subject,
          type: data.type,
          active: data.active,
          isFuze: data.isFuze,
          sendCopyTo: data.sendCopyTo ? data.sendCopyTo.split(', ') : [],
        });
      }
    }, [data]);

    const sendCopyToWatch = watch('sendCopyTo');
    const sendCopyToFieldWatch = watch('sendCopyToField');

    const textFieldEmailRef = useRef<HTMLInputElement | null>(null);

    const handleSelectChipEmail = (chipToDelete: ChipData) => () => {
      const updatedSendCopyTo = sendCopyToWatch.filter(
        (email) => email !== chipToDelete.label,
      );
      setValue('sendCopyTo', updatedSendCopyTo, { shouldDirty: true });
    };

    const emailValidationSchema = emailScheme();

    const addEmailToChips = () => {
      const newEmail = textFieldEmailRef.current?.value.trim();
      if (newEmail) {
        if (!sendCopyToWatch.includes(newEmail)) {
          const updatedSendCopyTo = [...sendCopyToWatch, newEmail];
          setValue('sendCopyTo', updatedSendCopyTo, { shouldDirty: true });
          setValue('sendCopyToField', '');
          clearErrors('sendCopyToField');
        } else {
          setError('sendCopyToField', { message: 'Email must be unique' });
        }
      }
    };

    useEffect(() => {
      if (sendCopyToFieldWatch) {
        try {
          emailValidationSchema.validateSync(sendCopyToFieldWatch);
          clearErrors('sendCopyToField');
        } catch (error) {
          setError('sendCopyToField', { message: 'Email must be valid' });
        }
      }
    }, [sendCopyToFieldWatch]);

    const activeWatch = watch('active');
    const typeWatch = watch('type');

    const handleSelectChip = (chipToDelete: ChipData) => () => {
      navigator.clipboard.writeText(chipToDelete.label);

      setShowTooltip(chipToDelete.key);

      setTimeout(() => {
        setShowTooltip(null);
      }, TIMEOUT_INTERVAL);
    };

    const onSubmit = (formData: IEmailTemplateSchema) => {
      const sendTo = formData.sendCopyTo.join(', ');
      const htmlConfig = JSON.stringify(documentConfig);
      const html = renderToStaticMarkup(documentConfig, {
        rootBlockId: 'root',
      });

      if (id) {
        updateEmailTemplate({
          subject: formData.subject,
          type: formData.type,
          active: formData.active,
          isFuze: formData.isFuze,
          html: html,
          sendCopyTo: sendTo,
          htmlEditorConfig: htmlConfig,
          id: Number(id),
        });
      } else {
        createEmailTemplate({
          subject: formData.subject,
          type: formData.type,
          active: formData.active,
          isFuze: formData.isFuze,
          html: html,
          sendCopyTo: sendTo,
          htmlEditorConfig: htmlConfig,
        });
      }
    };

    useServerError({
      isError: isErrorCreateEmailTemplate,
      error: errorCreateEmailTemplate,
    });
    useServerError({
      isError: isErrorUpdateEmailTemplate,
      error: errorUpdateEmailTemplate,
    });

    useEffect(() => {
      if (isSuccessCreateEmailTemplate) {
        navigate(PATH_ADMIN.EMAIL_TEMPLATE);
        showNotification('Email template was created!', 'success');
      }
      if (isSuccessUpdateEmailTemplate) {
        navigate(PATH_ADMIN.EMAIL_TEMPLATE);
        showNotification('Email template was updated!', 'success');
      }
    }, [isSuccessCreateEmailTemplate, isSuccessUpdateEmailTemplate]);

    const clonedEmailTemplatesKeys = { ...emailTemplatesKeys };

    delete clonedEmailTemplatesKeys.APPLICANT_ID;

    useEffect(() => {
      if (emailTemplatesKeys) {
        if (typeWatch === EMAIL_TYPE.SUCCESSFULL_REGISTRATION_EMAIL) {
          const keysData = Object.keys(clonedEmailTemplatesKeys).map(
            (key: string) => {
              return {
                key: key,
                label:
                  emailTemplatesKeys[key as keyof typeof emailTemplatesKeys],
              };
            },
          );
          setChipData(keysData);
        } else {
          const keysData = Object.keys(emailTemplatesKeys).map(
            (key: string) => {
              return {
                key: key,
                label:
                  emailTemplatesKeys[key as keyof typeof emailTemplatesKeys],
              };
            },
          );
          setChipData(keysData);
        }
      }
    }, [emailTemplatesKeys, typeWatch]);

    const disableButton =
      !isDirty &&
      JSON.stringify(documentConfig) ===
        (data?.htmlEditorConfig as unknown as string);

    return (
      <>
        <Box
          sx={{
            minWidth: 120,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {isFetchingEmailTemplatesKeys ? (
            <Skeleton
              sx={{ maxWidth: '450px', width: '100%', height: '100px' }}
            />
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                listStyle: 'none',
                p: 0.5,
                m: 0,
                mt: 3,
              }}
              component="ul"
            >
              {chipData?.map((chip) => {
                return (
                  <ListItem key={chip.key}>
                    <Tooltip
                      title="Copied"
                      open={chip.key === showTooltip}
                      placement="top"
                      arrow
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'offset',
                              options: {
                                offset: [0, -8],
                              },
                            },
                          ],
                        },
                      }}
                    >
                      <Chip
                        label={chip.label}
                        onClick={handleSelectChip(chip)}
                        icon={
                          JSON.stringify(documentConfig)?.includes(
                            chip.label,
                          ) ? (
                            <DoneIcon color="success" fontSize="small" />
                          ) : (
                            <></>
                          )
                        }
                      />
                    </Tooltip>
                  </ListItem>
                );
              })}
            </Box>
          )}
          <Box
            mt={2}
            width="100%"
            position="relative"
            sx={{ overflow: 'hidden', textAlign: 'left' }}
          >
            <EmailEditor config={data?.htmlEditorConfig || ''} />
          </Box>
          <Box
            sx={{
              minWidth: 120,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="sendCopyToField"
              render={({ field: { value }, fieldState: { error } }) => (
                <FormControl fullWidth>
                  <TextField
                    inputRef={textFieldEmailRef}
                    margin="normal"
                    type="text"
                    fullWidth
                    error={!!error?.message}
                    helperText={error?.message}
                    onChange={(e) =>
                      setValue('sendCopyToField', e.target.value, {
                        shouldDirty: false,
                      })
                    }
                    value={value}
                    multiline
                    id="sendCopyTo"
                    label="Send copy to"
                    name="sendCopyTo"
                    autoComplete="off"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            disabled={!!error?.message || value === ''}
                            onClick={addEmailToChips}
                          >
                            <SendIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              )}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                listStyle: 'none',
                p: 0.5,
                m: 0,
              }}
              component="ul"
            >
              {sendCopyToWatch?.map((email, index) => (
                <ListItem key={index}>
                  <Chip
                    label={email}
                    onDelete={handleSelectChipEmail({
                      key: index.toString(),
                      label: email,
                    })}
                  />
                </ListItem>
              ))}
            </Box>
            <Controller
              control={control}
              name="subject"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="string"
                    onChange={onChange}
                    value={value}
                    fullWidth
                    required
                    id="subject"
                    label="Subject"
                    name="subject"
                    autoComplete="off"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="type"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <Autocomplete
                    sx={{ m: '16px 0 8px 0' }}
                    disablePortal
                    id="combo-box-demo"
                    onChange={(event, val) => onChange(val)}
                    value={value}
                    disabled={isFetchingEmailTemplatesTypes}
                    loading={isFetchingEmailTemplatesTypes}
                    options={
                      emailTemplatesTypes
                        ? Object.values(emailTemplatesTypes)
                        : []
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Type"
                        required
                        error={!!error?.message}
                        helperText={error?.message}
                      />
                    )}
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="active"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl sx={{ mt: 2 }}>
                  <InputLabel id="demo-simple-select-helper-label" shrink>
                    Active
                  </InputLabel>

                  <FormControlLabel
                    onChange={onChange}
                    control={<Switch defaultChecked checked={value} />}
                    label={`${activeWatch}`}
                  />
                  {error && (
                    <FormHelperText error={!!error.message}>
                      {error.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="isFuze"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl sx={{ mt: 2 }}>
                  <InputLabel id="demo-simple-select-helper-label" shrink>
                    Is Fuze
                  </InputLabel>

                  <FormControlLabel
                    onChange={onChange}
                    control={<Switch defaultChecked checked={value} />}
                    label={`${activeWatch}`}
                  />
                  {error && (
                    <FormHelperText error={!!error.message}>
                      {error.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
            <LoadingButton
              endIcon={<SaveIcon />}
              loadingPosition="end"
              variant="contained"
              loading={
                isLoadingCreateEmailTemplate || isLoadingUpdateEmailTemplate
              }
              disabled={disableButton}
              sx={{ mt: 3, ml: 1 }}
              type="submit"
            >
              {id ? 'Update' : 'Create'}
            </LoadingButton>
          </Box>
        </Box>
      </>
    );
  },
);
