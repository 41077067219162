import React, { ChangeEvent, FC, MouseEvent, useEffect, useState } from 'react';
import {
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { spacing } from '@mui/system';
import { styled } from '@mui/system';
import { useDispatch } from 'react-redux';
import { useNotification, useServerError } from '../../../../hooks';
import { useDialog } from '../../../../hooks/useDialog';
import {
  useActivateUserMutation,
  useDeactivateUserMutation,
  useGetUserQuery,
  useVerifyAddressMutation,
} from '../../../../services';
import { NoItemsWrapper } from '../../../../shared';
import { setModalState, setRolesState } from '../../../../store/ui';
import {
  UserRolesEnum,
  UsersInterface,
} from '../../../../types/admin.interface';
import { UserRow } from './users.row';

type HeadCell = {
  id: string;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
};

type UsersTableProps = {
  users: UsersInterface[];
  count: number;
  rowsPerPage: number;
  isLoadingUsers: boolean;
  page: number;
  onPageChange: (e: MouseEvent<HTMLButtonElement> | null, page: number) => void;
  onRowsPerPageChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
};

const Paper = styled(MuiPaper)(spacing);

const StyledTableHead = styled(TableHead)(({ theme: styledTheme }) => ({
  height: 55,
  '& .MuiTableCell-root': {
    backgroundColor: styledTheme.palette.grey[200],
  },
}));

const headCells: Array<HeadCell> = [
  {
    id: '#',
    numeric: true,
    disablePadding: true,
    label: '#',
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: true,
    label: 'Name',
  },
  { id: 'last_name', numeric: true, disablePadding: false, label: 'Last Name' },
  {
    id: 'accountNumber',
    numeric: true,
    disablePadding: false,
    label: 'Account number',
  },
  {
    id: 'kycAplicant',
    numeric: true,
    disablePadding: false,
    label: 'KYC|KYB Aplicant',
  },
  { id: 'email', numeric: true, disablePadding: false, label: 'Email' },
  {
    id: 'email_verified',
    numeric: true,
    disablePadding: false,
    label: 'Email verified',
  },
  {
    id: 'phone_verified',
    numeric: true,
    disablePadding: false,
    label: 'Phone verified',
  },
  {
    id: 'kyc_verified',
    numeric: true,
    disablePadding: false,
    label: 'KYC|KYB verified',
  },
  {
    id: 'address_verified',
    numeric: true,
    disablePadding: false,
    label: 'Address verified',
  },
  { id: 'role', numeric: true, disablePadding: false, label: 'Role' },
  { id: 'tier', numeric: true, disablePadding: false, label: 'Tier name' },
  {
    id: 'address_btn',
    numeric: true,
    disablePadding: false,
    label: 'Verify Address',
  },
  {
    id: 'button',
    numeric: true,
    disablePadding: false,
    label: 'Deactivate / Delete',
  },
  {
    id: 'button-role',
    numeric: true,
    disablePadding: false,
    label: 'Role edit',
  },
  {
    id: 'button-role',
    numeric: true,
    disablePadding: false,
    label: 'Tier edit',
  },
];

const EnhancedTableHead: FC = () => {
  return (
    <StyledTableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ padding: '6px 16px' }}
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
};

export const TransactionsUsersTable: FC<UsersTableProps> = ({
  users,
  page,
  rowsPerPage,
  count,
  onPageChange,
  onRowsPerPageChange,
  isLoadingUsers,
}) => {
  const [userHasTransactionState, setUserHasTransaction] =
    useState<boolean>(false);
  const { showNotification } = useNotification();

  const [handleOpenDialog, DialogComponent] = useDialog();

  const dispatch = useDispatch();

  const { data: userData } = useGetUserQuery();

  const [
    deactivateUser,
    {
      isLoading: isLoadingDeactivate,
      isError: isErrorDeactivateUser,
      error: errorDeactivateUser,
      isSuccess: isSuccessDeactivateUser,
    },
  ] = useDeactivateUserMutation();
  const [
    activateUser,
    {
      isLoading: isLoadingActivate,
      isError: isErrorActivateUser,
      error: errorActivateUser,
      isSuccess: isSuccessActivateUser,
    },
  ] = useActivateUserMutation();
  const [
    verifyAddress,
    {
      isLoading: isLoadingVerifyAddress,
      isError: isErrorVerifyAddress,
      error: errorVerifyAddress,
      isSuccess: isSuccessVerifyAddress,
    },
  ] = useVerifyAddressMutation();

  const mediaTheme = useTheme();
  const matches = useMediaQuery(mediaTheme.breakpoints.up('sm'));

  const handleDelete = (id: number, isDeleted: boolean) => {
    if (id && !isDeleted) {
      deactivateUser({ id: id });
    } else {
      activateUser({ id: id as number });
    }
  };

  const handleOpenDeleteModal = ({
    id,
    isDeleted,
    hasTransactions,
    userName,
  }: {
    id: number;
    isDeleted: boolean;
    hasTransactions: boolean;
    userName: string;
  }) => {
    setUserHasTransaction(hasTransactions);
    handleOpenDialog({
      title: hasTransactions
        ? isDeleted
          ? 'Restore'
          : 'Deactivate'
        : 'Delete',
      description: (
        <>
          {hasTransactions
            ? isDeleted
              ? 'Are you sure you want to restore '
              : `Are you sure you want to deactivate `
            : `Are you sure you want to delete user `}
          <b style={{ wordBreak: 'break-word' }}>{userName}?</b>
        </>
      ),
      applyBtnTitle: hasTransactions
        ? isDeleted
          ? 'Restore'
          : 'Deactivate'
        : 'Delete',
      applyButtonColor: isDeleted ? 'success' : 'error',
      buttonApplyHandler: () => handleDelete(id, isDeleted),
      loadingDelete: isLoadingDeactivate || isLoadingActivate,
    });
  };

  const handleOpenAddressModal = ({
    id,
    userName,
  }: {
    id: number;
    userName: string;
  }) => {
    handleOpenDialog({
      title: 'Verify address?',
      description: (
        <>
          Are you sure you want to verify address for user{' '}
          <b style={{ wordBreak: 'break-word' }}>{userName}?</b>
        </>
      ),
      applyBtnTitle: 'Verify',
      applyButtonColor: 'success',
      buttonApplyHandler: () => verifyAddress({ id }),
      loadingDelete: isLoadingVerifyAddress,
    });
  };

  const handleOpenEditModal = ({
    id,
    userRole,
    userName,
  }: {
    id: number;
    userRole: string;
    userName: string;
  }) => {
    dispatch(
      setModalState({
        visible: true,
        name: 'changeRole',
      }),
    );
    dispatch(
      setRolesState({
        userId: id as number,
        userRole,
        userName: userName,
      }),
    );
  };

  const handleOpenEditTierModal = ({
    id,
    tierId,
    userName,
  }: {
    id: number;
    tierId: number;
    userName: string;
  }) => {
    dispatch(
      setModalState({
        visible: true,
        name: 'changeTierForUser',
      }),
    );
    dispatch(
      setRolesState({
        userId: id as number,
        tierId,
        userName: userName,
      }),
    );
  };

  useServerError({
    isError: isErrorDeactivateUser,
    error: errorDeactivateUser,
  });
  useServerError({ isError: isErrorActivateUser, error: errorActivateUser });
  useServerError({ isError: isErrorVerifyAddress, error: errorVerifyAddress });

  useEffect(() => {
    if (isSuccessDeactivateUser) {
      showNotification(
        userHasTransactionState ? 'User deactivated!' : 'User deleted!',
        'success',
      );
    }
    if (isSuccessActivateUser) {
      showNotification('User activated!', 'success');
    }
    if (isSuccessVerifyAddress) {
      showNotification('Address verified!', 'success');
    }
  }, [isSuccessActivateUser, isSuccessVerifyAddress, isSuccessDeactivateUser]);

  return (
    <Paper sx={{ marginBottom: 10 }}>
      <TableContainer sx={{ maxHeight: 640 }}>
        <Table
          sx={{ minWidth: 800 }}
          aria-labelledby="tableTitle"
          size={'small'}
          aria-label="enhanced table"
          stickyHeader
        >
          <EnhancedTableHead />
          <TableBody>
            {!!users?.length &&
              users?.map((row: any, index: number) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                const disableEdit =
                  userData?.id === row?.id || row?.role === UserRolesEnum.USER;
                return (
                  <UserRow
                    key={row?.id}
                    row={row}
                    labelId={labelId}
                    isLoadingUsers={isLoadingUsers}
                    handleOpenDeleteModal={handleOpenDeleteModal}
                    handleOpenAddressModal={handleOpenAddressModal}
                    isLoadingActivate={isLoadingActivate}
                    isLoadingDeactivate={isLoadingDeactivate}
                    disableEdit={disableEdit}
                    handleOpenEditModal={handleOpenEditModal}
                    handleOpenEditTierModal={handleOpenEditTierModal}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <NoItemsWrapper
        length={users?.length as number}
        text={'There are no users'}
      >
        <TablePagination
          labelRowsPerPage={matches ? 'Rows per page:' : ''}
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </NoItemsWrapper>
      {DialogComponent}
    </Paper>
  );
};
