// created by Artem
import React, { FC, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { LockOutlined } from '@mui/icons-material';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { useForm, SubmitHandler, Resolver } from 'react-hook-form';
import { Link as NavLink, useNavigate } from 'react-router-dom';
import { object } from 'yup';
import { useServerError } from '@/hooks';
import { useLoginMutation } from '@/services';
import { LoginFormValues } from '@/types/auth.interface';
import { emailScheme, passwordScheme } from '@/utils';
import { LoadingButton } from '../../components/buttons_collection/loading.button';
import { PATH_ADMIN, PATH_REGISTER } from '../../constants/spa-routes';
import { useSendVerificationCode } from '../../hooks/useSendVerificationCode';

type LoginPageProps = {};
export const LoginPage: FC<LoginPageProps> = () => {
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState<'password' | 'text'>(
    'password',
  );
  const [login, { data: loginData, isError, error, isLoading }] =
    useLoginMutation();
  const { sendEmailCode } = useSendVerificationCode();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<LoginFormValues>({
    defaultValues: {
      email: '',
      password: '',
      remember: false,
    },
    resolver: yupResolver(
      object({
        email: emailScheme({ required: true }),
        password: passwordScheme({ required: true }),
      }),
    ) as Resolver<any>,
  });

  const { email } = getValues();

  const onSubmit: SubmitHandler<LoginFormValues> = (data) => {
    login(data);
  };

  const changePassTypeHandle = () =>
    setPasswordType((prev) => (prev === 'password' ? 'text' : 'password'));

  useServerError({ isError, error });

  useEffect(() => {
    if (loginData) {
      if (!loginData?.isLogin2faEnabled) {
        if (loginData?.token) {
          localStorage.setItem('token', loginData?.token);
          navigate(PATH_ADMIN.TRANSACTION_USERS);
        }
      } else {
        sendEmailCode({ email });

        navigate(PATH_REGISTER.LOGIN_VERIFY, {
          state: {
            email: email,
            phone: loginData.phone,
            verifyType: 'login',
            twoFactorType: loginData.twoFactorType,
          },
        });
      }
    }
  }, [loginData]);

  return (
    <Box
      sx={{
        marginTop: 8,
        marginBottom: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlined />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{ mt: 1 }}
      >
        <TextField
          {...register('email')}
          error={!!errors?.email?.message}
          helperText={errors?.email?.message}
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <TextField
          {...register('password')}
          error={!!errors?.password?.message}
          helperText={errors?.password?.message}
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type={passwordType}
          id="password"
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={changePassTypeHandle}>
                  {passwordType === 'text' ? (
                    <RemoveRedEyeOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <LoadingButton
          type="submit"
          isLoading={isLoading}
          sx={{ mt: 3, mb: 2 }}
        >
          Sign In
        </LoadingButton>
        <Grid container>
          <Grid item xs>
            <Link variant="body2" component={NavLink} to="forgot">
              Forgot password?
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
