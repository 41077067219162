import { theme } from '../assets/theme';
import {
  OperationStatusEnum,
  OperationStatusFiatEnum,
} from '../types/operations.interface';

const getBgColorByStatusHelper = (status: string | boolean) => {
  if (typeof status == 'boolean') {
    if (status) {
      return theme.palette.success.light;
    } else {
      return theme.palette.error.light;
    }
  } else {
    if (status === OperationStatusEnum.PENDING)
      return theme.palette.warning.light;
    if (status === OperationStatusEnum.COMPLETED)
      return theme.palette.success.light;
    if (status === OperationStatusEnum.APPROVED)
      return theme.palette.success.light;
    if (
      status === OperationStatusEnum.CANCELED ||
      status === OperationStatusFiatEnum.FAILED
    )
      return theme.palette.error.light;
  }
  return 'inherit';
};

export { getBgColorByStatusHelper };
