import React, { ChangeEvent, FC, MouseEvent } from 'react';
import {
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { spacing } from '@mui/system';
import { styled } from '@mui/system';
import { EnhancedTableHeadComponent } from '@/components';
import { IUserFeedback } from '@/types/user.feedbacks.interface';
import { NoItemsWrapper } from '../../../../shared';
import { HEAD_CELLS } from './constants';
import { UserFeedbackRow } from './user.feedback.row';

type UserFeedbacksTableProps = {
  feedbacks: IUserFeedback[];
  count: number;
  rowsPerPage: number;
  isFetching: boolean;
  page: number;
  onPageChange: (e: MouseEvent<HTMLButtonElement> | null, page: number) => void;
  onRowsPerPageChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
};

const Paper = styled(MuiPaper)(spacing);

export const UserFeedbacksTable: FC<UserFeedbacksTableProps> = ({
  feedbacks,
  page,
  rowsPerPage,
  count,
  onPageChange,
  onRowsPerPageChange,
  isFetching,
}) => {
  const mediaTheme = useTheme();
  const matches = useMediaQuery(mediaTheme.breakpoints.up('sm'));

  return (
    <Paper sx={{ marginBottom: 10 }}>
      <TableContainer sx={{ maxHeight: 640 }}>
        <Table
          sx={{ minWidth: 800 }}
          aria-labelledby="tableTitle"
          size={'small'}
          aria-label="enhanced table"
          stickyHeader
        >
          <EnhancedTableHeadComponent headCells={HEAD_CELLS} />
          <TableBody>
            {!!feedbacks?.length &&
              feedbacks?.map((row) => {
                return (
                  <UserFeedbackRow
                    key={row?.id}
                    row={row}
                    isFetching={isFetching}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <NoItemsWrapper
        length={feedbacks?.length}
        text={'There are no feedbacks'}
      >
        <TablePagination
          labelRowsPerPage={matches ? 'Rows per page:' : ''}
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </NoItemsWrapper>
    </Paper>
  );
};
