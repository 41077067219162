import { PATH_ADMIN_API } from '@/constants/api-routes';
import { baseApi } from '@/services/base.api';

export const countriesService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllCountries: build.query<any, any>({
      query: (params) => ({
        url: PATH_ADMIN_API.COUNTRIES,
        params,
      }),
      providesTags: ['Countries'],
    }),
    updateCountryStatus: build.mutation<any, { id: number; status: boolean }>({
      query: ({ id, ...body }) => ({
        url: `${PATH_ADMIN_API.COUNTRIES}/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Countries'],
    }),
  }),
});

export const { useGetAllCountriesQuery, useUpdateCountryStatusMutation } =
  countriesService;
