import { IMetaSkipTake } from './pagination.interface';

export enum TransactionTypeEnum {
  WITHDRAW = 'WITHDRAW',
  EXCHANGE = 'EXCHANGE',
  DEPOSIT = 'DEPOSIT',
}

export enum TransactionStatusEnum {
  SUBMITTED = 'SUBMITTED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export interface TransactionHistoryResponse {
  items: TransactionHistoryItem[];
  meta: IMetaSkipTake;
}

export interface UserInterface {
  id: number;
  email: string;
  name: string;
  lastName: string;
}

export interface IWithdrawTransactionStatusHistory {
  User: UserInterface;
  createdAt: string;
  status: string;
}

export interface TransactionHistoryItem {
  externalId: string;
  InternalTo: InternalTo;
  InternalFrom: InternalFrom;
  createdAt: string;
  updatedAt: string;
  status: string;
  asset: string;
  fiat: string;
  assetAmount: string;
  fiatAmount: string;
  txHash: string;
  fee: string;
  id: number | string;
  total: string;
  User: UserInterface;
  Iban: IbanInterface;
  WithdrawTransactionStatusHistory: IWithdrawTransactionStatusHistory[];
}

export interface IbanInterface {
  account: string;
  id: number;
  isVerified: boolean;
}

export interface InternalTo {
  id: number;
  email: string;
  name: string;
  lastName: string;
}

export interface InternalFrom {
  id: number;
  email: string;
  name: string;
  lastName: string;
}

export interface IWithdrawals {
  id: number | string;
  fiat: string;
  total: string;
  status: string;
  createdAt: string;
  user: UserInterface;
}

export interface IDeposits {
  id: number;
  externalId: string;
  InternalTo: InternalTo;
  InternalFrom: InternalFrom;
  createdAt: string;
  updatedAt: string;
  status: string;
  asset: string;
  fiat: string;
  assetAmount: string;
  fiatAmount: string;
  txHash: string;
  fee: string;
}

export interface IExchanges {
  id: number;
  externalId: string;
  InternalTo: InternalTo;
  InternalFrom: InternalFrom;
  createdAt: string;
  updatedAt: string;
  status: string;
  asset: string;
  fiat: string;
  assetAmount: string;
  fiatAmount: string;
  txHash: string;
  fee: string;
}
