import React, { FC, memo, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  CardContent,
  Dialog,
  Divider,
  FormControl,
  TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { object } from 'yup';
import { useNotification, useServerError } from '../../../hooks';
import {
  useGasStationEditMutation,
  useGetGasStationQuery,
} from '../../../services';
import { IGasStation } from '../../../types/admin.interface';
import { stringScheme } from '../../../utils';

type GasStationEditProps = {
  open: boolean;
  onClose: () => void;
};

export const GasStationEdit: FC<GasStationEditProps> = memo(
  ({ open, onClose }) => {
    const { showNotification } = useNotification();

    const { data: gasStationData } = useGetGasStationQuery({});

    const [
      gasStationEdit,
      {
        isLoading: isLoadingGasStationEdit,
        isError: isErrorGasStationEdit,
        error: errorGasStationEdit,
        isSuccess: isSuccessGasStationEdit,
      },
    ] = useGasStationEditMutation();

    const { handleSubmit, control } = useForm<IGasStation>({
      defaultValues: {
        gasThreshold: gasStationData?.gasThreshold,
        gasCap: gasStationData?.gasCap,
        maxGasPrice: gasStationData?.maxGasPrice,
      },
      resolver: yupResolver(
        object({
          gasThreshold: stringScheme({ required: true }),
          gasCap: stringScheme({ required: true }),
          maxGasPrice: stringScheme({ required: true }),
        }),
      ) as Resolver<IGasStation>,
    });

    const onSubmit = (data: IGasStation) => {
      gasStationEdit({
        gasThreshold: data.gasThreshold,
        gasCap: data.gasCap,
        maxGasPrice: data.maxGasPrice,
      });
    };

    useServerError({
      isError: isErrorGasStationEdit,
      error: errorGasStationEdit,
    });

    useEffect(() => {
      if (isSuccessGasStationEdit) {
        onClose();
        showNotification(`Gas Station was updated!`, 'success');
      }
    }, [isSuccessGasStationEdit]);

    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <CardContent sx={{ overflow: 'auto' }}>
          <CardHeader sx={{ textAlign: 'center' }} title="Gas Station edit" />
          <Divider />
          <Typography m={1} py={2} variant="h5">
            Changing gas station values
          </Typography>
          <Box
            sx={{
              minWidth: 120,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="gasThreshold"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="number"
                    required
                    onChange={onChange}
                    value={value}
                    fullWidth
                    id="gasThreshold"
                    label="Gas Threshold"
                    name="gasThreshold"
                    autoComplete="gasThreshold"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="gasCap"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="number"
                    required
                    onChange={onChange}
                    value={value}
                    fullWidth
                    id="gasCap"
                    label="Gas Cap"
                    name="gasCap"
                    autoComplete="gasCap"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="maxGasPrice"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="number"
                    required
                    onChange={onChange}
                    value={value}
                    fullWidth
                    id="maxGasPrice"
                    label="Max Gas Price"
                    name="maxGasPrice"
                    autoComplete="maxGasPrice"
                  />
                </FormControl>
              )}
            />
          </Box>
          <Button sx={{ mt: 3, ml: 1 }} onClick={onClose} variant="outlined">
            Close
          </Button>
          <LoadingButton
            endIcon={<SaveIcon />}
            loadingPosition="end"
            variant="contained"
            loading={isLoadingGasStationEdit}
            onClick={handleSubmit(onSubmit)}
            sx={{ mt: 3, ml: 1 }}
            type="submit"
          >
            Save
          </LoadingButton>
        </CardContent>
      </Dialog>
    );
  },
);
