import React, { FC, useEffect, useRef, useState } from 'react';
import { Box, FormHelperText, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { transformDate } from '@/helpers/dateTransformHelper';
import { DateFormatEnum } from '@/types/dateTimeFormatOption';
import {
  EMAIL_TEMPLATE_ENUM,
  IEmailTemplate,
} from '@/types/email.template.interface';
import { Link as NavLink } from 'react-router-dom';
import { PATH_ADMIN } from '@/constants/spa-routes';
import { styled } from '@mui/material/styles';
import { getIconVerified } from '@/helpers/getIconVerifiedHelper';
import DifferenceOutlinedIcon from '@mui/icons-material/DifferenceOutlined';
import { LoadingButton } from '@mui/lab';

type EmailTemplateCardProps = {
  emailTemplate: IEmailTemplate;
  deleteEmailTemplate: (id: number) => void;
  handleDuplicateTemplate: (template: IEmailTemplate) => void;
  isLoadingDuplicate: boolean;
};

const StyledLink = styled(NavLink)({
  textDecoration: 'none',
  fontWeight: 500,
  fontSize: '0.8125rem',
  lineHeight: 1.75,
  minWidth: '64px',
  padding: '3px 9px',
  borderRadius: '4px',
  transition:
    'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  border: '1px solid rgba(26, 30, 42, 0.5)',
  color: '#1A1E2A',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'rgba(26, 30, 42, 0.04)',
    border: '1px solid #1A1E2A',
  },
});

const DEFAULT_SCALE_VALUE = 0.6;

export const EmailTemplateCard: FC<EmailTemplateCardProps> = ({
  emailTemplate,
  deleteEmailTemplate = () => {},
  handleDuplicateTemplate,
  isLoadingDuplicate,
}) => {
  const contentRef = useRef<any>();
  const [scale, setScale] = useState(DEFAULT_SCALE_VALUE);

  const handleDelete = () => {
    deleteEmailTemplate(emailTemplate?.id);
  };

  useEffect(() => {
    const handleResize = () => {
      if (contentRef.current) {
        const container = contentRef.current.parentElement;
        const containerWidth = container.offsetWidth;
        const containerHeight = container.offsetHeight;
        const contentWidth = contentRef.current.scrollWidth;
        const contentHeight = contentRef.current.scrollHeight;

        const widthScale = containerWidth / contentWidth;
        const heightScale = containerHeight / contentHeight;
        setScale(Math.min(widthScale, heightScale, 1) - 0.03);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Card sx={{ maxWidth: '100%', width: '100%' }} elevation={3}>
      <CardContent sx={{ display: 'flex' }}>
        <Box width={'100%'}>
          <Box sx={{ mb: 1, display: 'flex' }}>
            <Typography variant="h5" component="div">
              {emailTemplate.subject} (#{emailTemplate.id})
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              mt: 3,
            }}
          >
            <Grid container spacing={2.5}>
              <Grid item xs={12} md={6}>
                <Box>
                  {Object.keys(EMAIL_TEMPLATE_ENUM).map((el: string) => {
                    const enumKey = el as keyof typeof EMAIL_TEMPLATE_ENUM;
                    return (
                      <Box
                        key={el}
                        mb={2}
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                      >
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          textAlign="left"
                          width="20%"
                        >
                          {EMAIL_TEMPLATE_ENUM[enumKey]}:
                        </Typography>
                        <Typography
                          variant="body2"
                          ml={1}
                          whiteSpace="pre-line"
                          textAlign="left"
                        >
                          {typeof emailTemplate[enumKey] === 'boolean' ? (
                            getIconVerified(
                              Boolean(emailTemplate[enumKey]) as boolean,
                            )
                          ) : (
                            <Box
                              dangerouslySetInnerHTML={{
                                __html:
                                  (emailTemplate[enumKey] as string) || '-',
                              }}
                            />
                          )}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    maxHeight: '456px',
                    height: '100%',
                    minHeight: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid rgba(26, 30, 42, 0.5)',
                    borderRadius: 1,
                    background: '#F2F5F7',
                    pointerEvents: 'none',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      textAlign: 'left',
                      transform: `scale(${scale})`,
                    }}
                    ref={contentRef}
                    dangerouslySetInnerHTML={{
                      __html: emailTemplate.html,
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </CardContent>
      <CardActions sx={{ px: 2, pb: 2 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            flexWrap: 'wrap',
            gap: '10px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
            }}
          >
            <StyledLink to={`${PATH_ADMIN.EMAIL_TEMPLATE}/${emailTemplate.id}`}>
              Update
            </StyledLink>
            <LoadingButton
              variant="outlined"
              loadingPosition="end"
              loading={isLoadingDuplicate}
              startIcon={<DifferenceOutlinedIcon />}
              size="small"
              onClick={() => handleDuplicateTemplate(emailTemplate)}
            >
              Duplicate
            </LoadingButton>
            <Button
              size="small"
              variant="outlined"
              color="error"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Box>
          <Box>
            <FormHelperText>
              Created at{' '}
              {transformDate(
                emailTemplate?.createdAt,
                DateFormatEnum.DATE_WITH_TIME_WITH_DOT,
              )}
            </FormHelperText>
            {emailTemplate?.updatedAt && (
              <FormHelperText sx={{ mt: 0 }}>
                Updated at{' '}
                {transformDate(
                  emailTemplate?.updatedAt,
                  DateFormatEnum.DATE_WITH_TIME_WITH_DOT,
                )}
              </FormHelperText>
            )}
          </Box>
        </Box>
      </CardActions>
    </Card>
  );
};
