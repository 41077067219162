import { PATH_ADMIN_API } from '../constants/api-routes';
import { ITier } from '../types/tier.interface';
import { baseApi } from './base.api';

export const tierService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllTier: build.query<ITier[], null>({
      query: () => ({
        url: PATH_ADMIN_API.ALL_TIER,
      }),
      providesTags: ['Tier'],
    }),
    getTierById: build.query<any, { id: number }>({
      query: ({ id }) => ({
        url: `${PATH_ADMIN_API.TIER}/${id}`,
      }),
    }),
    deleteTier: build.mutation<any, { id: number }>({
      query: ({ id }) => ({
        url: `${PATH_ADMIN_API.TIER}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Tier'],
    }),
    updateTier: build.mutation<
      any,
      { id: number; name: string; isDefault: boolean }
    >({
      query: ({ id, name, isDefault }) => ({
        url: `${PATH_ADMIN_API.TIER}/${id}`,
        method: 'PUT',
        body: {
          name,
          isDefault,
        },
      }),
      invalidatesTags: ['Tier'],
    }),
    createTier: build.mutation<any, { name: string; isDefault: boolean }>({
      query: ({ name, isDefault }) => ({
        url: PATH_ADMIN_API.TIER,
        method: 'POST',
        body: {
          name,
          isDefault,
        },
      }),
      invalidatesTags: ['Tier'],
    }),
    changeTierForUser: build.mutation<any, { userId: number; tierId: number }>({
      query: ({ userId, tierId }) => ({
        url: PATH_ADMIN_API.USERS_TIER,
        method: 'PATCH',
        body: {
          userId,
          tierId,
        },
      }),
      invalidatesTags: ['AdminUsers'],
    }),
  }),
});

export const {
  useGetAllTierQuery,
  useGetTierByIdQuery,
  useCreateTierMutation,
  useDeleteTierMutation,
  useUpdateTierMutation,
  useChangeTierForUserMutation,
} = tierService;
