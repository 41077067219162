import { IMeta } from './pagination.interface';

export interface IUserPdf {
  createdAt: string;
  id: number;
  path: string;
}
export interface UsersInterface {
  id: number;
  email: string;
  name: string;
  lastName: string;
  middleName: any;
  phone: string;
  role: string;
  kycVerified: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
  emailVerified: boolean;
  phoneVerified: boolean;
  hasLeaveFeedback: boolean;
  vaultAccountId: any;
  twoFactorGoogleAuthenticatorSecret: string;
  twoFactorType: string;
  isWithdraw2faEnabled: boolean;
  isSendCrypto2faEnabled: boolean;
  isLogin2faEnabled: boolean;
  isChangeSettings2faEnabled: boolean;
  withdrawLimit: any;
  sendCryptoLimit: any;
  isGoogleAuthenticatorSetUp: boolean;
  canUseWalletConnect: boolean;
  isDeleted: boolean;
  hasTransactions: boolean;
  vaultAutoFuel: boolean;
  Tier: {
    id: number;
    name: string;
  };
  PdfArchive: IUserPdf[];
  accountNumber: string;
  addressVerified: boolean;
  kycAplicant: {
    applicantId: string;
  };
}

export interface AdminUsersInterface {
  data: UsersInterface[];
  meta: IMeta;
}

export interface DisableActivateUserInterface {
  success: boolean;
}

export interface AdminBalanceInterface {
  eurSystemBalance: number;
  usdSystemBalance: number;
  gbpSystemBalance: number;
  aedSystemBalance: number;
  fee: number;
  systemBankAccount: string;
  showNetworkFeeForSendCrypto: number;
  jurisdiction: string;
  companyName: string;
  companyRegistrationNumber: string;
  companyAddress: string;
}

export interface InviteUserInterface {
  email: string;
  role: string;
}

export interface IUserRoles {
  role: string;
}
export interface IUserTier {
  tierId: string | number;
}

export interface IAutoFuel {
  status: boolean;
}

export interface UserRolesInterface {
  availableRoles: string[];
}

type SystemBalanceNames =
  | 'eurSystemBalance'
  | 'gbpSystemBalance'
  | 'aedSystemBalance'
  | 'systemBankAccount'
  | 'usdSystemBalance';

export type SupportedSettingNames =
  | 'fee'
  | SystemBalanceNames
  | 'showNetworkFeeForSendCrypto';

export interface SystemSettings {
  id: number;
  updatedAt: string;
  settingName: SupportedSettingNames;
  settingValue: string;
}

export interface UpdateSystemSettingsPayload {
  settings: Pick<SystemSettings, 'settingName' | 'settingValue'>[];
}

export type SystemSettingsPayload = SystemSettings[];

export enum UserRolesEnum {
  SUPERADMIN = 'SUPERADMIN',
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  DEVELOPER = 'DEVELOPER',
  COMPLIANCE_OFFICER = 'COMPLIANCE_OFFICER',
  COMPLIANCE = 'COMPLIANCE',
  SUPPORT_OFFICER = 'SUPPORT_OFFICER',
  SUPPORT = 'SUPPORT',
  USER = 'USER',
}
export enum UserPermissionEnum {
  EDIT_ROLE = 'EDIT_ROLE',
  DEACTIVATE_USER = 'DEACTIVATE',
}

export interface IGasStation {
  gasThreshold: string;
  gasCap: string;
  maxGasPrice: string;
}
export interface IGasStationAssets {
  id: string;
  status: string;
  balance: string;
  address: string;
}
