import React, { FC, useEffect } from 'react';
import {
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  Box,
  Typography,
  Button,
} from '@mui/material';
import { spacing } from '@mui/system';
import { styled } from '@mui/system';
import { useDispatch } from 'react-redux';
import { EnhancedTableHeadComponent } from '../../../../components';
import { HeadCell } from '../../../../components/table_head';
import { useNotification, useServerError } from '../../../../hooks';
import { useDialog } from '../../../../hooks/useDialog';
import { useDeleteFeeMutation, useGetAllFeeQuery } from '../../../../services';
import { NoItemsWrapper } from '../../../../shared';
import { setModalState, setFeeFiatState } from '../../../../store/ui';
import { IFee } from '../../../../types/fee.interface';
import { FeeRow } from './fee.row';

const Paper = styled(MuiPaper)(spacing);

const headCells: Array<HeadCell> = [
  {
    id: 'applyCondition',
    numeric: false,
    disablePadding: true,
    label: 'Condition',
  },
  {
    id: 'tierId',
    numeric: true,
    disablePadding: false,
    label: 'Tier Name',
  },
  {
    id: 'value',
    numeric: true,
    disablePadding: false,
    label: 'BPS',
  },
  { id: 'button', numeric: true, disablePadding: false, label: 'Edit' },
  { id: 'buttonDelete', numeric: true, disablePadding: false, label: 'Delete' },
];

export const FeeTable: FC = () => {
  const [handleOpenDialog, DialogComponent] = useDialog();

  const dispatch = useDispatch();
  const { showNotification } = useNotification();

  const {
    data: feeData,
    isFetching: isFetchingFeeData,
    error: errorFeeData,
    isError: isErrorFeeData,
  } = useGetAllFeeQuery(null);

  const [
    deleteFee,
    {
      isLoading: isLoadingDeleteFee,
      isError: isErrorDeleteFee,
      error: errorDeleteFee,
      isSuccess: isSuccessDeleteFee,
    },
  ] = useDeleteFeeMutation();

  const handleOpenFeeModal = ({
    id,
    applyCondition,
    tierId,
    value,
  }: {
    applyCondition: string;
    id: number | null;
    tierId: number | null;
    value: number | null;
  }) => {
    dispatch(
      setModalState({
        visible: true,
        name: 'feeModal',
      }),
    );
    dispatch(
      setFeeFiatState({
        id: id,
        value: value,
        tierId: tierId,
        applyCondition: applyCondition,
        feeFiat: '',
      }),
    );
  };

  const handleDeleteFee = ({ id, name }: { id: number; name: string }) => {
    handleOpenDialog({
      description: (
        <>
          Are you sure you want to delete fee <b>{name}</b>?
        </>
      ),
      buttonApplyHandler: () => deleteFee({ id }),
      loadingDelete: isLoadingDeleteFee,
    });
  };

  useServerError({ isError: isErrorDeleteFee, error: errorDeleteFee });
  useServerError({ isError: isErrorFeeData, error: errorFeeData });

  useEffect(() => {
    if (isSuccessDeleteFee) {
      showNotification('Fee deleted!', 'success');
    }
  }, [isSuccessDeleteFee]);

  return (
    <>
      <Box
        mb={2}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h3" color="inherit" noWrap textAlign="left">
          Fee
        </Typography>
        <Button
          variant="contained"
          onClick={() =>
            handleOpenFeeModal({
              id: null,
              tierId: null,
              applyCondition: '',
              value: null,
            })
          }
        >
          + Add fee
        </Button>
      </Box>
      <Paper sx={{ marginBottom: 5 }}>
        <TableContainer sx={{ maxHeight: 640 }}>
          <Table
            sx={{ minWidth: 800 }}
            aria-labelledby="tableTitle"
            size={'small'}
            aria-label="enhanced table"
            stickyHeader
          >
            <EnhancedTableHeadComponent headCells={headCells} />
            <TableBody>
              <NoItemsWrapper
                length={feeData?.length as number}
                text={'There are no fee'}
                colSpan={4}
                isTableComponent
                isLoading={!feeData?.length ? isFetchingFeeData : false}
              >
                {feeData?.map((row: IFee, index: number) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <FeeRow
                      key={row?.id}
                      row={row}
                      labelId={labelId}
                      isLoadingFee={isFetchingFeeData}
                      handleOpenModal={handleOpenFeeModal}
                      handleDeleteTier={handleDeleteFee}
                    />
                  );
                })}
              </NoItemsWrapper>
            </TableBody>
          </Table>
        </TableContainer>
        {DialogComponent}
      </Paper>
    </>
  );
};
