import React, { FC } from 'react';
import { Container, Grid } from '@mui/material';
import { BankAccountList } from './bank.account.list';

type BankAccountProps = {};
export const BankAccount: FC<BankAccountProps> = () => {
  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid item xs={12}>
          <BankAccountList />
        </Grid>
      </Grid>
    </Container>
  );
};
