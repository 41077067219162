import {
  IExternalWalletsRequest,
  IExternalWalletsRequestParams,
  IVerifyExternalWalletRequestParams,
} from '@/types/external.wallets.interface';
import { PATH_ADMIN_API } from '../constants/api-routes';
import { baseApi } from './base.api';

export const userExternalWalletsService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUserExternalWalletsById: build.query<
      IExternalWalletsRequest,
      IExternalWalletsRequestParams
    >({
      query: ({ userId, skip, take }) => ({
        url: `${PATH_ADMIN_API.USERS}/${userId}/external-wallets`,
        params: {
          skip: skip,
          take: take,
        },
      }),
      providesTags: ['UserExternalWallets'],
    }),
    verifyUserExternalWallet: build.mutation<
      void,
      IVerifyExternalWalletRequestParams
    >({
      query: ({ externalWalletId }) => ({
        url: `${PATH_ADMIN_API.USERS_EXTERNAL_WALLETS_VERIFY}/${externalWalletId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['UserExternalWallets'],
    }),
  }),
});

export const {
  useGetUserExternalWalletsByIdQuery,
  useVerifyUserExternalWalletMutation,
} = userExternalWalletsService;
