import React, { FC } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Box,
  Collapse,
  Typography,
  TableHead,
} from '@mui/material';
import { styled } from '@mui/system';
import { theme } from '../../../../assets/theme';
import { transformDate } from '../../../../helpers/dateTransformHelper';
import { NoItemsWrapper } from '../../../../shared';
import { DateFormatEnum } from '../../../../types/dateTimeFormatOption';
import { ReceiveFiatItem } from '../../../../types/receive.fiat.interface';

const StyledTableHead = styled(TableHead)(({ theme: styledTheme }) => ({
  height: 55,
  '& .MuiTableCell-root': {
    backgroundColor: styledTheme.palette.grey[200],
  },
}));

type TransactionHistoryRowProps = {
  row: ReceiveFiatItem;
  open: boolean;
};

export const TransactionHistoryRow: FC<TransactionHistoryRowProps> = ({
  row,
  open,
}) => {
  return (
    <TableRow sx={{ backgroundColor: theme.palette.grey[100] }}>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <Typography variant="h6" gutterBottom component="div">
              Receive transaction status history
            </Typography>
          </Box>
          <Box sx={{ margin: 2 }}>
            <Table size="small" aria-label="purchases">
              <StyledTableHead>
                <TableRow>
                  <TableCell align="center">Created at</TableCell>
                  <TableCell align="center">Email</TableCell>
                  <TableCell align="center">Status</TableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                <NoItemsWrapper
                  isTableComponent
                  colSpan={9}
                  length={
                    row?.ReceiveTransactionStatusHistory?.length as number
                  }
                  text={'There is no status history'}
                >
                  {row?.ReceiveTransactionStatusHistory?.map((item) => {
                    return (
                      <TableRow key={item?.createdAt}>
                        <TableCell align="center" component="th" scope="row">
                          {transformDate(
                            item?.createdAt,
                            DateFormatEnum.DATE_WITH_TIME_WITH_DOT,
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {item?.User?.email}
                        </TableCell>
                        <TableCell align="center">{item?.status}</TableCell>
                      </TableRow>
                    );
                  })}
                </NoItemsWrapper>
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};
