import React, { FC, memo, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  CardContent,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Switch,
  TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { object } from 'yup';
import { useNotification, useServerError } from '../../../hooks';
import {
  useCreateTierMutation,
  useGetAllTierQuery,
  useUpdateTierMutation,
} from '../../../services';
import { ITierSchema } from '../../../types/tier.interface';
import { stringScheme } from '../../../utils';

type TierCreateEditModalProps = {
  open: boolean;
  onClose: () => void;
  tierName: string;
  id: number | null;
  isDefault: boolean;
};

export const TierCreateEditModal: FC<TierCreateEditModalProps> = memo(
  ({ open, onClose, tierName, id, isDefault }) => {
    const { showNotification } = useNotification();

    const { data: tierData } = useGetAllTierQuery(null);

    const [
      createTier,
      {
        isLoading: isLoadingCreateTier,
        isError: isErrorCreateTier,
        error: errorCreateTier,
        isSuccess: isSuccessCreateTier,
      },
    ] = useCreateTierMutation();

    const [
      updateTier,
      {
        isLoading: isLoadingUpdateTier,
        isError: isErrorUpdateTier,
        error: errorUpdateTier,
        isSuccess: isSuccessUpdateTier,
      },
    ] = useUpdateTierMutation();

    const { handleSubmit, control, watch } = useForm<ITierSchema>({
      defaultValues: {
        name: tierName,
        isDefaultTear: isDefault,
      },
      resolver: yupResolver(
        object({
          name: stringScheme({ required: true }).test(
            'unique-name',
            'Name must be unique',
            function (value) {
              return tierName === (value && value)
                ? true
                : !tierData?.some((item) => item.name === value);
            },
          ),
          isDefaultTear: stringScheme({ required: true }),
        }),
      ) as Resolver<any>,
    });

    const isDefaultWatch = watch('isDefaultTear');

    const onSubmit = (data: ITierSchema) => {
      if (id) {
        updateTier({
          id: id,
          name: data.name,
          isDefault: JSON.parse(data.isDefaultTear as any),
        });
      } else {
        createTier({
          name: data.name,
          isDefault: JSON.parse(data.isDefaultTear as any),
        });
      }
    };

    useServerError({
      isError: isErrorCreateTier,
      error: errorCreateTier,
    });
    useServerError({
      isError: isErrorUpdateTier,
      error: errorUpdateTier,
    });

    useEffect(() => {
      if (isSuccessCreateTier || isSuccessUpdateTier) {
        onClose();
        showNotification(
          !id ? `Tier was created!` : `Tier was updated!`,
          'success',
        );
      }
    }, [isSuccessCreateTier, isSuccessUpdateTier]);

    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <CardContent sx={{ overflow: 'auto' }}>
          <CardHeader
            sx={{ textAlign: 'center' }}
            title={id ? 'Tier update' : 'Tier create'}
          />
          <Divider />
          <Typography m={1} py={2} variant="h5">
            {id ? (
              <Typography variant="h6">
                Update tier{' '}
                <b>
                  {tierName}, ID #{id}
                </b>
              </Typography>
            ) : (
              <Typography variant="h6">Create new tier</Typography>
            )}
          </Typography>
          <Box
            sx={{
              minWidth: 120,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '20px',
            }}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="name"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="string"
                    required
                    onChange={onChange}
                    value={value}
                    fullWidth
                    id="name"
                    label="Name"
                    name="name"
                    autoComplete="name"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="isDefaultTear"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl>
                  <InputLabel id="demo-simple-select-helper-label" shrink>
                    Is default
                  </InputLabel>

                  <FormControlLabel
                    onChange={onChange}
                    control={<Switch defaultChecked checked={value} />}
                    label={`${isDefaultWatch}`}
                  />
                  {error && (
                    <FormHelperText error={!!error.message}>
                      {error.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Box>
          <Button sx={{ mt: 3, ml: 1 }} onClick={onClose} variant="outlined">
            Close
          </Button>
          <LoadingButton
            endIcon={<SaveIcon />}
            loadingPosition="end"
            variant="contained"
            loading={isLoadingCreateTier || isLoadingUpdateTier}
            onClick={handleSubmit(onSubmit)}
            sx={{ mt: 3, ml: 1 }}
            type="submit"
          >
            {id ? 'Update' : 'Create'}
          </LoadingButton>
        </CardContent>
      </Dialog>
    );
  },
);
