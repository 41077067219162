import React, { FC } from 'react';
import { TableCell, TableRow, Box, Typography, Collapse } from '@mui/material';
import { IUserFeedback } from '@/types/user.feedbacks.interface';
import { theme } from '../../../../assets/theme';

type FeedbackContentRowProps = {
  row: IUserFeedback;
  opened: boolean;
};

export const FeedbackContentRow: FC<FeedbackContentRowProps> = ({
  row,
  opened,
}) => {
  if (!opened) return null;

  return (
    <TableRow sx={{ backgroundColor: theme.palette.grey[100] }}>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={16}>
        <Collapse in={opened} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <Typography variant="h6" gutterBottom component="div">
              {row.feedback}
            </Typography>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};
