import React, { FC, useEffect } from 'react';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { BannerCard } from '../../../components/banner_card';
import { useNotification, useServerError } from '../../../hooks';
import { useDialog } from '../../../hooks/useDialog';
import {
  useDeleteBannerMutation,
  useGetAllBannerQuery,
} from '../../../services/banner.service';
import { NoItemsWrapper } from '../../../shared';
import { setBannerState, setModalState } from '../../../store/ui';
import { IBannerSlice } from '../../../types/banners.interface';

export const BannersList: FC = () => {
  const { showNotification } = useNotification();
  const [handleOpenDialog, DialogComponent] = useDialog();

  const dispatch = useDispatch();

  const {
    data: bannersData,
    isFetching: isFetchingBannersData,
    isError: isErrorBannersData,
    error: errorBannersData,
  } = useGetAllBannerQuery({});

  const [
    deleteBanners,
    {
      isLoading: isLoadingDeleteBanners,
      isError: isErrorDeleteBanners,
      error: errorDeleteBanners,
      isSuccess: isSuccessDeleteBanners,
    },
  ] = useDeleteBannerMutation();

  useServerError({
    isError: isErrorDeleteBanners,
    error: errorDeleteBanners,
  });
  useServerError({
    isError: isErrorBannersData,
    error: errorBannersData,
  });

  const handleDeleteBanners = (id: number) => {
    handleOpenDialog({
      description: (
        <>
          Are you sure you want to delete banner, <b>#{id}</b>?
        </>
      ),
      buttonApplyHandler: () => deleteBanners({ id }),
      loadingDelete: isLoadingDeleteBanners,
    });
  };

  const handleCreateBanners = () => {
    dispatch(
      setModalState({
        visible: true,
        name: 'bannersModal',
      }),
    );
  };
  const handleUpdateBanners = (banner: IBannerSlice) => {
    dispatch(
      setModalState({
        visible: true,
        name: 'bannersModal',
      }),
    );
    dispatch(setBannerState(banner));
  };

  useEffect(() => {
    if (isSuccessDeleteBanners) {
      showNotification('Bank account was deleted!', 'success');
    }
  }, [isSuccessDeleteBanners]);

  return (
    <>
      <Box
        mb={2}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          flexWrap: 'wrap',
        }}
      >
        <Typography variant="h3" color="inherit" noWrap textAlign="left">
          Banners
        </Typography>
        <Typography sx={{ my: 2 }} textAlign="left">
          Create or update existing KYC/KYB or address banner
        </Typography>
        <Box>
          <LoadingButton
            variant="contained"
            startIcon={<SafetyCheckIcon />}
            onClick={handleCreateBanners}
          >
            Create new banner
          </LoadingButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            my: 5,
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <NoItemsWrapper
            isLoading={isFetchingBannersData}
            length={bannersData?.length || 0}
            text="There are no banners"
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              {bannersData?.map((banner) => {
                return (
                  <BannerCard
                    key={banner?.id}
                    banner={banner}
                    updateBanner={handleUpdateBanners}
                    deleteBanner={handleDeleteBanners}
                  />
                );
              })}
            </Box>
          </NoItemsWrapper>
        </Box>
        {DialogComponent}
      </Box>
    </>
  );
};
