import React, { FC, memo, useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  Button,
  CardContent,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { object } from 'yup';
import { useNotification, useServerError } from '@/hooks';
import {
  useCreateCryptoCurrencyMutation,
  useGetB2C2NameListQuery,
  useGetFireblocksNameListQuery,
  useGetFuzeNameListQuery,
  useUpdateCryptoCurrencyMutation,
} from '@/services';
import { ICurrencySchema, ICurrencySlice } from '@/types/currencies.interface';
import { booleanScheme, numberScheme, stringScheme } from '@/utils';
import { CurrencyIconComponent } from '../../currency_image';
import { UploadSvgButton } from '@/components';

type CurrencyModalProps = ICurrencySlice & {
  open: boolean;
  onClose: () => void;
};

enum NotificationMessageEnum {
  SuccessCreate = 'Currency has been successfully created!',
  SuccessUpdate = 'Currency has been successfully updated!',
  ErrorCreate = 'Cannot find Fireblocks ID or it was not selected',
}

export const CurrencyModal: FC<CurrencyModalProps> = memo(
  ({
    open,
    onClose,
    customName,
    id,
    status,
    icon,
    networkQrCode,
    protocolQrCode,
    isDefault,
    b2c2CurrencyName,
    fuzeCurrencyName,
    ordering,
    externalId,
    reason,
    isVisible,
  }) => {
    const { showNotification } = useNotification();

    const [
      createCryptoCurrency,
      {
        isLoading: isLoadingCreateCryptoCurrency,
        isError: isErrorCreateCryptoCurrency,
        error: errorCreateCryptoCurrency,
        isSuccess: isSuccessCreateCryptoCurrency,
      },
    ] = useCreateCryptoCurrencyMutation();

    const [
      updateCryptoCurrency,
      {
        isLoading: isLoadingUpdateCryptoCurrency,
        isError: isErrorUpdateCryptoCurrency,
        error: errorUpdateCryptoCurrency,
        isSuccess: isSuccessUpdateCryptoCurrency,
      },
    ] = useUpdateCryptoCurrencyMutation();

    const {
      data: fireblocksNameList,
      isFetching: isFetchingFireblocksNameList,
      error: errorFireblocksNameList,
      isError: isErrorFireblocksNameList,
    } = useGetFireblocksNameListQuery();

    const fireblocksCurrenciesOptions = useMemo(
      () =>
        fireblocksNameList?.length
          ? fireblocksNameList.map((item) => item.externalId)
          : [],
      [fireblocksNameList?.length],
    );

    const {
      data: b2c2NameList,
      isFetching: isFetchingB2c2NameList,
      error: errorB2c2NameList,
      isError: isErrorB2c2NameList,
    } = useGetB2C2NameListQuery({});

    const {
      data: fuzeNameList,
      isFetching: isFetchingFuzeNameList,
      error: errorFuzeNameList,
      isError: isErrorFuzeNameList,
    } = useGetFuzeNameListQuery({});

    const { handleSubmit, control, watch } = useForm<ICurrencySchema>({
      defaultValues: {
        customName,
        status,
        icon,
        networkQrCode,
        protocolQrCode,
        isDefault,
        b2c2CurrencyName,
        fuzeCurrencyName,
        ordering,
        fireblocksCurrency: externalId,
        reason,
        isVisible,
      },
      resolver: yupResolver(
        object({
          customName: stringScheme({ required: true }),
          icon: stringScheme({ required: false }).nullable(),
          status: booleanScheme({ required: false }).nullable(),
          networkQrCode: stringScheme({ required: false }).nullable(),
          protocolQrCode: stringScheme({ required: false }).nullable(),
          fireblocksCurrency: stringScheme({ required: true }),
          b2c2CurrencyName: stringScheme({ required: false }),
          fuzeCurrencyName: stringScheme({ required: false }),
          isDefault: booleanScheme({ required: false }).nullable(),
          ordering: numberScheme({ required: false }),
          reason: stringScheme({ required: false }).nullable(),
          isVisible: booleanScheme({ required: false }),
        }),
      ) as Resolver<ICurrencySchema>,
    });

    const statusWatch = watch('status');
    const isDefaultWatch = watch('isDefault');
    const isVisibleWatch = watch('isVisible');

    const onSubmit = ({ fireblocksCurrency, ...data }: ICurrencySchema) => {
      if (id) {
        updateCryptoCurrency({
          ...data,
          id: id,
          ordering: data.ordering as number,
        });
      } else {
        const fireblocksCurrencyId = fireblocksNameList?.find(
          (item) => item.externalId === fireblocksCurrency,
        )?.id;

        if (fireblocksCurrencyId) {
          createCryptoCurrency({
            ...data,
            ordering: data.ordering as number,
            id: fireblocksCurrencyId,
          });
        } else {
          showNotification(NotificationMessageEnum.ErrorCreate, 'error');
        }
      }
    };

    useServerError({
      isError: isErrorCreateCryptoCurrency,
      error: errorCreateCryptoCurrency,
    });

    useServerError({
      isError: isErrorUpdateCryptoCurrency,
      error: errorUpdateCryptoCurrency,
    });

    useServerError({
      isError: isErrorFireblocksNameList,
      error: errorFireblocksNameList,
    });

    useServerError({
      isError: isErrorB2c2NameList,
      error: errorB2c2NameList,
    });

    useServerError({
      isError: isErrorFuzeNameList,
      error: errorFuzeNameList,
    });

    useEffect(() => {
      if (isSuccessCreateCryptoCurrency) {
        onClose();
        showNotification(NotificationMessageEnum.SuccessCreate, 'success');
      }

      if (isSuccessUpdateCryptoCurrency) {
        onClose();
        showNotification(NotificationMessageEnum.SuccessUpdate, 'success');
      }
    }, [isSuccessCreateCryptoCurrency, isSuccessUpdateCryptoCurrency]);

    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <CardContent sx={{ overflow: 'auto' }}>
          <CardHeader
            sx={{ textAlign: 'center' }}
            title={id ? 'Currency update' : 'Currency create'}
          />
          <Divider />
          <Typography m={1} py={2} variant="h5">
            <Typography variant="h6">
              {id ? (
                <>
                  Update currency <b>{customName}</b>
                </>
              ) : (
                <>Create new currency</>
              )}
            </Typography>
          </Typography>
          <Box
            sx={{
              minWidth: 120,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '20px',
            }}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="customName"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="string"
                    required
                    onChange={onChange}
                    value={value}
                    fullWidth
                    id="name"
                    label="Name"
                    name="name"
                    autoComplete="name"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="networkQrCode"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="string"
                    onChange={onChange}
                    value={value}
                    fullWidth
                    id="networkQrCode"
                    label="Network QR Code"
                    name="networkQrCode"
                    autoComplete="networkQrCode"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="protocolQrCode"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="string"
                    onChange={onChange}
                    value={value}
                    fullWidth
                    id="protocolQrCode"
                    label="Protocol QR Code"
                    name="protocolQrCode"
                    autoComplete="protocolQrCode"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="fireblocksCurrency"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    id="fireblocks-autocomplete"
                    onChange={(event, val) => onChange(val)}
                    value={value}
                    disabled={isFetchingFireblocksNameList || !!id}
                    options={
                      isFetchingFireblocksNameList
                        ? []
                        : (fireblocksCurrenciesOptions as string[])
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Fireblocks Currency Name"
                        required
                      />
                    )}
                  />
                  {error && (
                    <FormHelperText error={!!error.message}>
                      {error.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="b2c2CurrencyName"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    id="b2c2-autocomplete"
                    onChange={(event, val) => onChange(val)}
                    value={value}
                    disabled={isFetchingB2c2NameList}
                    options={
                      isFetchingB2c2NameList ? [] : (b2c2NameList as string[])
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="B2C2 Currency Name" />
                    )}
                  />
                  {error && (
                    <FormHelperText error={!!error.message}>
                      {error.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="fuzeCurrencyName"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    id="fuze-autocomplete"
                    onChange={(event, val) => onChange(val)}
                    value={value}
                    disabled={isFetchingFuzeNameList}
                    options={
                      isFetchingFuzeNameList ? [] : (fuzeNameList as string[])
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Fuze Currency Name" />
                    )}
                  />
                  {error && (
                    <FormHelperText error={!!error.message}>
                      {error.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="ordering"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="number"
                    required
                    onChange={onChange}
                    value={value}
                    fullWidth
                    id="ordering"
                    label="Ordering"
                    name="ordering"
                    inputProps={{ min: 0 }}
                    autoComplete="ordering"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="reason"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    type="string"
                    onChange={onChange}
                    value={value}
                    fullWidth
                    id="reason"
                    label="Reason"
                    name="reason"
                    autoComplete="reason"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="icon"
              render={({ field: { onChange, value } }) => (
                <FormControl
                  fullWidth
                  sx={{
                    display: 'flex',
                    gap: '20px',
                    alignItems: 'center',
                    mb: '20px',
                  }}
                >
                  <Typography>Logo</Typography>
                  <Box
                    sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <UploadSvgButton
                        title="Upload file"
                        onChange={onChange}
                      />
                      <FormHelperText>Only .svg format</FormHelperText>
                    </Box>
                    <Box sx={{ height: '50px', width: '50px' }}>
                      <CurrencyIconComponent
                        iconString={value}
                        height={50}
                        width={50}
                      />
                    </Box>
                  </Box>
                </FormControl>
              )}
            />
            <Stack sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: 2 }}>
              <Controller
                control={control}
                name="status"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormControl>
                    <InputLabel id="demo-simple-select-helper-label" shrink>
                      Status
                    </InputLabel>

                    <FormControlLabel
                      onChange={onChange}
                      control={<Switch defaultChecked checked={value} />}
                      label={`${statusWatch}`}
                    />
                    {error && (
                      <FormHelperText error={!!error.message}>
                        {error.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name="isDefault"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormControl>
                    <InputLabel id="demo-simple-select-helper-label" shrink>
                      Is Default
                    </InputLabel>

                    <FormControlLabel
                      onChange={onChange}
                      control={<Switch defaultChecked checked={value} />}
                      label={`${isDefaultWatch}`}
                    />
                    {error && (
                      <FormHelperText error={!!error.message}>
                        {error.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name="isVisible"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormControl>
                    <InputLabel id="is-visible-select-helper-label" shrink>
                      Is Visible
                    </InputLabel>

                    <FormControlLabel
                      onChange={onChange}
                      control={<Switch defaultChecked checked={value} />}
                      label={`${isVisibleWatch}`}
                    />
                    {error && (
                      <FormHelperText error={!!error.message}>
                        {error.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Stack>
          </Box>
          <Button sx={{ mt: 3, ml: 1 }} onClick={onClose} variant="outlined">
            Close
          </Button>
          <LoadingButton
            endIcon={<SaveIcon />}
            loadingPosition="end"
            variant="contained"
            loading={
              isLoadingCreateCryptoCurrency || isLoadingUpdateCryptoCurrency
            }
            onClick={handleSubmit(onSubmit)}
            sx={{ mt: 3, ml: 1 }}
            type="submit"
          >
            {id ? 'Update' : 'Create'}
          </LoadingButton>
        </CardContent>
      </Dialog>
    );
  },
);
